
const ShowPassword = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g id="Group_1856" data-name="Group 1856" transform="translate(-223.98 -195.164)">
        <rect id="Rectangle_729" data-name="Rectangle 729" width="22" height="22" transform="translate(223.98 195.164)" fill="none" />
        <g id="Group_708" data-name="Group 708" transform="translate(-153 142)">
          <path id="Path_31476" data-name="Path 31476" d="M379,72.329h17.961V58.449H393.7L392.063,56h-4.9l-1.633,2.449H379Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
          <circle id="Ellipse_122" data-name="Ellipse 122" cx="4" cy="4" r="4" transform="translate(385.981 61.164)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
          <line id="Line_143" data-name="Line 143" x1="1" transform="translate(381.981 62.164)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
        </g>
      </g>
    </svg>
  )
};

export default ShowPassword;