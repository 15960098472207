// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/img/icn_tick.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-type{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) left center no-repeat;padding:5px 0 5px 30px;margin-right:40px}.service-type:last-child{margin-right:0}`, "",{"version":3,"sources":["webpack://./src/components/ServiceTypes.scss"],"names":[],"mappings":"AASA,cACI,wEAAA,CACA,sBAAA,CACA,iBAAA,CAEA,yBACI,cAAA","sourcesContent":["/**\n *@file ServiceTypes.scss\n *@brief ServiceTypes Input component style\n *@date Oct, 2023\n *@author ZCO Engineer\n *@copyright (c) 2023, ZCO\n */\n@import \"../assets/styles/variables.scss\";\n\n.service-type {\n    background: url(../assets/img/icn_tick.svg) left center no-repeat;\n    padding: 5px 0 5px 30px;\n    margin-right: 40px;\n\n    &:last-child {\n        margin-right: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
