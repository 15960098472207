/**
 * @file   src\containers\ForgotPassword.tsx
 * @brief  Forgot Password Page.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ForgotEmailRequest } from '../interfaces/authInterface';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { _forgotEmail } from '../store/actions/authAction';
import { resetForgotEmailState } from '../store/slices/authSlice';
import '../assets/styles/prelogin.scss';
import Logo from '../assets/img/nearbuys-prelogin.svg';
import ForgotPswd from '../components/ForgotPass';
import ForgotPassIc from '../assets/img/icons/ForgotPass';
import ForgotMailIc from '../assets/img/icons/ForgotMailSent';
import ICForgotMail from '../assets/img/icons/ICMailForgot';
import * as CONSTANT from '../utils/constants';
import * as alerts from '../utils/alerts';
import ForgotMailForm from '../components/ForgotMailForm';
import { validatePhoneNo } from '../utils/authHelper';
import ForgotPhone from '../components/ForgotPhone';


const ForgotPassowrd = () => {
  const dispatch = useAppDispatch();
  const [forgotPhone, setforgotPhone] = useState<string>('');
  const [forgotPhoneError, setforgotPhoneError] = useState<string>('');
  const [mailSentSucess, setMailSentSucess] = useState<boolean>(true);


  const forgotEmail = (params: ForgotEmailRequest) => dispatch(_forgotEmail(params));
  const forgotResponseData = useAppSelector((RootReducer) => RootReducer.auth.forgotEmail);

  const phoneNumberErrMsg = validatePhoneNo(forgotPhone);


  //handles forgot Email api response
  useEffect(() => {
    if (forgotResponseData.isLoading === false && forgotResponseData.isSuccess === true) {
      if (forgotResponseData.errorCode === CONSTANT.API_ERRCODE && forgotResponseData.errorMessage) {
        toast.error(forgotResponseData.errorMessage);
      } else if (forgotResponseData.errorCode === CONSTANT.API_SUCCESSCODE) {
        if (mailSentSucess) {
          toast.success(forgotResponseData?.errorMessage);
          setMailSentSucess(!mailSentSucess);
        }
      }
    }
    dispatch(resetForgotEmailState());
  }, [forgotResponseData]);

  const handleMobileInputChange = (event: string) => {
    let inputValue = event;
    const validFormat = /^[-\d\(\)\[\]\s]+$/
    const validDigit = /\d+/g
    const nonDigit = /\D/
    if (validFormat.test(inputValue)) {
      const numbers: any = inputValue.match(validDigit);
      const fullNumber = numbers.join('');
      setforgotPhone(fullNumber);
    } else if (!nonDigit.test(inputValue)) {
      setforgotPhone(event);     
    }
  };

  // handles email validation
  const validatePhone = () => {
    console.log("Entered validation");
    
    let isValid =true
    if( forgotPhone.trim() === ''){
      isValid = false;
      setforgotPhoneError( alerts.ENTER_PHONE_NUMBER)
    }
        else if(phoneNumberErrMsg !== '') {
          isValid = false;
          setforgotPhoneError(phoneNumberErrMsg)
        }     
        return isValid
  };

  // handle phone format
  const phoneFormat = (value: any) => {
    let formattedPhoneNumber = value;
    if (value) {
      if (value.length <= 3) {
        formattedPhoneNumber = value;
      } else if (value.length > 3 && value.length <= 6) {
        formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
      } else if (value.length > 6) {
        formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)} ${value.slice(6, 10)}`;
      }
      return formattedPhoneNumber;
    }
  };

  // handles form submit
  const handleSubmit = () => {
    if (validatePhone()) {
      forgotEmail({ 'phone_no': phoneFormat(forgotPhone) });
    }
  };

  const disableSubmitBtn = () => {
    if (forgotPhone === '' || mailSentSucess === false) {
      return true;
    } else return false;
  };

  return (
    <Container fluid className="pre-login">
      <Row>
        <Col lg="6">
          <div className="nb-caption d-flex align-items-center justify-content-center">
            {/* {!mailSentSucess ? <ForgotPassIc /> : <ForgotMailIc />} */}
            <ICForgotMail />
          </div>
        </Col>
        <Col lg="6" className="d-flex align-items-center justify-content-center">
          <div className="nb-login">
            <img src={Logo} alt="" width={116} />
            {mailSentSucess ?
              <ForgotMailForm onChange={handleMobileInputChange} value ={forgotPhone} handleSubmit={handleSubmit} phoneError={forgotPhoneError} disabled={disableSubmitBtn()} /> :
              <ForgotPhone />}
            {/* <ForgotMailForm onChange={handleMobileInputChange} value ={forgotPhone} handleSubmit={handleSubmit} phoneError={forgotPhoneError} disabled={disableSubmitBtn()} /> */}
            
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassowrd;
