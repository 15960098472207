/**
 * @file   src\components\Header.tsx
 * @brief  Header component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useState, useEffect } from 'react';
import { Col, Container, Offcanvas, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import './Header.scss';
import Logo from '../assets/img/nearbuys-prelogin.svg';
import MainMenu from './MainMenu';
import SubMenu from './SubMenu';
import CategoryMenu from './CategoryMenu';
import Hamburger from '../assets/img/icons/Hamburger';
import DropMenu from './DropdownMenu';
import LogoInner from '../assets/img/icons/LogoInner';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { getGusetUser, getUser } from '../utils/localStorage';
import { ntfnCheck } from '../store/actions/ntfnAction';
import VendorMenu from './VendorMenu';

const Header = (props: any) => {
  const dispatch = useDispatch();
  const guestUser = getGusetUser();
  const UserInfo = JSON.parse(getUser());
  const [show, setShow] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  return (
    <header className={'header ' + (props.styleName || "")}>
      <Container fluid className={'top-wrap d-flex align-items-center'}>
        <Container>
          <Row className='top-wrap-inner'>
            <Col  md='2' className='logo-wrap'><LogoInner  navigate ={navigate}/></Col>
            <Col md='10' className='justify-content-end d-flex align-items-center right-menu-wrap'>
              <div className='main-menu-wrap'>
                <MainMenu />
              </div>
              <div className='sub-menu-wrap d-flex align-items-center'>
                <SubMenu />
                {/* Off Canvas Menu */}
                {(!UserInfo || guestUser === true) &&<VendorMenu/>}
                <span className='hamburger' onClick={handleShow}>
                  <Hamburger />
                </span>
                <Offcanvas show={show} onHide={handleClose}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title><img src={Logo} width={93} alt='NearBuys' /></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <MainMenu />
                    <VendorMenu/>
                    <DropMenu />
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className='bottom-wrap'>
        {(pathName === '/vendordetails') ||(pathName === '/productdetails')  ?
         <Container className='category-menu d-flex align-items-center justify-content-between'>
          <CategoryMenu />
        </Container> :
        <></>
        }
      </Container>
    </header>
  );
};

export default Header;
