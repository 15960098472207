
const CatClassifieds = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.406" height="24.748" viewBox="0 0 28.406 24.748">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_593" data-name="Rectangle 593" width="28.406" height="24.748" fill="#f28100" />
        </clipPath>
      </defs>
      <g id="Group_1633" data-name="Group 1633" transform="translate(-304.797 -1358.626)">
        <g id="icn_clasi" transform="translate(304.797 1358.626)">
          <g id="Group_1423" data-name="Group 1423" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path id="Path_31530" data-name="Path 31530" d="M19.511.148a1.187,1.187,0,0,0-1.2.032L9.129,5.918H4.734a4.734,4.734,0,0,0,0,9.469H9.129l9.18,5.738a1.184,1.184,0,0,0,1.812-1V1.184A1.187,1.187,0,0,0,19.511.148" transform="translate(0 0)" fill="#f28100" />
            <path id="Path_31531" data-name="Path 31531" d="M23.551,8H21.184a1.184,1.184,0,1,0,0,2.367h2.367a1.184,1.184,0,1,0,0-2.367" transform="translate(3.672 1.469)" fill="#f28100" />
            <path id="Path_31532" data-name="Path 31532" d="M19.979,6.051a1.174,1.174,0,0,0,.591-.159l2.05-1.184a1.184,1.184,0,1,0-1.184-2.05l-2.05,1.184a1.184,1.184,0,0,0,.593,2.209" transform="translate(3.451 0.459)" fill="#f28100" />
            <path id="Path_31533" data-name="Path 31533" d="M22.62,13.842l-2.05-1.184a1.184,1.184,0,1,0-1.184,2.05l2.05,1.184a1.184,1.184,0,0,0,1.184-2.05" transform="translate(3.45 2.295)" fill="#f28100" />
            <path id="Path_31534" data-name="Path 31534" d="M9.04,15.882,7.628,15H4.48L7.1,20.866a1.9,1.9,0,1,0,3.475-1.551Z" transform="translate(0.823 2.754)" fill="#f28100" />
          </g>
        </g>
      </g>
    </svg>

  )
};

export default CatClassifieds;

