/**
 *@file Products.tsx
 *@brief Product component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import React from 'react';
import { Products } from '../interfaces/GeneralInterface';
import './Products.scss';
import NoImage from '../assets/img/no-image.svg';
import * as CONSTANT from '../utils/constants';
import { categoryIds } from '../utils/enums';
import { calculateElapsedTime } from '../utils/authHelper';
import Strings from '../assets/strings/Strings.json';

interface Product extends Products {}

const Product = React.forwardRef((props: Product, ref: any) => {
  return (
    <div onClick={props.onClick} className="product-wrap">
      <div className="image-wrap position-relative">
        {props?.requestOfferBadge === CONSTANT.STATUS_1 ? <span className="freshness-tag position-absolute">{Strings.VendorDetails.Product.Label1}</span> : 
        props?.freshnessId === CONSTANT.STATUS_1 ? <span className="freshness-tag position-absolute">{props.freshnessName}</span> : ''}
        <img src={props?.image ?? NoImage} alt="" />
      </div>
      <div className="dtls-wrap">
        {props?.product?.prod_catid !== categoryIds.COUPONS && props?.price && <span className="price d-inline-block">${props?.price}</span>}
        <h5 className={props?.product?.prod_catid === categoryIds.COUPONS ? 'no-price' : ''}>{props.title}</h5>
        {props.type !== CONSTANT.STATUS_1 && (
          <div className="dtls-bottom d-flex">
            <span className="distance">{props?.product?.miles_away ? parseFloat(props?.product?.miles_away).toFixed(2) : '0'} mi Away</span>
            {props?.product?.posted_time && <span className="time">{props?.product?.posted_time}</span>}
          </div>
        )}
      </div>
    </div>
  );
});

export default Product;
