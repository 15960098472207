/**
 * @file   src\store\slices\userSlices.ts
 * @brief  This file is responsible for creating auth slices to call actions and state management.
 * @date   Oct, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { profileImageUpload, userDeleteAccount, userLocationUpdate, userSupport, userOfferRequest, offerRequestImageUpload, NationWideListing } from '../actions/userAction';
import { IprofileImageUpload, IuserUpdateLocation } from '../../interfaces/authInterface';
import { IdeleteAccount, IUserSupport, IOfferRequest, IOfferRequestImageUpload, INationWideListing } from '../../interfaces/GeneralInterface';

const profileImageUploadState: IprofileImageUpload = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const userUpdateLocationState: IuserUpdateLocation = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const userDeleteAccountState: IdeleteAccount = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const userSupportState: IUserSupport = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const userOfferRequestState: IOfferRequest = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const offerRequestImageUploadState: IOfferRequestImageUpload = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const NationWideListingState: INationWideListing = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

// Config user slice.
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    profileImageUpload: profileImageUploadState,
    userUpdateLocation: userUpdateLocationState,
    userDeleteAccount: userDeleteAccountState,
    userSupport: userSupportState,
    userOfferRequest: userOfferRequestState,
    offerRequestImageUpload: offerRequestImageUploadState,
    NationWideListing: NationWideListingState,
  },
  reducers: {
    resetProfileImageUploadState: (state) => {
      state.profileImageUpload = profileImageUploadState;
    },
    resetUserLocationUpdateState: (state) => {
      state.userUpdateLocation = userUpdateLocationState;
    },
    resetUserAccountDeleteState: (state) => {
      state.userDeleteAccount = userDeleteAccountState;
    },
    resetUserSupportState: (state) => {
      state.userSupport = userSupportState;
    },
    resetOfferRequestState: (state) => {
      state.userOfferRequest = userOfferRequestState;
    },
    resetOfferRequestImageUpload: (state) => {
      state.offerRequestImageUpload = offerRequestImageUploadState;
    },
    resetNationWideListing: (state) => {
      state.NationWideListing = NationWideListingState;
    },
  },
  extraReducers(builder) {
    // Start validate user request.
    builder.addCase(profileImageUpload.pending, (state) => {
      state.profileImageUpload.isSuccess = false;
      state.profileImageUpload.isLoading = true;
      state.profileImageUpload.errorCode = 0;
      state.profileImageUpload.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(profileImageUpload.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.profileImageUpload.isLoading = error === 0;
      state.profileImageUpload.isSuccess = true;
      state.profileImageUpload.userInfo = data;
      state.profileImageUpload.errorCode = error;
      state.profileImageUpload.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(profileImageUpload.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.profileImageUpload.isSuccess = false;
      state.profileImageUpload.isLoading = false;
      state.profileImageUpload.errorCode = error;
      state.profileImageUpload.errorMessage = 'Error';
    });
    // Start validate user request.
    builder.addCase(userLocationUpdate.pending, (state) => {
      state.userUpdateLocation.isSuccess = false;
      state.userUpdateLocation.isLoading = true;
      state.userUpdateLocation.errorCode = 0;
      state.userUpdateLocation.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(userLocationUpdate.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.userUpdateLocation.isLoading = error === 0;
      state.userUpdateLocation.isSuccess = true;
      state.userUpdateLocation.userInfo = data;
      state.userUpdateLocation.errorCode = error;
      state.userUpdateLocation.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(userLocationUpdate.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.userUpdateLocation.isSuccess = false;
      state.userUpdateLocation.isLoading = false;
      state.userUpdateLocation.errorCode = error;
      state.userUpdateLocation.errorMessage = 'Error';
    });
    // Start validate user request.
    builder.addCase(userDeleteAccount.pending, (state) => {
      state.userDeleteAccount.isSuccess = false;
      state.userDeleteAccount.isLoading = true;
      state.userDeleteAccount.errorCode = 0;
      state.userDeleteAccount.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(userDeleteAccount.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.userDeleteAccount.isLoading = error === 0;
      state.userDeleteAccount.isSuccess = true;
      state.userDeleteAccount.resultInfo = data;
      state.userDeleteAccount.errorCode = error;
      state.userDeleteAccount.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(userDeleteAccount.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.userDeleteAccount.isSuccess = false;
      state.userDeleteAccount.isLoading = false;
      state.userDeleteAccount.errorCode = error;
      state.userDeleteAccount.errorMessage = 'Error';
    });

    // Start validate user request.
    builder.addCase(userSupport.pending, (state) => {
      state.userSupport.isSuccess = false;
      state.userSupport.isLoading = true;
      state.userSupport.errorCode = 0;
      state.userSupport.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(userSupport.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.userSupport.isLoading = error === 0;
      state.userSupport.isSuccess = true;
      state.userSupport.resultInfo = data;
      state.userSupport.errorCode = error;
      state.userSupport.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(userSupport.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.userSupport.isSuccess = false;
      state.userSupport.isLoading = false;
      state.userSupport.errorCode = error;
      state.userSupport.errorMessage = 'Error';
    });
    builder.addCase(userOfferRequest.pending, (state) => {
      state.userOfferRequest.isSuccess = false;
      state.userOfferRequest.isLoading = true;
      state.userOfferRequest.errorCode = 0;
      state.userOfferRequest.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(userOfferRequest.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.userOfferRequest.isLoading = error === 0;
      state.userOfferRequest.isSuccess = true;
      state.userOfferRequest.resultInfo = data;
      state.userOfferRequest.errorCode = error;
      state.userOfferRequest.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(userOfferRequest.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.userOfferRequest.isSuccess = false;
      state.userOfferRequest.isLoading = false;
      state.userOfferRequest.errorCode = error;
      state.userOfferRequest.errorMessage = 'Error';
    });
    builder.addCase(offerRequestImageUpload.pending, (state) => {
      state.offerRequestImageUpload.isSuccess = false;
      state.offerRequestImageUpload.isLoading = true;
      state.offerRequestImageUpload.errorCode = 0;
      state.offerRequestImageUpload.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(offerRequestImageUpload.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.offerRequestImageUpload.isLoading = error === 0;
      state.offerRequestImageUpload.isSuccess = true;
      state.offerRequestImageUpload.resultInfo = data;
      state.offerRequestImageUpload.errorCode = error;
      state.offerRequestImageUpload.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(offerRequestImageUpload.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.offerRequestImageUpload.isSuccess = false;
      state.offerRequestImageUpload.isLoading = false;
      state.offerRequestImageUpload.errorCode = error;
      state.offerRequestImageUpload.errorMessage = 'Error';
    });
    builder.addCase(NationWideListing.pending, (state) => {
      state.NationWideListing.isSuccess = false;
      state.NationWideListing.isLoading = true;
      state.NationWideListing.errorCode = 0;
      state.NationWideListing.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(NationWideListing.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || action.payload?.errorCode || 0;
      state.NationWideListing.isLoading = error === 0;
      state.NationWideListing.isSuccess = true;
      state.NationWideListing.userInfo = data;
      state.NationWideListing.errorCode = error;
      state.NationWideListing.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(NationWideListing.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.NationWideListing.isSuccess = false;
      state.NationWideListing.isLoading = false;
      state.NationWideListing.errorCode = error;
      state.NationWideListing.errorMessage = 'Error';
    });
  },
});
// Export auth actions.
export const {
  resetProfileImageUploadState,
  resetUserLocationUpdateState,
  resetUserAccountDeleteState,
  resetUserSupportState,
  resetOfferRequestState,
  resetOfferRequestImageUpload,
  resetNationWideListing,
} = userSlice.actions;
// Export reducer.
export default userSlice.reducer;
