/**
 * @file   src\components\HomeLayout.tsx
 * @brief  This component used as a home layout for the content which can be replaced.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import '../assets/styles/home.scss';

const HomeLayout = () => {
  const [className, setClassName] = useState<any>('home-header');

  const toggleclass = 'is-sticky home-header';
  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll > 40) {
      setClassName(toggleclass);
    } else {
      setClassName('home-header');
    }
  });
  return (
    <>
      <Header styleName={className} />
      <Outlet />
      <Footer />
    </>
  );
};

export default HomeLayout;
