
const ForgotMailIc = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="263" height="263" viewBox="0 0 263 263">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_515" data-name="Rectangle 515" width="9.4" height="9.4" fill="none" stroke="#cbe991" stroke-width="1" />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect id="Rectangle_516" data-name="Rectangle 516" width="8.791" height="8.791" fill="none" stroke="#cbe991" stroke-width="1" />
        </clipPath>
      </defs>
      <g id="icn_success" transform="translate(-98 -123)">
        <circle id="Ellipse_83" data-name="Ellipse 83" cx="131.5" cy="131.5" r="131.5" transform="translate(98 123)" fill="#fff" />
        <g id="forgot_bg" transform="translate(122.469 151.311)">
          <g id="alarm_bg">
            <g id="Group_559" data-name="Group 559">
              <g id="Group_339" data-name="Group 339" transform="translate(0 44.888)" opacity="0.4">
                <g id="Group_338" data-name="Group 338">
                  <g id="Group_337" data-name="Group 337" clip-path="url(#clip-path)">
                    <line id="Line_133" data-name="Line 133" x2="7.968" transform="translate(0.716 4.7)" fill="none" stroke="#cbe991" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.918" />
                    <line id="Line_134" data-name="Line 134" y1="7.968" transform="translate(4.7 0.716)" fill="none" stroke="#cbe991" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.918" />
                  </g>
                </g>
              </g>
              <g id="Group_342" data-name="Group 342" transform="translate(104.951)" opacity="0.3">
                <g id="Group_341" data-name="Group 341">
                  <g id="Group_340" data-name="Group 340" clip-path="url(#clip-path-2)">
                    <line id="Line_135" data-name="Line 135" x2="6.723" transform="translate(1.034 4.396)" fill="none" stroke="#cbe991" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.77" />
                    <line id="Line_136" data-name="Line 136" y1="6.723" transform="translate(4.396 1.034)" fill="none" stroke="#cbe991" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.77" />
                  </g>
                </g>
              </g>
              <g id="Group_551" data-name="Group 551" transform="translate(113.742 121.408)">
                <line id="Line_137" data-name="Line 137" x2="4.736" transform="translate(0 2.368)" fill="none" stroke="#cbe991" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.952" />
                <line id="Line_138" data-name="Line 138" y1="4.736" transform="translate(2.368)" fill="none" stroke="#cbe991" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.952" />
              </g>
              <path id="Path_31467" data-name="Path 31467" d="M211.81,30.418a2.653,2.653,0,1,1-2.654-2.654A2.654,2.654,0,0,1,211.81,30.418Z" transform="translate(-169.385 -7.032)" fill="none" stroke="#cbe991" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.609" />
              <circle id="Ellipse_113" data-name="Ellipse 113" cx="3.898" cy="3.898" r="3.898" transform="translate(125.811 49.588)" fill="none" stroke="#cbe991" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364" opacity="0.5" />
              <circle id="Ellipse_114" data-name="Ellipse 114" cx="3.081" cy="3.081" r="3.081" transform="translate(60.241 128.145)" fill="none" stroke="#cbe991" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.693" />
              <circle id="Ellipse_115" data-name="Ellipse 115" cx="3.081" cy="3.081" r="3.081" transform="translate(9.4 99.527)" fill="none" stroke="#cbe991" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.693" />
            </g>
          </g>
        </g>
        <g id="Group_668" data-name="Group 668" transform="translate(193.699 214.505)">
          <path id="Path_31732" data-name="Path 31732" d="M38.676,0A38.676,38.676,0,1,1,0,38.676,38.676,38.676,0,0,1,38.676,0Z" transform="translate(3.471 2.636)" fill="#cbe991" />
          <circle id="Ellipse_117" data-name="Ellipse 117" cx="38.676" cy="38.676" r="38.676" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          <path id="Path_31468" data-name="Path 31468" d="M4391.163,1000.678a32.439,32.439,0,0,1,32.438,32.438" transform="translate(-4352.487 -994.44)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          <path id="Path_31469" data-name="Path 31469" d="M4398.942,1053.049l-4.36-4.361a1.541,1.541,0,0,0-2.181,0l-11.992,11.992-5.452-5.451a1.541,1.541,0,0,0-2.18,0l-4.36,4.361a1.541,1.541,0,0,0,0,2.18l10.9,10.9a1.539,1.539,0,0,0,2.18,0l17.443-17.443A1.542,1.542,0,0,0,4398.942,1053.049Z" transform="translate(-4341.935 -1016.072)" fill="#fff" />
          <path id="Path_31470" data-name="Path 31470" d="M4364.1,1092.62a32.438,32.438,0,0,1-32.438-32.438" transform="translate(-4325.421 -1021.506)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          <path id="Path_31471" data-name="Path 31471" d="M4397.452,1045.32l-4.361-4.36a1.539,1.539,0,0,0-2.18,0l-11.992,11.991-5.451-5.451a1.542,1.542,0,0,0-2.181,0l-4.358,4.361a1.541,1.541,0,0,0,0,2.18l10.9,10.9a1.54,1.54,0,0,0,2.181,0l17.442-17.442A1.542,1.542,0,0,0,4397.452,1045.32Z" transform="translate(-4341.258 -1012.557)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </g>
      </g>
    </svg>
  )
};

export default ForgotMailIc;

