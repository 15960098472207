/**
 *@file ServiceTypes.tsx
 *@brief Reusable ServiceTypes component
 *@date Oct, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */

import "./ServiceTypes.scss";

const ServiceItem = (props: any) => {
    return (
        <span className='service-type'>
            {props.type}
        </span>
    )
};

export default ServiceItem;
