/**
 * @file   src\containers\Payment.tsx
 * @brief  Payment page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from 'react';
import { Button, Col, Container, Row, Modal, InputGroup, Form, SplitButton, Badge, CloseButton } from 'react-bootstrap';
import '../assets/styles/payment.scss';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { productLock, createCustomer, productMakeOrder, productApplyCoupon } from '../store/actions/productAction';
import { ntfnCheck } from '../store/actions/ntfnAction';
import { resetLockProductState, resetCustomerCreateState, resetMakeOrderProductState, resetProductApplyCouponState } from '../store/slices/productSlice';
import * as CONSTANT from '../utils/constants';
import Strings from '../assets/strings/Strings.json';
import NBInputGroup from '../components/NBInputGroup';
import { getUser, getGusetUser, getAuthToken, getLatLong } from '../utils/localStorage';
import * as alerts from '../utils/alerts';
import PaymentSuccess from '../assets/img/paymentSuccess.svg';
import { IPaymentAddressData, IPaymentAddressDataError, ICouponData } from '../interfaces/GeneralInterface';
const Payment = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const guestUser = getGusetUser();
  const productInfo = location.state?.product_info;
  const user_address = location.state?.user_address;
  const deliveryOption =location.state?.deliveryOption;
  const quantity = location.state?.quantity;
  // const total = productInfo && parseFloat(productInfo?.prod_price.replace(/,/g, '')) * quantity;
  const customer = location.state?.customer_created;
  const user_details = JSON.parse(getUser());
  const latLong = JSON.parse(getLatLong());
  const productLockRsponse = useAppSelector((RootReducer) => RootReducer.product.lockProduct);
  const createCustomerRsponse = useAppSelector((RootReducer) => RootReducer.product.createCustomer);
  const productMakeOrderRsponse = useAppSelector((RootReducer) => RootReducer.product.makeOrderProduct);
  const productApplyCouponResponse = useAppSelector((RootReducer) => RootReducer.product.productApplyCoupon);
  const refAddress = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const [total, setTotal] = React.useState(productInfo && parseFloat(productInfo?.prod_price.replace(/,/g, '')) * quantity);
  const [loadMap, setLoadMap] = React.useState(false);
  const [place, setPlace] = React.useState(null);
  const [couponCode, setCouponCode] = React.useState();
  const [showDiscount, setShowDiscount] = React.useState(false);
  const [minimumAmountModal, setMinimumAmountModal] = React.useState(false);
  const [minimumAmountApplied, setMinimumAmountApplied] = React.useState(false);
  const [couponResponseData, setCouponResponseData] = React.useState<ICouponData>();
  const [customerData, setCustomerData] = React.useState<IPaymentAddressData>({
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state: '',
    country: '',
  });

  const [customerDataError, setCustomerDataError] = React.useState<IPaymentAddressDataError>({
    address1: '',
    city: '',
    zip: '',
    state: '',
  });
  const [load, setLoad] = React.useState<boolean>(false);
  const [showPayment, setShowPayment] = React.useState<boolean>(false);
  const [applyCouponLoad, setApplyCouponLoad] = React.useState(false);

  React.useEffect(() => {
    if (productLockRsponse.isLoading === false && productLockRsponse.isSuccess === true) {
      if (productLockRsponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        if (productLockRsponse.resultInfo.client_info.client_secret) {
          navigate('/paymentcard', {
            state: {
              client_secret: productLockRsponse.resultInfo.client_info.client_secret,
              billingDetails: productLockRsponse.resultInfo.client_info.billingDetails,
              customerInfo: productLockRsponse.resultInfo.client_info.customerInfo,
              total: !minimumAmountApplied && couponResponseData ? couponResponseData?.newTotal : total.toFixed(2),
              productInfo: productInfo,
              quantity: quantity,
              deliveryOption: deliveryOption,
              user_address: user_address,
              couponId: couponResponseData?.couponCode,
              is_minimum_order: !minimumAmountApplied ? CONSTANT.STATUS_0 : CONSTANT.STATUS_1,
            },
          });
        } else if (productLockRsponse.resultInfo.client_info.client_secret === null) {
          const payload = {
            prod_id: productInfo?.prod_id,
            qty: quantity,
            amount: couponResponseData?.newTotal ? couponResponseData?.newTotal : total,
            paymentIntentId: '',
            isZeroPayment: total === 0 ? 1 : 0,
            couponId: couponResponseData?.couponCode,
            is_minimum_order: !minimumAmountApplied ? CONSTANT.STATUS_0 : CONSTANT.STATUS_1,
            zipCode: latLong && latLong?.zipCode,
          };
          dispatch(productMakeOrder(payload));
        }
        handleClose();
      } else {
        toast.error(productLockRsponse.errorMessage);
      }
      setLoad(false);
      dispatch(resetLockProductState());
    } else if (productLockRsponse.isLoading === false && productLockRsponse.isSuccess === false) {
      setLoad(false);
      dispatch(resetLockProductState());
    }
  }, [productLockRsponse]);

  // handles create customer api response
  React.useEffect(() => {
    if (createCustomerRsponse.isLoading === false && createCustomerRsponse.isSuccess === true) {
      if (createCustomerRsponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        const payloads = {
          prod_id: productInfo?.prod_id,
          qty: quantity,
          amount: total.toFixed(2),
          isZeroPayment: total === 0 ? 1 : 0,
        };
        dispatch(productLock(payloads));
      } else {
        toast.error(createCustomerRsponse.errorMessage);
      }
      dispatch(resetCustomerCreateState());
    }
  }, [createCustomerRsponse]);

  // handles product make order api response
  React.useEffect(() => {
    if (productMakeOrderRsponse.isLoading === false && productMakeOrderRsponse.isSuccess === true) {
      if (productMakeOrderRsponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setShowPayment(true);
      } else {
        toast.error(productMakeOrderRsponse?.errorMessage);
        setLoad(false);
      }
    }
    dispatch(resetMakeOrderProductState());
  }, [productMakeOrderRsponse]);

  // handles product apply coupon api response
  React.useEffect(() => {
    if (productApplyCouponResponse.isLoading === false && productApplyCouponResponse.isSuccess === true) {
      setApplyCouponLoad(true);
      if (productApplyCouponResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setCouponCode('');
        setShowDiscount(true);
        setCouponResponseData(productApplyCouponResponse.resultInfo);
        if (productApplyCouponResponse.resultInfo.newTotal < CONSTANT.STATUS_1) {
          setMinimumAmountModal(true);
        }
        toast.success(productApplyCouponResponse?.errorMessage);
      } else {
        setShowDiscount(false);
        toast.error(productApplyCouponResponse?.errorMessage);
        setLoad(false);
      }
    }
    dispatch(resetProductApplyCouponState());
  }, [productApplyCouponResponse]);

  // handles google map loading
  React.useEffect(() => {
    if (!loadMap && show) {
      loadGoogleMapScript(() => {
        setLoadMap(true);
      });
    }
  }, [loadMap, show]);

  // handles google map
  React.useEffect(() => {
    if (loadMap && show) {
      initPlaceAPI();
    }
  }, [loadMap, show]);

  // handles notification call api
  React.useEffect(() => {
    dispatch<any>(ntfnCheck());
  }, []);

  // handles customer billing address input data
  React.useEffect(() => {
    if (place) {
      const address = place;
      let streetNumber = '';
      let routeInfo = '';
      let plusCode = '';
      let neighborhood = '';
      let premise = '';
      let businessCity = '';
      address.forEach((item: any) => {
        if (item.types.indexOf('street_number') >= 0) {
          streetNumber = item.long_name + ' ';
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: streetNumber,
          }));
        }

        if (item.types.indexOf('route') >= 0) {
          routeInfo = streetNumber + item.long_name + ' ';
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: routeInfo,
          }));
        }
        if (item.types.indexOf('plus_code') >= 0) {
          if (streetNumber === '') {
            plusCode = streetNumber + routeInfo + item.long_name + ' ';
          } else {
            plusCode = routeInfo + item.long_name + ' ';
          }
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: plusCode,
          }));
        }

        if (item.types.indexOf('neighborhood') >= 0) {
          if (streetNumber === '') {
            neighborhood = streetNumber + routeInfo + plusCode + item.long_name + ' ';
          } else {
            neighborhood = routeInfo + plusCode + item.long_name + ' ';
          }
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: neighborhood,
          }));
        }
        if (item.types.indexOf('premise') >= 0) {
          if (streetNumber === '') {
            premise = streetNumber + routeInfo + plusCode + neighborhood + item.long_name + ' ';
          } else {
            premise = routeInfo + plusCode + neighborhood + item.long_name + ' ';
          }
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: premise,
          }));
        }
        if (item.types.indexOf('administrative_area_level_1') >= 0) {
          setCustomerData((customerData: any) => ({
            ...customerData,
            state: item.short_name,
          }));
        }
        if (item.types.indexOf('locality') >= 0) {
          businessCity = item.short_name;
          setCustomerData((customerData: any) => ({
            ...customerData,
            city: item.short_name,
          }));
        } else if (item.types.indexOf('administrative_area_level_3') >= 0 && businessCity === '') {
          setCustomerData((customerData: any) => ({
            ...customerData,
            city: item.short_name,
          }));
        }

        if (item.types.indexOf('postal_code') >= 0) {
          setCustomerData((customerData: any) => ({
            ...customerData,
            zip: item.long_name,
          }));
        }
        if (item.types.indexOf('country') >= 0) {
          setCustomerData((customerData: any) => ({
            ...customerData,
            country: item.short_name,
          }));
        }
      });
    }
  }, [place]);

  // handles product checkout
  const handleProductCheckout = () => {
    if (customer === 0) {
      handleShow();
    } else if (productInfo) {
      const payload = {
        prod_id: productInfo?.prod_id,
        qty: quantity,
        amount: !minimumAmountApplied && couponResponseData ? couponResponseData?.newTotal : total.toFixed(2),
        isZeroPayment: total === 0 ? 1 : 0,
      };
      dispatch(productLock(payload));
      setLoad(true);
    }
  };

  // handles customer billing address popup
  const handleClose = () => {
    setShow(false);
    setCustomerData((customerData: any) => ({
      ...customerData,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    }));
  };

  // handle success modal
  const handleShow = () => {
    setShow(true);
    setLoad(false);
  };

  // handles customer data changes
  const handleCustomerChange = (event: any) => {
    setCustomerData((customerData: any) => ({
      ...customerData,
      [event.target.name]: event.target.value,
    }));
  };

  // handles customer address validations
  const validate = () => {
    let isValid = true;
    setCustomerDataError((customerDataError: any) => ({ ...customerDataError, address1: '', address2: '', city: '', state: '', zip: '' }));
    if (customerData.address1.trim() === '') {
      setCustomerDataError((customerDataError: any) => ({ ...customerDataError, address1: alerts.ENTER_ADDRESS }));
      isValid = false;
    }

    return isValid;
  };

  // load google map script
  const loadGoogleMapScript = (callback: any) => {
    if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
      callback();
    } else {
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}&callback=Function.prototype&libraries=places`;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener('load', callback);
      setLoadMap(true);
    }
  };

  // load google map script
  const initPlaceAPI = () => {
    setTimeout(() => {
      if (window.google) {
        let autocomplete = new window.google.maps.places.Autocomplete(refAddress.current, {
          fields: ['address_components', 'geometry'],
          types: ['address'],
        });
        new window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
          let place = autocomplete.getPlace();
          setCustomerData((customerData: any) => ({
            ...customerData,
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
          }));
          setPlace(place.address_components);
        });
      }
    }, 500);
  };

  // handles customer create data
  const handleCustomerCreate = () => {
    const validation = validate();
    if (validation) {
      const payload = {
        email: user_details?.email,
        full_name: user_details?.f_name + user_details?.l_name,
        addressLine1: customerData?.address1,
        addressLine2: customerData?.address2,
        phone: user_details?.mob_number,
        postal_code: customerData?.zip,
        city: customerData?.city,
        state: customerData?.state,
        country: customerData?.country,
        prod_id: productInfo?.prod_id,
      };
      dispatch(createCustomer(payload));
    }
  };
  // This will go back to the previous page in the browser history
  const handleGoBack = () => {
    navigate(`/productdetails/${productInfo?.prod_id}`, { state: { product_id: productInfo?.prod_id, guestUser: guestUser === true ? '' : getAuthToken() } });
  };

  // handle payment success modal close
  const handleClosePaymentModal = () => {
    setShowPayment(false);
    navigate('/');
  };

  // handle Coupon modal close
  const minimumAmountModalClose = () => {
    setMinimumAmountModal(false);
  };

  // handle Coupon cancel  modal
  const minimumAmountModalCancel = () => {
    minimumAmountModalClose();
    window.history.back();
  };

  const handleCouponCodeChange = (event: { target: { name: any; value: any } }) => {
    setApplyCouponLoad(false);
    setCouponCode(event.target.value);
  };

  const handleAppluCouponBtn = () => {
    setApplyCouponLoad(true);
    const payload = {
      couponId: couponCode,
      total: total.toFixed(2),
      vendorId: productInfo?.vendor_id,
    };
    dispatch(productApplyCoupon(payload));
  };

  const handleMinimumAmount = () => {
    setTotal(CONSTANT.STATUS_1 * quantity);
    setMinimumAmountApplied(true);
    minimumAmountModalClose();
  };

  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container>
          <Row>
            <Col md="6" className="d-flex align-items-center">
              <h2>{Strings.Payment.Title}</h2>
            </Col>
            <Col md="6" className="text-md-end btn-wrap">
              <Button onClick={handleGoBack} variant="secondary">
                {Strings.Payment.Back}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container className="payment">
          <Row>
            <Col lg="12">
              <Row>
                <Col lg="3" xl="3" className="item-image d-flex">
                  <img src={productInfo?.thumbnail_image} alt="" />
                </Col>
                <Col lg="4" xl="6" className="item-des">
                  <h2>{Strings.Payment.Description}</h2>
                  <p>{productInfo?.prod_description}</p>
                  <span className="price d-inline-block">${productInfo?.prod_price}</span>
                </Col>
                <Col lg="5" xl="3" className="item-price">
                  <div className="price-dtl-wrap">
                    <div className="price-item d-flex justify-content-between">
                      <span>Quantity</span>
                      <span className="highlight">{quantity}</span>
                    </div>
                    {total > CONSTANT.STATUS_0 ? (
                      <div className="apply-coupon mt-4 mb-4">
                        <label>Have a Coupon Code?</label>
                        <InputGroup>
                          <Form.Control onChange={handleCouponCodeChange} value={couponCode} maxLength={CONSTANT.STATUS_10} />
                          <Button
                            disabled={applyCouponLoad}
                            onClick={() => {
                              handleAppluCouponBtn();
                            }}
                          >
                            Apply
                          </Button>
                        </InputGroup>
                        {showDiscount ? <Badge className="d-flex align-items-center">{couponResponseData?.couponCode}</Badge> : ''}
                      </div>
                    ) : (
                      ''
                    )}

                    {showDiscount ? (
                      <div className="price-item d-flex justify-content-between mb-2">
                        <span>Subtotal</span>
                        <span className="highlight">${total.toFixed(2)}</span>
                      </div>
                    ) : (
                      ''
                    )}

                    {showDiscount ? (
                      <div className="price-item d-flex justify-content-between align-items-center mb-2">
                        <span className="d-flex align-items-center">
                          Discount
                          <span className="discount-tag">{couponResponseData?.couponCode}</span>
                        </span>
                        <span className="highlight">${couponResponseData?.discountAmount.toFixed(2)}</span>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="price-item d-flex justify-content-between align-items-center mb-2">
                      <span>Total</span>
                      <span className="total">${showDiscount && !minimumAmountApplied ? couponResponseData?.newTotal : total.toFixed(2)}</span>
                    </div>

                    <Button disabled={load} onClick={handleProductCheckout} variant="primary" className="w-100 mt-4">
                      {load ? <Spinner animation="border" size="sm" variant="light" /> : <>{Strings.Payment.CheckOut}</>}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal show={show} onHide={handleClose} centered backdrop="static">
          <Modal.Body>
            <Col>
              <h1 style={{ paddingBottom: 25 }}>Billing Address</h1>
              <Col xl={12}>
                <NBInputGroup
                  id="address1"
                  name="address1"
                  type="text"
                  label="Address 1"
                  onChange={handleCustomerChange}
                  value={customerData.address1}
                  error={customerDataError.address1}
                  ref={refAddress}
                />
              </Col>
              <Col xl={12}>
                <NBInputGroup id="address2" name="address2" type="text" label="Address 2" onChange={handleCustomerChange} value={customerData.address2} />
              </Col>
              <Row>
                <Col xl="7" lg="6" md="6">
                  <NBInputGroup id="city" name="city" type="text" label="City" value={customerData.city} error={customerDataError.city} />
                </Col>
                <Col>
                  <NBInputGroup id="zip" name="zip" type="text" label="ZIP code" value={customerData.zip} error={customerDataError.zip} />
                </Col>
              </Row>
              <Col xl={12}>
                <NBInputGroup id="state" name="state" type="text" label="State" value={customerData.state} error={customerDataError.state} />
              </Col>
              <div className="button-wrap">
                <Button onClick={handleClose} style={{ marginTop: 10, marginRight: 15 }} variant="secondary">
                  Cancel
                </Button>
                <Button onClick={handleCustomerCreate} style={{ marginTop: 10 }} variant="primary">
                  Continue
                </Button>
              </div>
            </Col>
          </Modal.Body>
        </Modal>
        <Modal show={showPayment} onHide={handleClosePaymentModal} centered size="sm">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pt-3 pb-5 text-center">
            <img src={PaymentSuccess} />
            <h4>Success!</h4>
          </Modal.Body>
        </Modal>

        <Modal show={minimumAmountModal} centered backdrop="static">
          <Modal.Body className="pt-7 pb-5 text-center">
            <h1 className="text-center">{Strings.Payment.Modal.label}</h1>
            <div className="btn-wrap d-flex">
              <Button onClick={minimumAmountModalCancel} variant="secondary">
                {Strings.Payment.Modal.Btn2}
              </Button>
              <Button onClick={handleMinimumAmount} variant="primary">
                {Strings.Payment.Modal.Btn1}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default Payment;
