const ShareIc = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="icn_share" transform="translate(1 1)">
        <rect id="Rectangle_612" data-name="Rectangle 612" width="24" height="24" transform="translate(-1 -1)" fill="none" stroke="none" />
        <circle
          id="Ellipse_184"
          data-name="Ellipse 184"
          cx="2.68"
          cy="2.68"
          r="2.68"
          transform="translate(1.174 8.32)"
          fill="none"
          stroke="#262626"
          stroke-linecap="square"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <circle
          id="Ellipse_185"
          data-name="Ellipse 185"
          cx="2.68"
          cy="2.68"
          r="2.68"
          transform="translate(13.682 1.173)"
          fill="none"
          stroke="#262626"
          stroke-linecap="square"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <circle
          id="Ellipse_186"
          data-name="Ellipse 186"
          cx="2.68"
          cy="2.68"
          r="2.68"
          transform="translate(13.682 15.467)"
          fill="none"
          stroke="#262626"
          stroke-linecap="square"
          stroke-miterlimit="10"
          stroke-width="2"
        />
        <line id="Line_167" data-name="Line 167" y1="4.468" x2="7.862" transform="translate(6.176 5.192)" fill="none" stroke="#262626" stroke-miterlimit="10" stroke-width="2" />
        <line id="Line_168" data-name="Line 168" x2="7.862" y2="4.468" transform="translate(6.176 12.34)" fill="none" stroke="#262626" stroke-miterlimit="10" stroke-width="2" />
      </g>
    </svg>
  );
};

export default ShareIc;
