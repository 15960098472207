
const CurrentLocation = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_716" data-name="Rectangle 716" width="22" height="22" fill="none" stroke="#444" stroke-width="1" />
        </clipPath>
      </defs>
      <g id="current_location" transform="translate(-1002 -417)">
        <rect id="Rectangle_785" data-name="Rectangle 785" width="24" height="24" transform="translate(1002 417)" stroke="none" fill="none" />
        <g id="Group_1817" data-name="Group 1817" transform="translate(1003 418)" clip-path="url(#clip-path)">
          <circle id="Ellipse_189" data-name="Ellipse 189" cx="8" cy="8" r="8" transform="translate(3 3)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          <line id="Line_182" data-name="Line 182" y1="4" transform="translate(11 1)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          <line id="Line_183" data-name="Line 183" y2="4" transform="translate(11 17)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          <line id="Line_184" data-name="Line 184" x2="4" transform="translate(17 11)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          <line id="Line_185" data-name="Line 185" x1="4" transform="translate(1 11)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </g>
      </g>
    </svg>

  )
};

export default CurrentLocation;

