import React from 'react';
import { Form } from 'react-bootstrap';
import { InputProperty } from '../interfaces/GeneralInterface';
import './NBSelect.scss';
interface Input extends InputProperty {}

/**
 *@file NBInputGroup.tsx
 *@brief Reusable input group component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
const NBSelect = React.forwardRef((props: Input, ref: any) => {
  const propsValue = props.value ? props.value : '';
  const optionValues = props.options;
  const { defaultPlaceHolder } = props;
  const { handleSelectChange } = props;
  const { error } = props;
  return (
    <Form.Group className="nb-inputgroup">
      <Form.Label>{props.label}</Form.Label>
      <Form.Select
        disabled={props.disabled}
        aria-label="Default select example"
        onChange={(event) => {
          handleSelectChange(event.target.value);
        }}
        value={propsValue}
      >
        {defaultPlaceHolder && (
          <option value="" disabled selected>
            {defaultPlaceHolder}
          </option>
        )}
        {optionValues?.length > 0 ? optionValues.map((items: any) => <option value={items.reason_id}>{items.reason}</option>) : ''}
      </Form.Select>

      {error && error !== '' ? (
        <Form.Text className="error" style={{ color: 'red' }}>
          {error}
        </Form.Text>
      ) : (
        ''
      )}
    </Form.Group>
  );
});

export default NBSelect;
