/**
 * @file   src\hooks\index.ts
 * @brief  This file is responsible for exporting typed dispatch/selector hooks.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';

// useDispatch hook.
export const useAppDispatch = () => useDispatch<AppDispatch>();
// useSelector hook.
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
