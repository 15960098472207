/**
 * @file   src\components\Footer.tsx
 * @brief  Footer component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import './Footer.scss';
import Strings from '../assets/strings/Strings.json';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='d-flex justify-content-center align-items-center'>
      <Container>
        <Row>
          <Col md='6'>{Strings.Footer.Copyright}</Col>
          <Col md='6' className='text-md-end'>
            <NavLink to="/terms">{Strings.Footer.LinkText}</NavLink>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
