/**
 * @file   src\components\MainMenu.tsx
 * @brief  MainMenu component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { NavLink, Link } from 'react-router-dom';
import './Header.scss';
import Strings from '../assets/strings/Strings.json';
import { Modal, Button } from 'react-bootstrap';
import * as alerts from '../utils/alerts';
import { useState } from 'react';
import { getGusetUser, getUser } from '../utils/localStorage';
import RequestOfferModal from './RequestOfferModal';

const MainMenu = () => {
  const guestUser = getGusetUser();
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [succesModal, setSuccesModal] = useState<boolean>(false);
  const UserInfo = JSON.parse(getUser());
  // handle modal close and open function
  const showLocationModalClose = () => {
    setShowLocationModal(false);
  };
  const showLocationModalOpen = () => {
    setShowLocationModal(true);
  };
  // handle modal confirm function
  const showLocationModalConfirm = () => {
    setShowLocationModal(false);
    window.open('https://trynearbuys.com/', '_blank');
  };
  const pathName = window.location.pathname;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseSuccesModal = () => {
    setSuccesModal(false);
  };
  return (
    <>
      {/* <Link to="#" className={pathName === '/aboutus' ? 'active' : ''} onClick={showLocationModalOpen}>
        {Strings.Header.MainMenu.MenuItem1}
      </Link> */}
      <NavLink to="/about">{Strings.Header.MainMenu.MenuItem1}</NavLink>
      <NavLink to="/vendorpricing">{Strings.Header.MainMenu.MenuItem6}</NavLink>
      <NavLink to="/contact">{Strings.Header.MainMenu.MenuItem3}</NavLink>
      <NavLink to="https://shopvanacom.zendesk.com/hc/en-us/sections/20441694138519-Getting-Started" target="_blank">
        {Strings.Header.MainMenu.MenuItem5}
      </NavLink>
      <Link onClick={() => handleShow()} to="#">
        {Strings.Header.MainMenu.MenuItem7}
      </Link>

      {(!UserInfo || guestUser === true) && (
        <NavLink target="_parent" to="/signin">
          {Strings.Header.MainMenu.MenuItem4}
        </NavLink>
      )}
      <Modal show={showLocationModal} onHide={showLocationModalClose} centered backdrop="static">
        <Modal.Body className="otp-verify">
          <h5 className="text-center d-block pt-3">
            {alerts.ABOUT_US_CONFIRM_ALERT}
            <br />
          </h5>

          <div className="btn-wrap d-flex pt-4">
            <Button onClick={showLocationModalClose} variant="secondary">
              {Strings.Header.DropDownMenu.signOut.Btn2}
            </Button>
            <Button onClick={showLocationModalConfirm} variant="primary">
              {Strings.Header.DropDownMenu.signOut.Btn1}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <RequestOfferModal show={show} handleClose={handleClose} setSuccesModal={setSuccesModal} />
      <Modal show={succesModal} onHide={handleCloseSuccesModal} centered backdrop="static" className="report">
        <Modal.Body>
          <h5 className="text-center d-block pt-3">
            Keep an eye on your offer. The Shopvana vendors are working on it!
            <br />
          </h5>
          <div className="text-center d-block pt-3">
            <Button variant="primary" onClick={handleCloseSuccesModal}>
              Ok
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MainMenu;
