/**
 * @file   src\containers\VendorPricing.tsx
 * @brief  Vendor Pricing page
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Strings from '../assets/strings/Strings.json';
import '../assets/styles/pricing.scss';

const VendorPricing = () => {
  const handleClick = () => {
    window.open(process.env.REACT_APP_VENDOR_SITE_URL);
  };
  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container className="d-flex align-items-middle justify-content-between">
          <h2>Vendor Pricing</h2>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <div className="pricing-details">
            <Row>
              <Col xl="3" lg="6">
                <div className="pricing-outer tier3">
                  <h3>Monthly</h3>
                  <div className="header-wrap">
                    <h4>
                    $12 <span>/Months</span>
                    </h4>
                    <button onClick={handleClick}>Subscribe</button>
                  </div>
                  <div className="plan-features">
                    <div className="plan-features-top">
                      <ul>
                        <li>30 Saved Offers Per Month</li>
                        <li>30 Nationwide Listings Per Month</li>
                        <li>80 Offerings Per Month</li>
                        <li>80 Manual Broadcasts Per Month (60 Items Per Broadcast)</li>
                        <li>30 Scheduled Broadcasts Per Month (60 Items Per Broadcast)</li>
                        <li>Add 5 Proxy Zip codes</li>
                      </ul>
                    </div>
                    <div className="plan-features-bottom">
                      <ul>
                        <li>Insights of app users by zip code</li>
                        <li>Analyze zipcodes using ZCTA data</li>
                        <li>Create and manage coupons</li>
                        <li>0% Commission Charges</li>
                        <li>Freshness Label For Food</li>
                        <li>Chat With Consumers</li>
                        <li>Stripe Verified Badge</li>
                        <li>Classifieds Feature</li>
                        <li>Add Proxy Address</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl="3" lg="6">
                <div className="pricing-outer tier1">
                  <h3>Basic</h3>
                  <div className="header-wrap">
                    <h4>
                      $60 <span>/Annually</span>
                    </h4>
                    <button onClick={handleClick}>Sign up for free trial</button>
                  </div>
                  <div className="plan-features">
                    <div className="plan-features-top">
                      <ul>
                        <li>30 Saved Offers Per Month</li>
                        <li>30 Nationwide Listings Per Month</li>
                        <li>30 Offerings Per Month</li>
                        <li>30 Manual Broadcasts Per Month (20 Items Per Broadcast)</li>
                        <li>4 Scheduled Broadcasts Per Month (20 Items Per Broadcast)</li>
                        <li>Add 1 Proxy Zip code</li>
                      </ul>
                    </div>
                    <div className="plan-features-bottom">
                      <ul>
                        <li>Insights of app users by zip code</li>
                        <li>Analyze zipcodes using ZCTA data</li>
                        <li>Create and manage coupons</li>
                        <li>0% Commission Charges</li>
                        <li>Freshness Label For Food</li>
                        <li>Chat With Consumers</li>
                        <li>Stripe Verified Badge</li>
                        <li>Classifieds Feature</li>
                        <li className="no-bg">Add Proxy Address</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl="3" lg="6">
                <div className="pricing-outer tier2">
                  <h3>Advanced</h3>
                  <div className="header-wrap">
                    <h4>
                      $90 <span>/Annually</span>
                    </h4>
                    <button onClick={handleClick}>Sign up for free trial</button>
                  </div>
                  <div className="plan-features">
                    <div className="plan-features-top">
                      <ul>
                        <li>30 Saved Offers Per Month</li>
                        <li>30 Nationwide Listings Per Month</li>
                        <li>60 Offerings Per Month</li>
                        <li>60 Manual Broadcasts Per Month (40 Items Per Broadcast)</li>
                        <li>10 Scheduled Broadcasts Per Month (40 Items Per Broadcast)</li>
                        <li>Add 2 Proxy Zip codes</li>
                      </ul>
                    </div>
                    <div className="plan-features-bottom">
                      <ul>
                        <li>Insights of app users by zip code</li>
                        <li>Analyze zipcodes using ZCTA data</li>
                        <li>Create and manage coupons</li>
                        <li>0% Commission Charges</li>
                        <li>Freshness Label For Food</li>
                        <li>Chat With Consumers</li>
                        <li>Stripe Verified Badge</li>
                        <li>Classifieds Feature</li>
                        <li>Add Proxy Address</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl="3" lg="6">
                <div className="pricing-outer tier4">
                  <h3>Ultimate</h3>
                  <div className="header-wrap">
                    <h4>
                      $110 <span>/Annually</span>
                    </h4>
                    <button onClick={handleClick}>Sign up for free trial</button>
                  </div>
                  <div className="plan-features">
                    <div className="plan-features-top">
                      <ul>
                        <li>30 Saved Offers Per Month</li>
                        <li>30 Nationwide Listings Per Month</li>
                        <li>80 Offerings Per Month</li>
                        <li>80 Manual Broadcasts Per Month (60 Items Per Broadcast)</li>
                        <li>30 Scheduled Broadcasts Per Month (60 Items Per Broadcast)</li>
                        <li>Add 5 Proxy Zip codes</li>
                      </ul>
                    </div>
                    <div className="plan-features-bottom">
                      <ul>
                        <li>Insights of app users by zip code</li>
                        <li>Analyze zipcodes using ZCTA data</li>
                        <li>Create and manage coupons</li>
                        <li>0% Commission Charges</li>
                        <li>Freshness Label For Food</li>
                        <li>Chat With Consumers</li>
                        <li>Stripe Verified Badge</li>
                        <li>Classifieds Feature</li>
                        <li>Add Proxy Address</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default VendorPricing;
