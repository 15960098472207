import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Strings from '../../../assets/strings/Strings.json';
const RemoveIc = (props: any) => {
  return (
    <OverlayTrigger placement='bottom' overlay={<Tooltip id="tooltip-disabled">{Strings.OfferRequest.CloseBtn}</Tooltip>}>
    <svg xmlns="http://www.w3.org/2000/svg" width="13.895" height="13.895" viewBox="0 0 13.895 13.895">
    <g id="Group_2158" data-name="Group 2158" transform="translate(-1340.979 -580.873)">
      <path id="chevron-left" d="M1,13.895a1,1,0,0,1-.707-1.707l5.24-5.24L.293,1.707A1,1,0,1,1,1.707.293L7.654,6.24a1,1,0,0,1,0,1.414L1.707,13.6A1,1,0,0,1,1,13.895Z" transform="translate(1354.873 580.873) rotate(90)"/>
      <path id="chevron-left-2" data-name="chevron-left" d="M1,13.895a1,1,0,0,1-.707-1.707l5.24-5.24L.293,1.707A1,1,0,0,1,1.707.293L7.654,6.24a1,1,0,0,1,0,1.414L1.707,13.6A1,1,0,0,1,1,13.895Z" transform="translate(1340.979 594.768) rotate(-90)"/>
    </g>
  </svg>
  </OverlayTrigger>
  )
};

export default RemoveIc;

