
const Visit = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="Group_1853" data-name="Group 1853" transform="translate(4.734 2.231)">
        <rect id="Rectangle_726" data-name="Rectangle 726" width="32" height="32" transform="translate(-4.734 -2.231)" fill="none" />
        <g id="map-pin">
          <path id="Path_1" data-name="Path 1" d="M25.531,12.265c0,8.762-11.265,16.272-11.265,16.272S3,21.027,3,12.265a11.265,11.265,0,0,1,22.531,0Z" transform="translate(-3 -1)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          <circle id="Ellipse_5" data-name="Ellipse 5" cx="5.633" cy="5.633" r="5.633" transform="translate(5.633 6.102)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </g>
      </g>
    </svg>
  )
};

export default Visit;

