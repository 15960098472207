
const Website = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g id="Group_1855" data-name="Group 1855" transform="translate(-1524.311 -404.311)">
        <rect id="Rectangle_728" data-name="Rectangle 728" width="36" height="36" transform="translate(1524.311 404.311)" fill="none" />
        <g id="website" transform="translate(1443.33 328)">
          <path id="Path_31720" data-name="Path 31720" d="M98.982,82c.187,0,.37,0,.544.012a12.311,12.311,0,0,1,0,24.6c-.173.008-.354.012-.543.012-.255,0-.507-.008-.749-.023a12.311,12.311,0,0,1,0-24.576C98.475,82.008,98.728,82,98.982,82Zm0,23.772c.175,0,.345,0,.506-.011a11.462,11.462,0,0,0,0-22.9c-.162-.007-.332-.011-.507-.011-.238,0-.472.007-.7.021a11.462,11.462,0,0,0,0,22.881C98.511,105.765,98.747,105.772,98.982,105.772Z" transform="translate(0 0)" fill="#94da0a" />
          <path id="Path_31721" data-name="Path 31721" d="M94.405,82.172h.018a12.311,12.311,0,0,1,0,24.6.417.417,0,0,1-.212-.045c-.176-.089-.359-.187-.542-.291l-.056-.031-.052-.03h-.006a13.74,13.74,0,0,1,0-23.8l.006,0,.052-.03.058-.033c.181-.1.364-.2.54-.289A.424.424,0,0,1,94.405,82.172Zm-.637.768Zm.729,22.976a11.462,11.462,0,0,0,0-22.891c-.134.071-.271.142-.409.221l-.051.029-.058.033a12.9,12.9,0,0,0,0,22.326l.058.033.049.028Q94.3,105.812,94.5,105.916Z" transform="translate(5.102 -0.16)" fill="#94da0a" />
          <path id="Path_31722" data-name="Path 31722" d="M173.663,82.31a.424.424,0,0,1,.193.047c.219.112.432.228.633.343l.079.047.033.02a13.745,13.745,0,0,1,0,23.664l-.034.021-.079.046c-.2.117-.414.232-.632.344a.425.425,0,0,1-.219.046,12.311,12.311,0,0,1,0-24.576Zm-.09,23.721c.169-.088.334-.178.491-.27l.071-.042.035-.021a12.894,12.894,0,0,0,0-22.2l-.034-.02-.071-.042c-.156-.09-.321-.18-.491-.269a11.462,11.462,0,0,0,0,22.865Z" transform="translate(-75.404 -0.288)" fill="#94da0a" />
          <path id="Path_31723" data-name="Path 31723" d="M141.936,82a12.186,12.186,0,0,1,5.448,1.268,12.31,12.31,0,0,1,3.475,2.56.424.424,0,0,1,0,.585,12.24,12.24,0,0,1-8.38,3.816c-.173.008-.354.012-.543.012-.255,0-.507-.008-.749-.023a12.262,12.262,0,0,1-8.172-3.8.424.424,0,0,1,0-.585,12.31,12.31,0,0,1,3.291-2.468A12.178,12.178,0,0,1,141.936,82Zm8.019,4.121a11.452,11.452,0,0,0-16.037,0,11.41,11.41,0,0,0,7.321,3.25c.226.014.461.021.7.021.175,0,.345,0,.506-.011A11.4,11.4,0,0,0,149.955,86.121Z" transform="translate(-42.954 16.38)" fill="#94da0a" />
          <path id="Path_31724" data-name="Path 31724" d="M141.946,313.519c.187,0,.37,0,.544.012a12.24,12.24,0,0,1,8.378,3.816.424.424,0,0,1,0,.585,12.3,12.3,0,0,1-17.844,0,.425.425,0,0,1,0-.585,12.265,12.265,0,0,1,8.173-3.8C141.439,313.527,141.691,313.519,141.946,313.519Zm8.019,4.12a11.4,11.4,0,0,0-7.512-3.261c-.162-.007-.332-.011-.507-.011-.238,0-.472.007-.7.021a11.412,11.412,0,0,0-7.321,3.25,11.454,11.454,0,0,0,16.037,0Z" transform="translate(-42.964 -231.519)" fill="#94da0a" />
          <path id="Path_31725" data-name="Path 31725" d="M87.095,250h23.772a.424.424,0,1,1,0,.849H87.095a.424.424,0,1,1,0-.849Z" transform="translate(0.002 -156.114)" fill="#94da0a" />
          <path id="Path_31726" data-name="Path 31726" d="M255.094,82a.424.424,0,0,1,.424.425V106.2a.424.424,0,0,1-.849,0V82.425A.424.424,0,0,1,255.094,82Z" transform="translate(-156.112 0)" fill="#94da0a" />
        </g>
      </g>
    </svg>
  )
};

export default Website;

