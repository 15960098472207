/**
 * @file   src\components\AppsAd.tsx
 * @brief  AppsAd component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import './AppsAd.scss';
import Strings from '../assets/strings/Strings.json';
import { Col, Row } from 'react-bootstrap';
import Appstore from '../assets/img/AppStore-Btn.svg';
import Playstore from '../assets/img/PlayStore-Btn.svg';

const AppsAd = () => {
  return (
    <div className='apps-ad'>
      <Row>
        <Col xl='6'>
          <p>{Strings.AppsAd.Text1}</p>
          <p>{Strings.AppsAd.Text2}</p>
        </Col>
        <Col xl='6' className='d-flex align-items-end btn-wrap'>
          <img onClick={() => {alert('Not implemented')}} src={Appstore} alt='' />
          <img onClick={() => {alert('Not implemented')}} src={Playstore} alt='' />
        </Col>
      </Row>
    </div>
  );
};

export default AppsAd;
