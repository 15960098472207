
const ShowPassword = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="view-password" transform="translate(-1675 -481)">
        <rect id="Rectangle_723" data-name="Rectangle 723" width="24" height="24" transform="translate(1675 481)" fill="none" />
        <path id="view-password-2" data-name="view-password" d="M12,16a4.48,4.48,0,0,0,4.5-4.5A4.48,4.48,0,0,0,12,7a4.48,4.48,0,0,0-4.5,4.5A4.48,4.48,0,0,0,12,16Zm0-1.8a2.69,2.69,0,0,1-2.7-2.7A2.689,2.689,0,0,1,12,8.8a2.687,2.687,0,0,1,2.7,2.7A2.689,2.689,0,0,1,12,14.2ZM12,19A11.774,11.774,0,0,1,1,11.5,11.771,11.771,0,0,1,12,4a11.771,11.771,0,0,1,11,7.5A11.774,11.774,0,0,1,12,19ZM12,11.5ZM12,17a9.545,9.545,0,0,0,5.188-1.488A9.771,9.771,0,0,0,20.8,11.5a9.777,9.777,0,0,0-3.612-4.013A9.549,9.549,0,0,0,12,6,9.549,9.549,0,0,0,6.812,7.487,9.777,9.777,0,0,0,3.2,11.5a9.771,9.771,0,0,0,3.612,4.012A9.545,9.545,0,0,0,12,17Z" transform="translate(1675 481.5)" />
      </g>
    </svg>
  )
};

export default ShowPassword;

