/**
 * @file   src\store\actions\authAction.ts
 * @brief  This file is responsible for generating asynchronous api call.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { GeneralApi } from '../../utils/apiUrls';
import { getAuthToken } from '../../utils/localStorage'

// Encrypt Header
const headers: any = {};
headers['Access-Control-Allow-Origin'] = '*';
headers['Access-Control-Allow-Headers'] = '*';
headers['Access-Control-Allow-Credentials'] = true;
headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, PATCH, DELETE';
headers['Content-Type'] = 'text/plain';
headers.Accept = 'text/plain';
headers['Cache-Control'] = 'no-cache';
headers['api_key'] = process.env.REACT_APP_API_KEY;

export const clearNtfn = createAsyncThunk('/auth/clear', async (_, { rejectWithValue }) => {
    try {
        const headers = { Authorization: 'Bearer ' + getAuthToken() };
        const { data } = await axios.post(GeneralApi.CLEARNTFN, undefined, { headers });
        return data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data);
    }
});

export const ntfnCheck = createAsyncThunk('/auth/ntfnavailable', async (_, { rejectWithValue }) => {
    try {
        const headers = { Authorization: 'Bearer ' + getAuthToken() };
        const { data } = await axios.post(GeneralApi.CHECKNTFN, undefined, { headers });
        return data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data);
    }
});

export const ntnList = createAsyncThunk('/auth/ntfnlist', async (_, { rejectWithValue }) => {
    try {
        const headers = { Authorization: 'Bearer ' + getAuthToken() };
        const { data } = await axios.post(GeneralApi.LISTNTFN, undefined, { headers });
        return data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data);
    }
});

export const ntfnRead = createAsyncThunk('/auth/read', async (payload: any, { rejectWithValue }) => {
    try {
        const headers = { Authorization: 'Bearer ' + getAuthToken() };
        const { data } = await axios.post(GeneralApi.READNTFN, payload, { headers });
        return data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data);
    }
});