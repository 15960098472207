const EditAddress = (props: any) => {
  return (
    <svg onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="18.001" viewBox="0 0 18 18.001">
      <g id="ic_edit_address" transform="translate(-0.5 -0.424)" opacity="0.5">
        <path id="Path_32037" data-name="Path 32037" d="M14.406,18.076H2.92A2.423,2.423,0,0,1,.5,15.656V4.17A2.423,2.423,0,0,1,2.92,1.75H8.663a.779.779,0,0,1,0,1.558H2.92a.863.863,0,0,0-.862.862V15.656a.863.863,0,0,0,.862.862H14.406a.863.863,0,0,0,.862-.862V9.913a.779.779,0,1,1,1.558,0v5.743A2.423,2.423,0,0,1,14.406,18.076Z" transform="translate(0 0.35)" />
        <path id="Path_32038" data-name="Path 32038" d="M14.478.424a2.425,2.425,0,0,1,1.715,4.141l-7.5,7.5a.75.75,0,0,1-.348.2l-3.159.79a.75.75,0,0,1-.91-.91l.79-3.159a.75.75,0,0,1,.2-.348l7.5-7.5A2.41,2.41,0,0,1,14.478.424ZM7.776,10.86,15.132,3.5A.925.925,0,0,0,13.823,2.2L6.467,9.552,6.031,11.3Z" transform="translate(1.598)" />
      </g>
    </svg>

  )
};

export default EditAddress;

