import React, { useState } from 'react';
import './Terms.scss';
import { Col } from 'react-bootstrap';
import * as Constants from '../utils/constants';

const TermsConditions = () => {
  return (
    <Col className="terms-condition">
      <h3 className="mb-4">AppVentures LLC</h3>
      <h3>TERMS OF USE </h3>
      <p>
        Effective Date: <strong>{Constants.EFFECTIVE_DATE}</strong>
      </p>
      <p>
        This website,{' '}
        <a href="https://www.nearbuys.com/" target="_blank">
          www.nearbuys.com
        </a>
        , is owned and operated by <strong>AppVentures LLC</strong> or one of its affiliates <strong>(“NearBuys,” “we,” “our,” or “us”)</strong>. These Terms of Use apply to our
        application and any website (as well as any features, ‎widgets, ‎plug-ins, content, downloads or other services) ‎that posts a link to this page (collectively, the{' '}
        <strong>“Services”</strong>). By accessing the Services, you (<strong>“you”</strong> or <strong>“your”</strong>) acknowledge and consent to (i) these Terms of Use (
        <strong>“Terms”</strong>), (ii) our collection, use and sharing of your information as described in our Privacy Policy, and (iii) any other legal notices, conditions or
        guidelines located within the Services. If you do not agree to these Terms, our Privacy Policy, or any other legal notices, conditions or guidelines location within the
        Services, please exit our Services. If you have any questions regarding our policies or your use of our Services, please contact us by e-mail at{' '}
        <a href="mailto:info@nearbuys.com">info@nearbuys.com</a> or by mail to <a href="mailto:admin@nearbuys.com">admin@nearbuys.com</a>, PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY
        BEFORE USING THE SERVICES. THEY APPLY TO ALL USERS OF THE SITE, REGARDLESS OF WHETHER THEY CREATE AN ACCOUNT ON THE SITE.
      </p>
      <h3>Changing Your Information</h3>
      <p>
        You are responsible for ensuring that information you provide in connection with any account you create on the Services is accurate and up to date. You may change or edit
        your information at our Services by logging into your registered account.
      </p>
      <h3>Provision of Services</h3>
      <p>
        You agree and acknowledge that NearBuys is entitled to modify, improve or discontinue any of its services at its discretion and without notice to you even if it may result
        in you being prevented from accessing any information contained in it. Furthermore, you agree and acknowledge that NearBuys is entitled to provide Services to you through
        subsidiaries, affiliated entities, or third-party service providers.
      </p>
      <h3>Ownership of Services Content</h3>
      <p>
        Unless otherwise noted, all photographs, pictures, graphics, and all other images, including digital, printed and video images, and portions thereof, and all content, data,
        information, news, and all other text and materials, including digital, printed and audio materials, along with the layout and design of the Services and all documentation,
        data, services, features, products and other content at the Services (collectively, <strong>“Services Content”</strong>) are protected by the copyright laws of the United
        States and other jurisdictions. All Services Content is owned by, or licensed to, NearBuys. Services Content may not be reproduced, modified, redistributed or otherwise
        used in any way without a written license with the applicable Services Content owner.
      </p>
      <h3>Use of Services Content</h3>
      <p>
        Subject to and conditioned on your continued compliance with these Terms and all other terms and conditions that NearBuys may provide to you from time to time, NearBuys
        grants you a personal, limited, non-exclusive, non-assignable, non-transferable, non-sublicenseable, revocable license to access and display on your personal computer or
        mobile device for your own use, but not copy or transfer or broadcast or otherwise use in any way, the Services Content found at this Services for your personal,
        non-commercial and educational use only. You further agree not to change or delete any proprietary notices from materials downloaded from the Services.
      </p>
      <h3>Reservation of Rights</h3>
      <p>
        All rights not expressly granted by NearBuys herein are reserved. Nothing on the Services or in these Terms grants, expressly or implicitly, by estoppel or otherwise, any
        right or license to use any Services Content or other materials of any third party, or may be construed to mean that NearBuys has the authority to grant any license on
        behalf of any third party.
      </p>
      <h3>NearBuys Trademarks</h3>
      <p>
        NearBuys trademarks, including the trademarks “NearBuys,” the logo, all page headers, graphics, images, symbols, trade names and other marks found at the Services are the
        proprietary property of NearBuys or its affiliates (collectively the <strong>“Marks”</strong>). Use of the Marks is strictly prohibited without the prior written consent of
        NearBuys. YOU MANY NOT USE THE MARKS IN CONNECTION WITH  ANY AUCTION WEBSITE OR ONLINE MARKETPLACE, INCLUDING EBAY, AMAZON OR ANY OF THEIR AFFILIATES’ WEBSITES.
      </p>
      <h3>Third Party Trademarks</h3>
      <p>
        The trademarks of third parties may also appear on the Services from time to time; you may not use these trademarks without prior written permission of their respective
        owners. You acknowledge and agree that nothing on the Services grants, expressly or implicitly, by estoppel or otherwise, any right or license to use any of the these
        trademarks, nor may anything be construed to mean that NearBuys has authority to grant any right or license on behalf of any third-party trademark owner.
      </p>
      <h3>Restrictions, Limitations and Conditions of Use</h3>
      <p>
        Any unauthorized use of our Services or Services Content for any purpose is prohibited. Your rights to the Services are given on the condition that you use the Services in
        accordance with these Terms. You may not use the Services for any unlawful purpose and you may not:
      </p>
      <ul>
        <li>access, solicit, collect or use any personally identifying information about any other users of the Services or anyone else;</li>
        <li>restrict or inhibit others from using the Services;</li>
        <li>
          transmit to or introduce at the Services any viruses, harmful software, or other modifications or changes to the Services or reverse engineer, decompile, disassemble,
          reverse assemble or modify any Services source or object code or any software or other services or processes accessible through any portion of the Services or Services
          Content (to the extent such restriction is permitted under applicable law);
        </li>
        <li>
          submit, upload, email or otherwise transmit any content or other materials, to or through the Services that: (a) is threatening, abusive, defamatory, obscene, vulgar,
          offensive, or otherwise objectionable, or which may harm minors; (b) infringes or violates the rights of others; or (c) has an advertising, political marketing,
          promotional or other commercial purpose, unless otherwise expressly permitted by the Services;
        </li>
        <li>engage in any activities that interfere with another user’s access to the Services or the proper operation of the Services; or</li>
        <li>
          distribute or copy any Services Content manually or by using any robot, scraper, crawler, or any other automatic device or process; frame or utilize framing techniques to
          enclose any Services Content; or use content from our Services in a manner that suggests an unauthorized association with any of our or our licensor’s products, services
          or brands.
        </li>
      </ul>
      <p>
        NearBuys has no obligation to monitor your interaction with the Services, but reserves the right to review or monitor the Services and any user submissions, if permitted,
        in its sole discretion.
      </p>
      <h3>Creating and Terminating Accounts</h3>
      <p>
        Some of the features on the Services may require you to register for an account through an online registration process. When you register for an account, you will select
        login credentials and you agree that: you will not use a username (or e-mail address) used by someone else, impersonates another person, belongs to another person, violates
        the intellectual property or other right of any person or entity, or that is offensive. You will provide true, accurate, current and complete registration information about
        yourself during any registration process and will update it as needed to keep it accurate. You will immediately notify us of any unauthorized use of your account, password
        or username, or any other breach of security; and you will not sell, transfer, or assign your account or any account rights. You are solely responsible for all activities
        that occur under your account, password and username whether or not you authorize the activity. You are solely responsible for maintaining the confidentiality of your
        password and for restricting access to your device(s) so that others may not access any password-protected portion of our Services using your name, username, or password.
        We will not be liable for any loss or damage (of any kind and under any legal theory) to you or any third party arising from your inability or failure for any reason to
        comply with any of the foregoing obligations in this section. If any information that you provide, or if we have reasonable grounds to suspect that any information that you
        provide, is false, inaccurate, outdated or incomplete, or violates these Terms, or any applicable law, we may suspend or terminate your account. We also reserve the more
        general and broad right to terminate your account, or suspend or otherwise deny you access to it, or its benefits all in our sole discretion, for any reason, and without
        advance notice or liability.
      </p>
      <h3>Links to Other Sites</h3>
      <p>
        In using the Services, you may find that NearBuys provides hyperlinks to one or more third-party Web sites or applications (the <strong>“Linked Sites”</strong>). These
        links are provided as a convenience for you and other users, but NearBuys is not responsible for the content of, or links to and from, the Linked Sites. Your linking to
        these Linked Sites is at your own risk. We encourage you to read the terms and conditions and privacy policies of the respective Linked Sites, should you choose to visit
        them since your use of those Linked Sites will be subject to their terms of use and privacy policies. A link from the Services does not imply a relationship with, or
        endorsement of, the Linked Services(s) or its content, purpose, policies, or business practices by NearBuys.
      </p>
      <h3>Contest, Sweepstakes and Other Promotions</h3>
      <p>
        From time to time, NearBuys may conduct promotions on or through the Services, including without limitation, contests, rewards, sweepstakes and other promotions (
        <strong>“Promotions”</strong>). Each Promotion may have jurisdictional limitations, additional terms and/or rules of participations (“Promotion Rules”), which will be
        posted on the Services or otherwise made available to you. The Promotion Rules for each Promotion in which you participate are deemed incorporated into and form a part of
        these Terms.  It is your responsibility to read the Promotion Rules to determine whether or not your participation, registration or entry will be valid or restricted, and
        to determine your participation requirements.
      </p>
      <h3>Disclaimer, Limitation of Liability, and Indemnification</h3>
      <p>
        <strong>
          The materials, Services Content and services offered on the Services are provided “AS IS” and without warranties or conditions of any kind. To the fullest extent
          permissible pursuant to applicable law, we disclaim all warranties, conditions and other terms, express, implied, statutory or otherwise, including, but not limited to,
          implied warranties of non-infringement, merchantability and fitness for a particular purpose. We do not warrant that the use of the Services will be uninterrupted or
          error-free, that defects will be corrected, or that the Services or the server(s) that make it available or any advertised or hyperlinked site are free of viruses or
          other harmful components or that the Services, server(s), or advertised or Linked Sites will be accessible at all times. We do not warrant or make any representations,
          conditions or other terms regarding the use of the Services or any information contained therein, including Services Content, with respect to correctness, accuracy,
          reliability, graphics, links or otherwise. Certain prices or promotions for vendors using the Services may have expired. You assume the ‎entire cost of all necessary
          servicing, repair or correction to any equipment you use to access the Services. To the extent that applicable law may not allow the exclusion of implied warranties or
          conditions, the above exclusions may not apply to you.
        </strong>
      </p>
      <p>
        <strong>
          In the event that a court finds the above disclaimer of direct damages to be unenforceable, in no event will NearBuys' total aggregate liability for all damages,
          including without limitation all claims, losses, liabilities, costs and expenses (including legal fees and expenses) to you related to the Services or Services Content,
          or these Terms, exceed the lesser of (a) the direct damages suffered by you, and (b) $100.
        </strong>
      </p>
      <p>
        Documents, information, graphics and other materials appearing on the Services may include technical inaccuracies, miscalculations, typographical errors, and out-of-date
        information. Use of such documents, information, graphics or other materials is at your own risk.
      </p>
      <p>
        <strong>
          You waive any and all claims and rights against us and our affiliates, parents, and successors and each of our employees, assignees, officers, agents and directors (the
          “NearBuys Parties”) resulting from injury or damage to, or destruction, theft, or loss of, any property, or person, to the maximum extent permitted by the applicable laws
          of your jurisdiction of residence. None of the NearBuys Parties will be liable to you under any cause of action, for any direct, indirect, special, incidental,
          consequential, reliance or punitive damages, including loss of profits or business interruption. You hereby agree to indemnify the NearBuys Parties from and against any
          and all claims, liabilities, and expenses (including reasonable attorneys’ fees), resulting from your use of the Services or any breach of these Terms by you.
        </strong>
      </p>
      <p>
        <strong>
          If you are a consumer, the provisions in these Terms are intended to be only as broad and inclusive as is permitted by the laws of your state or country of residence.
          Certain jurisdictions have heightened consumer protection laws that may make certain portions of these Terms inapplicable to you. For example, if you are a New Jersey
          consumer, certain provisions do not limit or waive your rights as a consumer under New Jersey law. The provisions in these Terms are intended to be only as broad and
          inclusive as is permitted by the laws of your jurisdiction. No provision of these Terms shall limit or waive your rights as a consumer under the law of your state or
          country of residence. In any event, NearBuys reserves all rights, defenses and permissible limitations under the law of your state or country of residence.
        </strong>
      </p>
      <h3>Typographical Errors</h3>
      <p>
        In the event that any <strong>product</strong> or service is mistakenly listed at an incorrect price on our Services, NearBuys or its applicable vendor reserves the right
        to refuse or cancel any order placed for any product listed at the incorrect price. NearBuys reserves the right to refuse or cancel any such orders whether or not the order
        has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is cancelled, NearBuys will issue a credit to
        your credit card account in the amount of the incorrect price.
      </p>
      <h3>Ability to Accept Terms</h3>
      <p>
        You affirm that you are the age of majority in your jurisdiction and are fully able and competent to enter into the terms, conditions, obligations, affirmations,
        representations, and warranties set forth in these Terms, and to abide by and comply with these Terms. The Services is not intended for children under the age of eighteen
        (18). <strong>If you are under 18 years of age, then please exit now and do not use this Services.</strong>
      </p>
      <h3>Geographic Limitations</h3>
      <p>
        NearBuys controls and operates the Services in the United States. If you use our Services from other locations, you are doing so on your own initiative and are responsible
        for compliance with applicable local laws regarding your online conduct and acceptable content, if and to the extent local laws apply. The Services may describe products
        and services that are available only in certain jurisdictions (or only parts of them). We reserve the right to limit the availability of our Services and/or the provision
        of any Services Content, program, product, service, or other feature described or available on our Services to any person, entity, geographic area, or jurisdiction, at any
        time and in our sole discretion, and to limit the quantities of any Services Content, program, product, service, or other feature that we provide. Further information on
        your rights regarding personal information collected on the Services are set forth in our{' '}
        <strong>Privacy Policy</strong>.
      </p>
      <h3>Choice of Law and Forum</h3>
      <p>
        These Terms will be governed by and construed in accordance with the laws of the Commonwealth of Massachusetts, without giving effect to any principles of conflicts of law.
        Subject to the dispute resolution and arbitration provisions set forth below, you hereby consent and submit to the person jurisdiction of the state courts located
        in Boston, Massachusetts and the federal courts located in Boston, Massachusetts. If any provision of these terms shall be unlawful, void or for any reason unenforceable,
        then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions. Any waiver of any provision
        of these Terms must be in writing signed by an authorized representative of NearBuys to be valid. A waiver of any provision hereunder shall not operate as a waiver of any
        other provision, or a continuing waiver of the same provision in the future.
      </p>
      <p>
        IF YOU ARE A CONSUMER, HOWEVER, THIS PROVISION SHALL NOT BE CONSTRUED TO LIMIT YOUR RIGHTS UNDER THE CONSUMER PROTECTION LAWS OF YOUR STATE OR JURISDICTION OF RESIDENCE.
      </p>
      <h3>Dispute Resolution; Information Resolution and Formal Resolution by Arbitration / Class Action Waiver</h3>
      <p>
        In order to expedite and control the cost of disputes, you and NearBuys agree that any legal or equitable claim relating to the use of this Services or the purchase of any
        product from this Services (referred to as a <strong>“Claim”</strong>) will be resolved as follows:
      </p>
      <h3>1. Informal Resolution:</h3>
      <p>
        You and NearBuys will first attempt to resolve any Claim informally. In the event that any dispute between NearBuys and you arises out of or relates to: (i) these Terms;
        (ii) the Services; or (iii) the purchase of any products from this Services, you and we agree to try to promptly resolve any such dispute informally. Please send a written
        notice describing the dispute to <a href="mailto:admin@nearbuys.com">admin@nearbuys.com</a>.
      </p>
      <h3>1.Formal Resolution by Arbitration / Class Action Waiver.</h3>
      <p>
        <strong>READ THE FOLLOWING ARBITRATION PROVISION CAREFULLY, IT LIMITS YOUR RIGHTS, INCLUDING THE RIGHT TO MAINTAIN A COURT ACTION.</strong> You agree that any dispute,
        controversy or Claim arising out of or relating to these Terms, Your use of the Services, or the purchase of any products from this Services, or the determination of the
        scope or applicability of arbitration shall be governed as set forth below.
      </p>
      <p>
        If you and NearBuys cannot resolve a Claim informally, any Claim asserted by either party will be resolved only by binding arbitration (<strong>“Arbitration”</strong>). By
        agreeing to Arbitration, both you and NearBuys understand and agree that all processes, such as a court action or administrative proceeding, to settle disputes shall be
        decided by a single arbitrator and that you are waiving your rights to maintain other available resolution processes, such as a court action or administrative proceeding,
        to settle disputes. Instead of suing in court, both you and NearBuys each agree to settle disputes (except certain small claims as set forth below) only by Arbitration.
      </p>
      <h3>ARBITRATION MEANS THAT YOU WAVE YOUR RIGHT TO A JURY TRIAL.</h3>
      <p>
        The rules in Arbitration are different. There is no judge or jury, and review is limited, but an arbitrator can award the same damages and relief, and must honor the same
        limitations stated in these Terms as a court would. The Arbitration will be conduction under the American Arbitration Association Consumer Arbitration Rules (referred to as
        the <strong>“AAA Rules”</strong>) and under the rules set forth in these Terms. If there is a conflict between AAA Rules and the rules set forth in these Terms, the rules
        set forth in these Terms will govern. You may, in Arbitration seek any and all remedies otherwise available to you pursuant to the law of the governing jurisdiction. If you
        decide to initiate Arbitration, NearBuys agrees to pay the Arbitration initiation fee and any additional required deposit required by AAA to initiate your Arbitration. You
        and NearBuys agree to pay the costs of the Arbitration proceedings provided however that if you are a consumer you shall not be required to pay more than $250.00 of the
        fees or such amount as the AAA Rules may later prescribe. All other fees, such as attorneys’ fees and expenses of travel to the Arbitration, will be paid in accordance with
        AAA Rules. The arbitration will be held at a location in your home town area if possible, unless you and NearBuys both agree to another location or telephonic Arbitration.
        To initiate Arbitration, you or NearBuys must do the following things:
      </p>
      <ol>
        <li>
          Write a demand for Arbitration. The demand must include a description of the Claim and the amount of damages sought to be recovered. You can file a Demand for Arbitration
          at{' '}
          <a href="https://www.adr.org/" target="_blank">
            https://www.adr.org/
          </a>
          .
        </li>
        <li>Send one copy of the Demand for Arbitration to the other party.</li>
      </ol>
      <p>
        <strong>Special rules in the Arbitration Proceeding.</strong>
      </p>
      <ol>
        <li>Except for errors of law, the arbitrator’s decision is final and binding on all parties and may be enforced in any court that has jurisdiction.</li>
        <li>
          Neither you nor NearBuys shall be entitled to join or consolidate claims in Arbitration by or against other individuals or entities, or arbitrate any claim as a
          representative member of a class or in a private attorney general capacity.
        </li>
      </ol>
      <h3>THIS MEANS THAT YOU WAIVE YOUR RIGHT TO INITIATE OR PARTICIPATE IN ANY CLASS OR CONSOLIDATED ACTION WHATSOEVER.</h3>
      <p>
        Accordingly, you and NearBuys agree that the AAA Class Action Rules do not apply to our Arbitration. A court may sever any portion of this dispute resolution provision if
        it finds such unenforceable, except for the prohibition on class, representative and private attorney general arbitrations. Notwithstanding the obligation to arbitrate all
        Claims under these Terms, you may assert an individual Claim in small claims court in lieu of Arbitration.
      </p>
      <h3>Limited Time to File Claims</h3>
      <p>
        You agree that you will assert any Claim arising out of your use of any NearBuys Services or the purchase of any NearBuys product from this Services within one (1) year
        after the Claim arises, or such claim will be barred.
      </p>
      <h3>Severability and Enforceability</h3>
      <p>
        If any provision or portion of these Terms is held to be illegal, invalid, or unenforceable, in whole or in part, it shall be modified to the minimum extent necessary to
        correct any deficiencies or replaced with a provision which is as close as is legally permissible to the provision found invalid or unenforceable and shall not affect the
        legality, validity or enforceability of any other provisions or portions of these Terms.
      </p>
      <h3>Termination/Exclusion</h3>
      <p>
        We reserve the right, in our sole discretion, to revoke, terminate or suspend any and all privileges associated with accessing the Services for any reason or for no reason
        whatsoever, including improper use of the Services or failure to comply with these Terms, and to take any other action we deem appropriate.
      </p>
      <p>
        Provisions relating to Copyrights, Trademarks, Disclaimers, Limitations of Liability, Indemnification, Waiver, Modifications, User-Generated Content, Assignment of Rights,
        Choice of Law, Jurisdiction, Forum, Dispute Resolution, and Limitations on Time to File Claims, shall survive any termination.
      </p>
      <h3>Disclaimer for User-Generated Content</h3>
      <ol>
        <li>
          The Services may permit the submission of certain user-generated text, information, data, audio, photographs, files or other content (“User Submissions”) and the hosting,
          sharing, transmission and/or publishing of such User Submissions. User Submissions may be used, distributed and published by us and viewed by both general users and
          members of the Services.  You understand that we do not guarantee any confidentiality with respect to any User Submissions.
        </li>
        <li>
          You shall be solely responsible for your own User Submissions and the consequences of submitting, posting or publishing them. In connection with User Submissions, you
          affirm, represent, and warrant that: (i) you own or have the necessary licenses, rights, consents, and permissions to use and authorize us to use all copyrights,
          trademarks, trade secrets, patents or any other proprietary or personal rights in and to your User Submissions to enable inclusion and use of such User Submissions in the
          manner contemplated by the Services and these Terms; (ii) submitting, posting or publishing your User Submission on or through the Services does not and will not violate
          any confidentiality obligations between you and any person or organization or the privacy rights, publicity rights or other rights of any person; and (iii) you have the
          written consent, release, and/or permission of each and every identifiable person or organization in your User Submission to use the name or likeness of each and every
          such identifiable person or organization to enable inclusion and use of the User Submissions in the manner contemplated by the Services and these Terms.  You further
          affirm, represent and warrant that your User Submissions are not defamatory or libelous in any manner whatsoever. 
        </li>
        <li>
          You will retain all ownership rights in your User Submissions, and we will not use, reproduce, publish, distribute or display User Submissions owned by you and stored on
          our Services for our commercial, marketing or any similar purpose, without your consent. By submitting a User Submission to us and permitting it to be viewed, posted or
          published on our Services, you hereby grant to us a perpetual, worldwide, non-exclusive, royalty-free, fully paid up, sublicenseable and transferable license to use, post
          and store User Submissions on our Services and servers and publish, distribute and display such User Submissions in connection with the Services and NearBuys’ (and its
          successor’s) business, including without limitation the right to distribute such User Submissions to other users and third parties.  You further hereby waive any and all
          moral rights and all rights of a similar nature in any jurisdiction in your User Submission.
        </li>
        <li>
          In connection with any User Submissions, you further agree that you will not: (i) submit or publish falsehoods, misrepresentations or statements that could damage us or
          any third-party; (ii) submit material that is false, misleading, inaccurate, derogatory, unlawful, obscene, defamatory, contains nudity, libelous, threatening, sexually
          explicit or pornographic, harassing, hateful, intimidating, racially or ethnically offensive, or encourages conduct that would be considered a criminal offense of any
          federal, state or local law, give rise to civil liability, violate any law, or is otherwise inappropriate or objectionable; (iii) post advertisements or business
          solicitations, including any “junk mail” or “spam;” (iv) impersonate another person, institution or organization or falsely state or otherwise misrepresent yourself, your
          age or your affiliation with any third-party, organization, institution or person; (v) upload, post, store or otherwise make available any virus, bug, Trojan horse or
          other computer file or program that is capable of destroying, interrupting or interfering with or limiting the functionality of the Services or any server, computer
          hardware, software or equipment. We do not endorse any User Submission or any opinion, recommendation, or advice expressed therein.  We reserve the right to remove or
          revise Services Content and User Submissions without prior notice.  We also reserve the right to decide whether Services Content or a User Submission is appropriate and
          complies with these Terms for violations other than copyright infringement and violations of intellectual property law, such as, but not limited to, trademark
          infringement, violations of rights of publicity or privacy, obscene or defamatory material, or excessive length.  We may remove such User Submissions and/or terminate a
          user’s access for accessing or distributing such material in violation of these Terms at any time, without prior notice and at our sole discretion. 
        </li>
        <li>
          You understand that when using the Services, you will be exposed to User Submissions and third-party information from a variety of sources and that we are not responsible
          for the accuracy, usefulness, safety or intellectual property rights of or relating to such User Submissions and third-party information. You further understand and
          acknowledge that you may be exposed to User Submissions that are inaccurate, offensive, indecent, or objectionable, and you agree to waive, and hereby do waive, any legal
          or equitable rights or remedies you have or may have against us with respect thereto.
        </li>
      </ol>
      <h3>Digital Millennium Copyright Act </h3>
      <p>
        1. Notification. If you are a copyright owner or an agent thereof and believe that any User Submission or other Services Content infringes upon your copyright, you may
        submit a written notification pursuant to the Digital Millennium Copyright Act (<strong>“DMCA”</strong>) (see 17 U.S.C. 512(c)(3) for further information) by providing our
        Copyright Agent (listed below) with the following information:
      </p>
      <ol>
        <li>an electronic or physical signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
        <li>
          a description of the copyrighted work claimed to have been infringed or if multiple copyrighted works are covered by a notification, a representative list of such works
          at our Services;
        </li>
        <li>a description of the location on the Services of the allegedly infringing material(s);</li>
        <li>your address, telephone number, and e-mail address;</li>
        <li>
          a written statement that you have a good faith belief that use of the material(s) in the manner complained of is not authorized by the copyright owner, its agent, or the
          law; and
        </li>
        <li>
          a written statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an
          exclusive right that is allegedly infringed.
        </li>
      </ol>
      <p>Our designated Copyright Agent for notice of claims of infringement is:</p>
      <p>
        Designated Agent
        <br />
        M.Hoagland
        <br />
        <a href="mailto:admin@nearbuys.com">admin@nearbuys.com</a> <a href="https://beta-vendor.nearbuys.com/terms">(https://beta-vendor.nearbuys.com/terms)</a>
        <br />
        Phone: <strong>617-684-6308</strong>
        <br />
      </p>
      <p>
        Only notices of alleged copyright infringement should go to the Copyright Agent; any other feedback, comments, requests for technical support, and other communications
        should be directed to <a href="mailto:admin@nearbuys.com">admin@nearbuys.com</a>. You acknowledge that if you fail to comply with all of the notice requirements of the DMCA, your notice may not be valid.
      </p>
      <ol>
        <li>
          Counter-Notification. If you believe that any User Submission of yours that was removed is not infringing, or that you have the appropriate rights from the copyright
          owner or third party, or pursuant to the law, to post and use the material in your User Submission, you may send a counter notification containing the following
          information to the Copyright Agent: (i) your physical or electronic signature; (ii) identification of the material that has been removed or to which access has been
          disabled and the location at which the material appeared before it was removed or disabled; (iii) a statement (under penalty of perjury) that you have a good faith belief
          that the content was removed or disabled as a result of mistake or a misidentification of the material; and your name, address, telephone number, and e-mail address,
          along with a statement that you consent to the federal court of your jurisdiction and a statement that you will accept service of process from the person who provided
          notification of the alleged infringement.
        </li>
      </ol>
      <p>
        If a counter notification is received by the Copyright Agent, we shall send a copy of such counter notification to the original notifying party.  The original notifying
        party shall have ten (10) business days to file an action for copyright infringement and seek a court order against the content provider or user posting such material.  If
        no such infringement action is filed within such 10 business days, we may, in our sole discretion, reinstate the removed material or cease disabling such material.
      </p>
      <ol>
        <li>
          In accordance with the DMCA and other applicable law, we will, in appropriate circumstances, terminate access, at our sole discretion, of any member or user that we find
          to be a repeat infringer of others copyrights. We may also, in our sole discretion, limit or fully terminate access to the Services of any user infringing the
          intellectual property rights of others, regardless of whether such user is repeat offender or not.
        </li>
      </ol>
      <h3>User Suggestions</h3>
      <p>
        You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information provided by you to NearBuys through the Services or any of our
        social media platforms are not confidential and you grant us a worldwide, royalty-free license to distribute, publish, modify, edit or otherwise use your submissions.
        NearBuys shall be entitled to the unrestricted use and dissemination of these submissions for any purpose, commercial or otherwise without any acknowledgement of or
        compensation to you.
      </p>
      <h3>Changes to Terms</h3>
      <p>
        We may revise these Terms at any time, with or without notice, by updating this page or notifying you of the changes, and such revisions will be effective upon posting to
        this page. Please check these Terms periodically for any changes. Your continued use of the Services following the posting of any revisions to these Terms will mean you
        accept those changes. We reserve the right to alter, suspend or discontinue any aspect of the Services, including your access to it. Unless explicitly stated, any new
        features will be subject to these Terms.
      </p>
      <h3>General</h3>
      <p>
        NearBuys’ failure to insist upon or enforce strict performance of any provision of these Terms shall not be construed as a waiver of any provision or right. Neither the
        course of conduct between the parties nor trade practice shall act to modify any of these Terms.
      </p>
      <p>We may assign, novate or subcontract any or all of our rights and obligations under these Terms at any time.</p>
      <p>
        If you have any questions or comments on the Services or become aware of misuse of the Services by any person, please contact us at:<strong>admin@nearbuys.com</strong>.
      </p>
      <h3>Exclusions and Limitations; Consumer Protection Notice</h3>
      <p>
        If you are a consumer, the provisions in these Terms are intended to be only as broad and inclusive as is permitted by the laws of your jurisdiction of residence. If you
        are a New Jersey consumer, the terms of Sections entitled{' '}
        <strong>
          Disclaimer, Limitation of Liability, Indemnification; Unauthorized Dealers and Resellers; Links to Other Sites; Disclaimer for User-Generated Content; Choice of Law and
          Forum; Limited Time to File Claims; Severability and Enforceability; and Geographic Limitations
        </strong>{' '}
        do not limit or waive your rights as a consumer under New Jersey law and the provisions in these Terms as applied to you are intended to be only as broad and inclusive as
        is permitted by the laws of the State of New Jersey. In any event, NearBuys reserves all rights, defenses and permissible limitations under the law of your state of
        residence. Notwithstanding the foregoing, nothing in this Section shall modify the subsection: “Formal Resolution by Arbitration / Class Action Waiver” of the Section
        titled “Dispute Resolution; Information Resolution and Formal Resolution by Arbitration / Class Action Waiver”.
      </p>
      <h3>Entire Agreement</h3>
      <p>
        These Terms constitute the entire and exclusive agreement between you and NearBuys regarding the Services and its use, and supersedes all other agreements, understandings
        and communications, if any, whether oral or written.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions regarding these Terms or your use of our Services, please contact us at by e-mail at <strong>admin@nearbuys.com</strong> or by using the mailing
        information below:
      </p>
      <strong>31 HomeDepot Drive, Plymouth Ma. 02360, Box 235</strong>
    </Col>
  );
};

export default TermsConditions;
