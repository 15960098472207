
const Fb = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.417" height="29.266" viewBox="0 0 29.417 29.266">
      <path id="icons8-facebook" d="M17.709,3a14.7,14.7,0,0,0-2.2,29.236V21.606H11.87V17.74H15.51V15.168c0-4.259,2.075-6.129,5.615-6.129a20.647,20.647,0,0,1,3.016.183V12.6H21.726c-1.5,0-2.027,1.425-2.027,3.031V17.74h4.4l-.6,3.866H19.7v10.66A14.7,14.7,0,0,0,17.709,3Z" transform="translate(-3 -3)" fill="#fff" />
    </svg>
  )
};

export default Fb;

