/**
 * @file   src\App.tsx
 * @brief  This file is responsible for routing.
 * @date   September, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './containers/Login';
import SignUp from './containers/SignUp';
import ForgotPassowrd from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import ForgotEmail from './containers/ForgotEmail';
import GenericNotFound from './containers/GenericNotFound';
import InnerLayout from './components/InnerLayout';
import Profile from './containers/Profile';
import Notification from './containers/NotificationList';
import PurchaseHistory from './containers/PurchaseHistoryList';
import About from './containers/About';
import Terms from './containers/Terms';
import PrivacyPolicy from './containers/PrivacyPolicy';
import ChangePassword from './containers/ChangePassword';
import Contact from './containers/Contact';
import HomeLayout from './components/HomeLayout';
import Home from './containers/Home';
import VendorDetails from './containers/VendorDetails';
import ProductDetail from './containers/ProductDetails';
import Payment from './containers/Payment';
import PaymentCardInfo from './containers/PaymentCardInfo';
import ReturnRequest from './containers/ReturnRequest';
import CommonLayout from './components/CommonLayout';
import DeleteAccount from './containers/DeleteAccount';
import Support from './containers/Support';
import VendorPricing from './containers/VendorPricing';
import { setGusetUser, getUser } from './utils/localStorage';

const App = () => {
  const [idleTimer, setIdleTimer] = React.useState(null);
  // const idleTimerRef = React.useRef(idleTimer);
  const idleTimerRef = React.useRef<any | undefined>(undefined);

  function onIdle() {
    window.location.href = "/signin";
  }

  const resetIdleTimer = () => {
    clearTimeout(idleTimerRef.current);

    const newIdleTimer = setTimeout(onIdle, 600000);
    idleTimerRef.current = newIdleTimer;
  };

  const handleMouseActivity = () => resetIdleTimer();
  const handleKeyboardActivity = () => resetIdleTimer();

  React.useEffect(() => {
    resetIdleTimer();

    document.addEventListener('mousemove', handleMouseActivity);
    document.addEventListener('keydown', handleKeyboardActivity);

    return () => {
      document.removeEventListener('mousemove', handleMouseActivity);
      document.removeEventListener('keydown', handleKeyboardActivity);
      clearTimeout(idleTimerRef.current);
    };
  }, []);
  const UserInfo = JSON.parse(getUser());
  const pathName = window.location.pathname;

  React.useEffect(() => {
    if (!UserInfo && pathName === '/') {
      setGusetUser(JSON.parse('true'));
    }
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="signin" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="forgotpassword" element={<ForgotPassowrd />} />
        <Route path="resetpassword/:id" element={<ResetPassword />} />
        <Route path="forgotemail" element={<ForgotEmail />} />
        <Route element={<CommonLayout />}>
          <Route path='/deleteaccount' element={<DeleteAccount />} />
          <Route path="/Support" element={<Support />} />
        </Route>
        <Route element={<InnerLayout />}>
          <Route path='/about' element={<About />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
          <Route path='/contact' element={<Contact />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/purchasehistory" element={<PurchaseHistory />} />
          <Route path="/vendordetails" element={<VendorDetails />} />
          <Route path="/productdetails/:id" element={<ProductDetail />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/paymentcard" element={<PaymentCardInfo />} />
          <Route path="/return" element={<ReturnRequest />} />
          <Route path='/vendorpricing' element={<VendorPricing />} />
          <Route path="/*" element={<GenericNotFound />} />
        </Route>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Home />} />
        </Route>
      </Routes>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={4000} transition={Slide} hideProgressBar />
    </Router>
  );
};

export default App;
