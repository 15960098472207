/**
 *@file Notification.tsx
 *@brief Notification component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import React from 'react';
import { Notification } from '../interfaces/GeneralInterface';
import './Notification.scss';
import NotificationIcon from '../assets/img/icons/Notification';
import NoImage from '../assets/img/no-image.svg'
interface INotification extends Notification {}

const NotificationMain = React.forwardRef((props: INotification, ref: any) => {
  return (
    <div className={'notification-main ' + (props.unread ? 'unread' : '')} onClick={props.onClick}>
      <div className="notification-img"><img src={props.image ? props.image : NoImage} alt="" /></div>
      <p>{props.text}</p>
    </div>
  );
});

export default NotificationMain;
