
const Filter = (props: any) => {
    return (
        <svg onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 15.526 18.344">
            <path id="Filter" d="M14.526,3.254,9.454,8.89v6.2L6.072,17.344V8.89L1,3.254V1H14.526Z" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
        </svg>
    )
};

export default Filter;

