/**
 * @file   src\components\ActionBtn.tsx
 * @brief  Action Button component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import './ActionBtn.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ActionBtn = (props: any) => {
  return (
    <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.tooltip}</Tooltip>}>
      <span className="action-btn d-flex align-items-center justify-content-center" onClick={props.onClick}>
        {props.children}
      </span>
    </OverlayTrigger>
  );
};

export default ActionBtn;
