/**
 *@file PurchaseHistory.tsx
 *@brief Purchase History component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { PurchaseHistory } from '../interfaces/GeneralInterface';
import Strings from '../assets/strings/Strings.json';
import './Purchase.scss';
import * as CONSTANT from '../utils/constants';
import NoImage from '../assets/img/no-image.svg';
import { constants } from 'buffer';
const PurchaseHistoryMain = React.forwardRef((props: PurchaseHistory, ref: any) => {
  const [statusData, setStatusData] = useState<any>(null);
  const [show, setShow] = useState(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [acceptedClass, setAcceptedClass] = useState<string>('');
  const [inTransitClass, setInTransitClass] = useState<string>('');
  const [deliveredClass, setDeliveredClass] = useState<string>('');
  // handle status of the refund
  useEffect(() => {
    switch (props.status) {
      case 2:
        // setStatusData({ text: 'Refund Approved', color: '#00A706' });
        setStatusData({ text: 'Refund Approved', classname: "refund approved d-block" });

        break;
      case 1:
        setStatusData({ text: 'Refund Requested', classname: "refund pending d-block" });
        // setStatusData({ text: 'Refund Requested', color: '#F78C00' });
        break;
      default:
        setStatusData({ text: '', classname: '' });
        break;
    }
  }, [props]);

  // // handle status of the shipingStatus
  useEffect(() => {
    const defaultClass = 'status-wrap d-flex flex-column align-items-center position-relative text-center';

    let acceptedClassName = defaultClass;
    let inTransitClassName = defaultClass;
    let deliveredClassName = defaultClass;

    if (props.shipStatus === CONSTANT.ACCEPTED && props.trackingNo) {
      acceptedClassName = `${defaultClass} active`;
    }
    if (props.shipStatus === CONSTANT.INTRANSIT) {
      acceptedClassName = `${defaultClass} active`;
      inTransitClassName = `${defaultClass} active`;
    }
    if (props.shipStatus === CONSTANT.DELIVERED) {
      acceptedClassName = `${defaultClass} active`;
      inTransitClassName = `${defaultClass} active`;
      deliveredClassName = `${defaultClass} active`;
    }

    setAcceptedClass(acceptedClassName);
    setInTransitClass(inTransitClassName);
    setDeliveredClass(deliveredClassName);
  }, [props.shipStatus, props.trackingNo]);


  const handleShipped = () => {
    handleShow();
  }

  return (
    <div
      className="purchase-history-main" style={{cursor:"auto" }}
      // style={{ cursor: props.status !== 1 && props.status !== 2 && props.price && props.price !== 0 ? 'pointer' : 'auto' }}
    // onClick={() => {
    //   props.status !== 1 && props.status !== 2 && props.price && props.price !== 0 && props.navigateToDetails(props.purchase_id);
    // }}
    >
      <Row>
        <Col xl={4} lg={12} className="d-flex align-items-center" >
          <div className="purchase-img" style={{cursor:"pointer" }} onClick={() => {
            props.status !== 1 && props.status !== 2 && props.price && props.price !== 0 && props.navigateToDetails(props.purchase_id);
          }}>
            <img src={props.image ? props.image : NoImage} alt="" />
          </div>
          <div className="product-name" style={{cursor:"pointer" }} onClick={() => {
            props.status !== 1 && props.status !== 2 && props.price && props.price !== 0 && props.navigateToDetails(props.purchase_id);
          }}>
            <h5>{props.productname}</h5>
            <p>{props.productowner}</p>
            <span className={statusData?.classname}>{statusData?.text}</span>
            {/* <span className="refund pending d-block">{Strings.Purchase.Status4}</span> */}
          </div>
        </Col>
        <Col xl={8} lg={12} md={12} className="d-flex justify-content-between align-items-center product-details">
          <div className='product-details-inner d-flex w-100 justify-content-between'>
            <div>
              <span>{Strings.Purchase.Price}</span>
              <b>$ {parseFloat(props.price).toFixed(2)}</b>
            </div>
            {/* <div>
            <span>{Strings.Purchase.Discount}</span>
            <div className="discount-applied d-flex">
              {props.discountType === CONSTANT.STATUS_1 || null ? <b>{parseFloat(props?.discount)} %</b> : <b>$ {parseFloat(props?.discount).toFixed(2)}</b>}
              {props.couponCode && <span className="discount-tag">{props.couponCode}</span>}
            </div>
          </div> */}
            <div>
              <span>{Strings.Purchase.DisAmt}</span>
              <b>$ {parseFloat(props.discountAmount ? props.discountAmount : CONSTANT.STATUS_0).toFixed(2)}</b>
            </div>
            <div>
              <span>{Strings.Purchase.TotAmt}</span>
              <b>$ {parseFloat(props.discountedPrice ? props.discountedPrice : props.price).toFixed(2)}</b>
            </div>
            <div>
              <span>{Strings.Purchase.Quantity}</span>
              <b>{props.quantity}</b>
            </div>
            <div>
              <span>{Strings.Purchase.Date}</span>
              <b>{props.date}</b>
            </div>
            <div className='shipping-status'>
              <span>{Strings.Purchase.Status}</span>
              {
                (props?.shipStatus === CONSTANT.ACCEPTED && props?.trackingNo != null) ?
                  <div className='btn accepted' onClick={handleShipped}>{props?.shipStatus}</div> :
                  (props?.shipStatus === CONSTANT.INTRANSIT) ?
                    <div className='btn transit' onClick={handleShipped}>{props?.shipStatus}</div> :
                    (props?.shipStatus === CONSTANT.DELIVERED) ?
                      <div className='btn delivered' onClick={handleShipped}>{props?.shipStatus}</div> :
                      (props?.shipStatus === CONSTANT.PRESHIPPING) ?
                        <div className='btn shipped'>{props?.shipStatus}</div> :
                        <div><b>{props?.shipStatus != null ? props?.shipStatus : "NA"}</b></div>

              }
            </div>
          </div>
        </Col>
      </Row>
      {/* Tracking Modal */}
      <Modal show={showModal} size="lg" onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton className='pb-0'>
          <Modal.Title>Shipment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body className='shipment-dtls'>
          <div className='shipment-info'>
            <span>{props?.carrierCode}</span>
            <p>Tracking ID: {props?.trackingNo}</p>
          </div>
          <div className='shipment-status d-flex w-100'>
            <div className={acceptedClass}>
              <div className='step'></div>
              <div className='status'>Accepted</div>
              {/* <div className='date'>Aug 26, 2022</div> */}
            </div>
            <div className={inTransitClass}>
              <div className='step'></div>
              <div className='status'>In Transit</div>
              {/* <div className='date'>Aug 28, 2022</div> */}
            </div>
            <div className={deliveredClass}>
              <div className='step'></div>
              <span className='status'>Delivered</span>
              {/* <div className='date'>Date</div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
});

export default PurchaseHistoryMain;
