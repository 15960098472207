/**
 * @file   src\containers\Login.tsx
 * @brief  Container for Login page.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import AppleLogin from 'react-apple-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { resetAuthState, resetAppleLogin, resetSendOTPState, resetVerifyOtpState, resetfbLoginState } from '../store/slices/authSlice';
import { login, applelogin, fbLogin, sendcode, verifyCode } from '../store/actions/authAction';
import '../assets/styles/prelogin.scss';
import Strings from '../assets/strings/Strings.json';
import Logo from '../assets/img/nearbuys-prelogin.svg';
import NBInputGroup from '../components/NBInputGroup';
import NBOtpInput from '../components/NBOtpInput';
import Fb from '../assets/img/icons/FB';
import Apple from '../assets/img/icons/Apple';
import NBInputGroupPswd from '../components/NBInputGroupPswd';
import { validateLogin, isOTPValid } from '../utils/authHelper';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  setUser,
  setGusetUserTerms,
  getGusetUserTerms,
  setSocialUserTerms,
  getSocialUserTerms,
  setGusetUser,
  removeGuestUser,
  removeUser,
  removeLatLong,
  removeNationalWide,
  removeCategory,
  removeVInfo,
  removeFilter,
  getSessionOut,
  removeSession,
  removeDeliveryOption,
  removeQty

} from '../utils/localStorage';
import TermsConditions from '../components/Terms';
import PrivacyPolicy from '../components/Privacy';
import * as CONSTANT from '../utils/constants';
import * as alerts from '../utils/alerts'
import { loginTypes } from '../utils/enums';
import { Ilogin, IemailOtp, IemailOtpErr } from '../interfaces/GeneralInterface';
import Loader from '../components/Loader';

const Login = () => {
  const currentUrl = window.location.href;
  let appleToken = currentUrl.split('?token=')[1];

  const [show, setShow] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sessionOut = getSessionOut();
  const loginResponseData = useAppSelector((RootReducer) => RootReducer.auth.Login);
  const appleResponse = useAppSelector((RootReducer) => RootReducer.auth.appleLogin);
  const fbloginResponseData = useAppSelector((RootReducer) => RootReducer.auth.fbLogin);
  const requestOTPResponseData = useAppSelector((RootReducer) => RootReducer.auth.sendOTP);
  const verifyCodeResponseData = useAppSelector((RootReducer) => RootReducer.auth.verifyOTP);

  const [loginData, setLoginData] = useState<Ilogin>({
    password: '',
    email: '',
  });
  const [loginError, setLoginError] = useState<Ilogin>({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [termsType, setTermsType] = useState<string>('');
  const [appleLoginFunction, setAppleLoginFunction] = useState<any>(null);
  const [facebookLoginFunction, setFacebookLoginFunction] = useState<any>(null);
  const [showTermsAndConditionModal, setShowTermsAndConditionModal] = useState<boolean>(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false);
  const [showOtpModal, setShowOtpModal] = useState<boolean>(false);
  const [showSessionModal, setShowSessionModal] = useState<boolean>(false);
  const [verificationOtpEmail, setVerificationOtpEmail] = useState<string>('');
  const [codeInfo, setCodeInfo] = useState<IemailOtp>({
    email_otp: '',
  });
  const [codeInfoError, setCodeInfoError] = useState<IemailOtpErr>({
    email_otp_error: '',
  });

  const vendorSiteLink :any = process.env.REACT_APP_VENDOR_SITE_URL;

  const redirectToExternalLink = () => {
    window.open(vendorSiteLink, '_blank');
  };

  // handle the redirected url from apple
  useEffect(() => {
    if (appleToken) {
      setLoader(true);
      dispatch(applelogin({ access_token: appleToken, is_user_web: 1 }));
      appleToken = '';
    }
  }, [appleToken]);

  // Remove all the cache stored value
  useEffect(() => {
    // removeGuestUser();
    setGusetUser(JSON.parse('true'));
    removeUser();
    removeLatLong();
    removeNationalWide();
    removeVInfo();
    removeCategory();
    removeFilter();
    removeQty();
    removeDeliveryOption();
    if (sessionOut !== ('' || null)) {
      setShowSessionModal(true);
    }
  }, []);

  // handles apple api response
  useEffect(() => {
    if (appleResponse.isLoading === false && appleResponse.isSuccess === true) {
      if (appleResponse.errorCode === CONSTANT.API_SUCCESSCODE && appleResponse.userInfo.user_info) {
        const userInfo = {
          a_token: appleResponse.userInfo.user_info.a_token,
          r_token: appleResponse.userInfo.user_info.r_token,
          f_name: appleResponse.userInfo.user_info.f_name,
          l_name: appleResponse.userInfo.user_info.l_name,
          e_time: appleResponse.userInfo.user_info.e_time,
          email: appleResponse.userInfo.user_info?.e_address,
          mob_number: appleResponse.userInfo.user_info?.mob_number,
          user_id: appleResponse.userInfo.user_info?.u_id,
          u_logintype: appleResponse.userInfo.user_info?.u_logintype,
        };
        setUser(JSON.stringify(userInfo));
        toast.success(appleResponse?.errorMessage);
        removeGuestUser();
        setTimeout(() => {
          navigate('/');
        }, 100);
      } else if (appleResponse.errorCode === CONSTANT.API_ERRCODE && appleResponse?.errorMessage) {
        toast.error(appleResponse?.errorMessage);
      }
      dispatch(resetAppleLogin());
      setLoader(false);
    }
  }, [appleResponse]);

  // handles normal login api response
  useEffect(() => {
    if (loginResponseData.isLoading === false && loginResponseData.isSuccess === true) {
      if (loginResponseData.errorCode === CONSTANT.API_SUCCESSCODE && loginResponseData.userInfo.user_info) {
        const userInfo = {
          a_token: loginResponseData.userInfo.user_info.a_token,
          r_token: loginResponseData.userInfo.user_info.r_token,
          f_name: loginResponseData.userInfo.user_info.f_name,
          l_name: loginResponseData.userInfo.user_info.l_name,
          e_time: loginResponseData.userInfo.user_info.e_time,
          email: loginResponseData.userInfo.user_info?.e_address,
          mob_number: loginResponseData.userInfo.user_info?.mob_number,
          user_id: loginResponseData.userInfo.user_info?.u_id,
          u_logintype: loginResponseData.userInfo.user_info?.u_logintype,
        };
        setUser(JSON.stringify(userInfo));
        toast.success(loginResponseData?.errorMessage);
        removeGuestUser();
        setTimeout(() => {
          navigate('/');
        }, 100);
      } else if (loginResponseData.errorCode === CONSTANT.API_ERRCODE && loginResponseData.userInfo.user_info) {
        let payload = { email: loginResponseData.userInfo.user_info?.email };
        setVerificationOtpEmail(loginResponseData.userInfo.user_info?.email);
        dispatch(sendcode(payload));
      } else if (loginResponseData.errorCode === CONSTANT.API_ERRCODE && loginResponseData?.errorMessage) {
        toast.error(loginResponseData?.errorMessage);
      }
      dispatch(resetAuthState());
    }
  }, [loginResponseData]);

  // handles fb login api response
  useEffect(() => {
    if (fbloginResponseData.isLoading === false && fbloginResponseData.isSuccess === true) {
      if (fbloginResponseData.errorCode === CONSTANT.API_SUCCESSCODE && fbloginResponseData.userInfo.user_info) {
        const userInfo = {
          a_token: fbloginResponseData.userInfo.user_info.a_token,
          r_token: fbloginResponseData.userInfo.user_info.r_token,
          f_name: fbloginResponseData.userInfo.user_info.f_name,
          l_name: fbloginResponseData.userInfo.user_info.l_name,
          e_time: fbloginResponseData.userInfo.user_info.e_time,
          email: fbloginResponseData.userInfo.user_info?.e_address,
          mob_number: fbloginResponseData.userInfo.user_info?.mob_number,
          user_id: fbloginResponseData.userInfo.user_info?.u_id,
          u_logintype: fbloginResponseData.userInfo.user_info?.u_logintype,
        };
        setUser(JSON.stringify(userInfo));
        removeGuestUser();
        setTimeout(() => {
          navigate('/');
        }, 100);
        toast.success(fbloginResponseData?.errorMessage)
      } else if (fbloginResponseData.errorCode === CONSTANT.API_ERRCODE && fbloginResponseData?.errorMessage) {
        toast.error(fbloginResponseData?.errorMessage);
      }
      setLoader(false);
      dispatch(resetfbLoginState());
    }
  }, [fbloginResponseData]);

  // handles verify code api response
  useEffect(() => {
    if (verifyCodeResponseData.isLoading === false && verifyCodeResponseData.isSuccess === true) {
      if (verifyCodeResponseData.errorCode === CONSTANT.API_SUCCESSCODE) {
        const userInfo = {
          a_token: verifyCodeResponseData.userInfo.user_info.a_token,
          r_token: verifyCodeResponseData.userInfo.user_info.r_token,
          f_name: verifyCodeResponseData.userInfo.user_info.f_name,
          l_name: verifyCodeResponseData.userInfo.user_info.l_name,
          e_time: verifyCodeResponseData.userInfo.user_info.e_time,
          user_id: verifyCodeResponseData.userInfo.user_info.u_id,
          email: verifyCodeResponseData.userInfo.user_info.e_address,
          u_logintype: verifyCodeResponseData.userInfo.user_info?.u_logintype,

        };
        setUser(JSON.stringify(userInfo));
        toast.success(verifyCodeResponseData.errorMessage);
        resetOTPForm();
        removeGuestUser();
        setTimeout(() => {
          navigate('/');
        }, 100);
      } else {
        toast.error(verifyCodeResponseData.errorMessage);
      }
      dispatch(resetVerifyOtpState());
    }
  }, [verifyCodeResponseData]);

  // handles otp request api response
  useEffect(() => {
    if (requestOTPResponseData.isLoading === false && requestOTPResponseData.isSuccess === true) {
      if (requestOTPResponseData.errorCode === CONSTANT.API_SUCCESSCODE) {
        if (showOtpModal === true) {
          toast.success(requestOTPResponseData.errorMessage);
        }
        setShowOtpModal(true);
      } else {
        toast.error(requestOTPResponseData.errorMessage);
      }
      dispatch(resetSendOTPState());
    }
  }, [requestOTPResponseData]);

  // handle terms and condition, privacy policy modal close
  const handleTermsAndConditionClose = () => setShowTermsAndConditionModal(false);
  const handlePrivacyClose = () => setShowPrivacyModal(false);

  // handles login validation
  const logs = () => {
    const validate = validateLogin(loginData);
    if (validate.email === '' && validate.password === '') {
      const payload = {
        email: loginData?.email,
        password: loginData?.password,
        u_type: 3,
        is_user_web: 1,
      };
      dispatch(login(payload));
      setLoginError(validate);
    } else {
      setLoginError(validate);
    }
  };

  // handles email changes
  const handleEmailChanges = (event: { target: { name: any; value: string } }) => {
    setLoginData(() => ({
      ...loginData,
      [event.target.name]: event.target.value,
    }));
  };

  // handles password changes
  const handlePasswordChanges = (event: { target: { name: any; value: string } }) => {
    setLoginData(() => ({
      ...loginData,
      [event.target.name]: event.target.value,
    }));
  };

  // handle password showing
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // handles show terms modal
  const handleShowTermsModal = (type: string, appleClick: any) => {
    const guestTerms = getGusetUserTerms();
    const socialTerms = getSocialUserTerms();
    setTermsType(type);
    if (type === loginTypes.GUESTLOGIN && guestTerms === true) {
      setGusetUser(JSON.parse('true'));
      navigate('/');
    } else if (type === loginTypes.APPLELOGIN && socialTerms === true) {
      appleClick.onClick();
    } else if (type === loginTypes.FACEBOOKLOGIN && socialTerms === true) {
      appleClick.onClick();
    } else {
      setShow(!show);
    }
  };

  // handles agree terms modal
  const handleAgreeTermsModal = () => {
    if (termsType === loginTypes.GUESTLOGIN) {
      setGusetUser(JSON.parse('true'));
      setGusetUserTerms(JSON.parse('true'));
      navigate('/');
    } else if (termsType === loginTypes.APPLELOGIN) {
      setSocialUserTerms(JSON.parse('true'));
      appleLoginFunction.onClick();
    } else if (termsType === loginTypes.FACEBOOKLOGIN) {
      setSocialUserTerms(JSON.parse('true'));
      facebookLoginFunction.onClick();
      setShow(!show);
    }
  };

  // handles facebook api response
  const responseFacebook = (response: any) => {
    if (response && response.accessToken) {
      setLoader(true);
      const payload = {
        access_token: response.accessToken,
        is_user_web: 1,
      };
      dispatch(fbLogin(payload));
    }
  };

  // handles close terms modal
  const handleCloseTermsModal = () => {
    setShow(!show);
  };

  // handles reset otp form
  const resetOTPForm = () => {
    setCodeInfo({
      email_otp: '', // Reset email_otp to an empty string or initial value and close the modal
    });
    setVerificationOtpEmail('');
    setShowOtpModal(false);
  };

  // handles Otp form submission
  const submitOTPForm = () => {
    const isValidInput = validatOTP(codeInfo.email_otp);
    if (isValidInput) {
      const payload = {
        email: verificationOtpEmail,
        otp: codeInfo?.email_otp,
        is_user_web: 1,
      };
      dispatch(verifyCode(payload));
      setCodeInfo({
        email_otp: '', // Reset email_otp to an empty string or initial value and close the modal
      });
    }
  };

  // validates the email otp
  const validatOTP = (otp: any) => {
    const isValid = true;
    const isValidOTP = isOTPValid(otp);
    if (isValidOTP === false) {
      setCodeInfoError(() => ({ ...setCodeInfoError, ['email_otp_error']: alerts.INVALID_CODE }));
    } else {
      setCodeInfoError(() => ({ ...setCodeInfoError, ['email_otp_error']: '' }));
    }
    return isValid;
  };

  // handles resend code
  const sendResendCode = (e_address: string) => {
    const payload = { email: e_address };
    dispatch(sendcode(payload));
    setCodeInfo({
      email_otp: '', // Reset email_otp to an empty string or initial value and close the modal
    });
  };

  // handles email otp change
  const handleEmailOTPChange = (otp: any) => {
    const numberValidation = /^[0-9]*$/;
    if (numberValidation.test(otp)) {
      setCodeInfo(() => ({ ...codeInfo, ['email_otp']: otp }));
    }
  };

  // Mask Email address
  const maskEmail = (email: string) => {
    if (email !== '') {
      const split = email.split('@');
      return email.substring(0, 1) + '*******' + split[0].slice(-1) + '@' + split[1];
    }
  };

  // handle session out message modal close
  const showSessionModalClose = () => {
    setShowSessionModal(false);
    removeSession();
  };

  return (
    <Container fluid className="pre-login">
      <Row>
        <Col lg="6">
          <div className="nb-caption d-flex align-items-center justify-content-center">
            <p className="text-center">NearBuys. Where Offers Find You!</p>
          </div>
        </Col>
        <Col lg="6" className="d-flex align-items-center justify-content-center">
          <div className="nb-login">
            <img src={Logo} alt="" width={116} />
            <h1>{Strings.Login.Title}</h1>
            <NBInputGroup id="email" name="email" type="email" label={Strings.Login.Label1} onChange={handleEmailChanges} value={loginData.email} error={loginError.email} />
            <div className="text-end d-block forgot-pass pb-0">
              <Link to="/forgotemail">{Strings.Login.ForgotEmail}</Link>
            </div>
            <NBInputGroupPswd id="password" name="password" label={Strings.Login.Label2} onChange={handlePasswordChanges} value={loginData.password} error={loginError.password} showpass={showPassword} handleShowPassword={handleShowPassword} maxLength={15} />
            <div className="text-end d-block forgot-pass">
              <Link to="/forgotpassword">{Strings.Login.Forgot}</Link>
            </div>
            <Button variant="primary" className="w-100" onClick={logs}>
              {Strings.Login.Btn}
            </Button>
            <div className="or d-flex justify-content-center">
              <div className="or-cont d-flex justify-content-center align-items-center">{Strings.Login.Or}</div>
            </div>
            <Row className="alt-login">
              <Col lg="12" xl="6">
                <FacebookLogin
                  appId={process.env.REACT_FB_APP_ID}
                  autoLoad={false}
                  fields="id, email, first_name, last_name"
                  scope="public_profile,email"
                  auth_type='reauthorize'
                  callback={responseFacebook}
                  render={(renderProps: any) => (
                    <Button onClick={() => {
                      handleShowTermsModal('facebook', renderProps);
                      setFacebookLoginFunction(renderProps);
                    }} className="w-100 fb">
                      <Fb />{Strings.Login.FB}
                    </Button>
                  )}
                />
                {/* <Button className="w-100 fb"><Fb />{Strings.Login.FB}</Button> */}
              </Col>
              <Col lg="12" xl="6">
                <AppleLogin
                  clientId={process.env.REACT_APPLE_APP_ID ? process.env.REACT_APPLE_APP_ID : ''}
                  redirectURI={`${process.env.REACT_APP_API_URL}V1/callbacks/sign_in_with_apple_website`}
                  scope="email name"
                  responseType="code id_token"
                  responseMode="form_post"
                  render={(
                    renderProps,   //Custom Apple Sign in Button
                  ) => (
                    <Button onClick={() => { handleShowTermsModal('apple', renderProps), setAppleLoginFunction(renderProps) }} className="w-100 apple">
                      <Apple />
                      {Strings.Login.Apple}
                    </Button>
                  )}
                />
              </Col>
            </Row>
            <div className="register text-center">
            <p>If you are Vendor<Link onClick={redirectToExternalLink} to=''> click here</Link> to Sign In</p>
              <p>{Strings.Login.RegisterTxt} <Link to="/signup">{Strings.Login.RegisterLink}</Link></p>
            </div>
            <div onClick={() => { handleShowTermsModal("guest", '') }} className="guest text-center">
              <Link>{Strings.Login.GuestLink}</Link>
            </div>
          </div>
        </Col>
      </Row>
      {/* Terms Modal */}
      <Modal show={show} centered backdrop="static">
        <Modal.Body className='otp-verify' >
          <h1 className='text-center'>{Strings.Login.TermsModal.Title}</h1>
          <p className='text-center pb-1'>By clicking the <span>"Agree"</span> button, you agree to our<br /><span><Link onClick={() => setShowTermsAndConditionModal(true)} to=""> {Strings.SignUp.AgreeLink}&nbsp;</Link>
            <Link onClick={() => setShowPrivacyModal(true)} to="">
              {Strings.SignUp.AgreeLinkPrivacy}
            </Link></span></p>
          <div className='btn-wrap d-flex'>
            <Button onClick={handleCloseTermsModal} variant="secondary">{Strings.Login.TermsModal.Btn}</Button>
            <Button onClick={handleAgreeTermsModal} variant="primary">{Strings.Login.TermsModal.Btn2}</Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showTermsAndConditionModal} onHide={handleTermsAndConditionClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Terms.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={400} >
            <TermsConditions />
          </Scrollbars>
        </Modal.Body>
      </Modal>
      <Modal show={showPrivacyModal} onHide={handlePrivacyClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Privacy.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={400} >
            <PrivacyPolicy />
          </Scrollbars>
        </Modal.Body>
      </Modal>
      {/* OTP Modal */}
      <Modal show={showOtpModal} centered backdrop="static">
        <Modal.Body className="otp-verify">
          <h1 className="text-center">{Strings.SignUp.OtpModal.Title}</h1>
          <p className="text-center">
            {Strings.SignUp.OtpModal.InfoTxt}
            <br />
            <span>{maskEmail(verificationOtpEmail)}</span>
          </p>
          <div className="otp-wrap d-flex justify-content-center">
            <NBOtpInput
              name="otp"
              id="otp"
              type="number"
              label={Strings.SignUp.OtpModal.Label1}
              linkName={Strings.SignUp.OtpModal.LinkTxt}
              onChange={handleEmailOTPChange}
              value={codeInfo.email_otp}
              resendOtp={() => sendResendCode(verificationOtpEmail)}
              error={codeInfoError.email_otp_error}
            />
          </div>
          <div className="btn-wrap d-flex">
            <Button variant="secondary" onClick={resetOTPForm}>
              {Strings.SignUp.OtpModal.Btn}
            </Button>
            <Button variant="primary" onClick={submitOTPForm}>
              {Strings.SignUp.OtpModal.Btn2}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* <Modal show={loader} onHide={() => setLoader(false)} centered backdrop="static" className="loader-pop">
        <div className=" align-items-center justify-content-center d-flex">
          <div className="spinner-grow" style={{ width: '3rem', height: '3rem', color: '#F9C901', marginRight: 10 }} role="status" />
          <div className="spinner-grow" style={{ width: '3rem', height: '3rem', color: '#F9C901', marginRight: 10 }} role="status" />
          <div className="spinner-grow" style={{ width: '3rem', height: '3rem', color: '#F9C901', marginRight: 10 }} role="status" />
          <div className="spinner-grow" style={{ width: '3rem', height: '3rem', color: '#F9C901', marginRight: 10 }} role="status" />
          <div className="spinner-grow" style={{ width: '3rem', height: '3rem', color: '#F9C901', marginRight: 10 }} role="status" />
        </div>
      </Modal> */}
      {loader && <Loader/>}
      <Modal show={showSessionModal} onHide={showSessionModalClose} centered backdrop="static">
        <Modal.Body className="otp-verify">
          <h5 className="text-center d-block pt-3">
            {alerts.DEVICE_SESSION_LOGOUT}
            <br />
          </h5>
          <div className="align-items-center justify-content-center d-flex pt-4">
            <Button onClick={showSessionModalClose} variant="primary">
              {Strings.Header.DropDownMenu.signOut.Btn1}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Login;
