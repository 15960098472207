import axios,{ AxiosRequestConfig } from 'axios';
import {
  STATUS_401,
  API_TIMEOUT,
  API_MAXCONTENTLENGTH,
  API_REDIRECTS,
  API_AUTH_TOKEN_ERROR,
  API_BLOCKED_USER,
  API_LOGOUT_USER,
  API_ERRCODE,
  API_SUCCESSCODE,
  STATUS_500,
  STATUS_1
} from '../utils/constants';
import { getRefreshToken, setUser, getAuthToken, getUser } from '../utils/localStorage';
import { AuthApi } from '../utils/apiUrls';
import { setSessionOut } from '../utils/localStorage';

// Create axios instance.
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

// Set axios headers.
const headers: any = {};
headers['Access-Control-Allow-Origin'] = '*';
headers['Access-Control-Allow-Headers'] = '*';
headers['Access-Control-Allow-Credentials'] = true;
headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, PATCH, DELETE';
headers['Content-Type'] = 'application/json';
headers.Accept = 'application/json';
headers['Cache-Control'] = 'no-cache';
headers['api_key'] = process.env.REACT_APP_API_KEY;

// Assign default values.
axiosClient.defaults.baseURL = process.env.REACT_APP_API_URL;
axiosClient.defaults.headers = headers;
axiosClient.defaults.timeout = API_TIMEOUT;
axiosClient.defaults.maxContentLength = API_MAXCONTENTLENGTH;
axiosClient.defaults.maxRedirects = API_REDIRECTS;
axiosClient.defaults.withCredentials = true;


const refreshAccessToken = async (rtoken: any) => {
  const requestURL = process.env.REACT_APP_API_URL + AuthApi.REFRESHTOKEN;
  const requestMethod = 'POST';
  const headers = {
    'x-refresh-token': rtoken,
    'is_user_web': STATUS_1,
    'Authorization': 'Bearer ' + getAuthToken(),
    'Api_key':process.env.REACT_APP_API_KEY
  };

  const requestOptions = {
    method: requestMethod,
    url: requestURL,
    headers,
  };

  try {
    const response = await axios(requestOptions);
    const responseData = response.data;
    if (responseData?.error_code === API_SUCCESSCODE) {
      return responseData.data;
    } else if (responseData.error_code === API_AUTH_TOKEN_ERROR) {
      window.location.href = '/signin';
    } else {
      throw new Error('Refresh token failed');
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// Handle interceptor request.

axiosClient.interceptors.request.use(
  async (config) => { 
    if (config.headers.baseURL && config.headers.baseURL === process.env.REACT_APP_API_PRODUCT_URL) {
      config.baseURL = process.env.REACT_APP_API_PRODUCT_URL;
    }
    else if (config.headers.baseURL && config.headers.baseURL === process.env.REACT_APP_API_URL_VENDOR) {
      config.baseURL = process.env.REACT_APP_API_URL_VENDOR;
    }
    else if (config.headers.baseURL && config.headers.baseURL === process.env.REACT_APP_API_MONGODB_URL) {
      config.baseURL = process.env.REACT_APP_API_MONGODB_URL;
    }
    const token = getAuthToken();
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Handle interceptor response.
axiosClient.interceptors.response.use(
  async (response: any) => {
    const user = JSON.parse(getUser()) 
    if (response?.data.error_code === API_LOGOUT_USER) {
      window.location.href = '/signin';
    } else if (response?.data.error_code === API_BLOCKED_USER) {
      setSessionOut(JSON.stringify(response?.data.message));
      window.location.href = '/signin';
    } else if (response?.data.error_code === STATUS_500) {
      window.location.href = '/signin';

    } else if (response?.data.error_code === API_AUTH_TOKEN_ERROR) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const data = await refreshAccessToken(getRefreshToken());
          const newAccessToken = data.a_token;
          const userInfo = {
            a_token: newAccessToken,
            r_token: data.r_token,
            u_logintype : user.u_logintype
          };
          setUser(JSON.stringify(userInfo));
          response.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosClient
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });
          refreshAndRetryQueue.length = 0;
          return axiosClient(response.config);
        } catch (refreshError) {
          console.error('Failed to refresh access token', refreshError);
          return Promise.reject('Failed to refresh access token');
        } finally {
          isRefreshing = false;
        }
      }
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: response.config, resolve, reject });
      });
    } 
      return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);


// Export default methods.
export default {
  get: axiosClient.get,
  post: axiosClient.post,
  put: axiosClient.put,
  patch: axiosClient.patch,
  delete: axiosClient.delete,
};
