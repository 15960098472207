
const CallUs = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g id="_9026689_phone_call_thin_icon" data-name="9026689_phone_call_thin_icon" transform="translate(0.329 0.329)">
        <rect id="Rectangle_95" data-name="Rectangle 95" width="36" height="36" transform="translate(-0.329 -0.329)" fill="none" />
        <path id="Path_42" data-name="Path 42" d="M163.888,45.049a.561.561,0,0,1-.541-.415,10.691,10.691,0,0,0-7.533-7.533.56.56,0,1,1,.29-1.082,11.815,11.815,0,0,1,8.325,8.325.561.561,0,0,1-.542.706Z" transform="translate(-133.652 -30.979)" fill="#94da0a" />
        <path id="Path_43" data-name="Path 43" d="M152.422,72.783a.561.561,0,0,1-.541-.417A6.163,6.163,0,0,0,147.516,68a.56.56,0,0,1,.288-1.083,7.286,7.286,0,0,1,5.161,5.161.561.561,0,0,1-.542.7Z" transform="translate(-126.515 -57.55)" fill="#94da0a" />
        <path id="Path_44" data-name="Path 44" d="M55.612,55.185h0A19.634,19.634,0,0,1,36,35.573a7.3,7.3,0,0,1,6.357-7.224,1.666,1.666,0,0,1,1.753,1.013l2.813,6.565A1.677,1.677,0,0,1,46.8,37.5l-2.343,3.564a.562.562,0,0,0-.033.543,11.193,11.193,0,0,0,5.2,5.186.57.57,0,0,0,.55-.044l3.5-2.341a1.685,1.685,0,0,1,1.6-.145l6.553,2.814a1.669,1.669,0,0,1,1.007,1.749A7.3,7.3,0,0,1,55.612,55.185ZM42.572,29.456a6.187,6.187,0,0,0-5.451,6.117A18.512,18.512,0,0,0,55.612,54.064a6.177,6.177,0,0,0,6.112-5.38.548.548,0,0,0-.332-.574l-6.562-2.818a.546.546,0,0,0-.529.047L50.8,47.68a1.673,1.673,0,0,1-.938.286h0a1.691,1.691,0,0,1-.719-.162,12.322,12.322,0,0,1-5.728-5.712,1.693,1.693,0,0,1,.1-1.641l2.342-3.562a.557.557,0,0,0,.038-.52L43.078,29.8A.547.547,0,0,0,42.572,29.456Z" transform="translate(-30.98 -24.389)" fill="#94da0a" />
      </g>
    </svg>
  )
};

export default CallUs;

