/**
 * @file   src\containers\About.tsx
 * @brief  About page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Container } from 'react-bootstrap';
import Strings from '../assets/strings/Strings.json';
import AppsAd from '../components/AppsAd';

const About = () => {
  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container className='d-flex align-items-middle justify-content-between'>
          <h2>{Strings.About.Title}</h2>
        </Container>
      </Container>
      <Container fluid>
        <Container className='content-area'>
          <p>{Strings.About.About}</p>
          <AppsAd />
        </Container>
      </Container>
    </div>
  );
};

export default About;
