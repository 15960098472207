import React from 'react';
// import './Register.scss';
import { Pagination } from 'react-bootstrap';

interface PaginateProps {
  totalRecords: number;
  currentPage: number;
  perPage: number;
  onClick: (page: number) => void;
}

const Paginate: React.FC<PaginateProps> = (props) => {
  const totalRecords = props.totalRecords;
  const currentPage = props.currentPage;
  const perPage = props.perPage;

  const prevPage = currentPage >= 1 ? currentPage - 1 : 1;
  const nextPage = currentPage >= 1 ? currentPage + 1 : 1;

  const firstPage = 1;
  const leftAndRight = 4;

  let startPage = 1;
  let startMinus = 0;

  let totalPages = totalRecords % perPage === 0 ? totalRecords / perPage : Math.ceil(totalRecords / perPage);
  let endPage = totalPages;

  if (totalPages <= 1) {
    startPage = firstPage;
    endPage = totalPages;
  } else {
    const start = currentPage - leftAndRight;
    if (start < firstPage) {
      startMinus = -1 * start;
    }

    startPage = start < firstPage ? firstPage : start;

    let end = currentPage + leftAndRight;
    // Adding left side to right side if < 0
    end = end + startMinus;
    endPage = end > totalPages ? totalPages : end;

    let endMinus = endPage - leftAndRight - leftAndRight;
    if (endMinus < startPage && endMinus > 0)
      startPage = endMinus;
  }

  let rightEllipsis = currentPage + 10 > totalPages ? totalPages : currentPage + 10;
  let leftEllipsis = currentPage - 10 < firstPage ? firstPage : currentPage - 10;

  const pageItem = () => {
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        i === currentPage ?
          <Pagination.Item key={"Item" + i} active onClick={() => props.onClick(i)} >{i}</Pagination.Item>
          : <Pagination.Item key={"Item" + i} onClick={() => props.onClick(i)} >{i}</Pagination.Item>
      );
    }
    return pages;
  };

  return (
    <Pagination>
      {currentPage === startPage ? "" :
        <Pagination.Prev disabled={currentPage <= startPage} onClick={() => props.onClick(prevPage)} className='previous' />
      }

      {
        firstPage === startPage ? "" : <><Pagination.Item onClick={() => props.onClick(firstPage)}>{firstPage} </Pagination.Item></>
      }
      {
        startPage > 2 ? <Pagination.Ellipsis onClick={() => props.onClick(leftEllipsis)} /> : ""
      }
      {pageItem()}

      {(totalPages - endPage > 1) ? <Pagination.Ellipsis onClick={() => props.onClick(rightEllipsis)} /> : ""}

      {
        totalPages === endPage ? "" :
          <Pagination.Item onClick={() => props.onClick(totalPages)}>{totalPages} </Pagination.Item>
      }
      {
        currentPage === totalPages ? "" :
          <Pagination.Next disabled={nextPage > totalPages} onClick={() => props.onClick(nextPage)} className='next' />
      }
    </Pagination>
  );
};

export default Paginate;
