
const ChatIcon = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <g id="Group_1867" data-name="Group 1867" transform="translate(-1250.091 -765.105)">
        <rect id="Rectangle_730" data-name="Rectangle 730" width="34" height="34" transform="translate(1250.091 765.105)" fill="none" />
        <g id="icn_ask" transform="translate(903.704 83)">
          <path id="Path_31473" data-name="Path 31473" d="M363.387,685C354.924,685,348,690.77,348,697.823s6.924,12.823,15.387,12.823a19.564,19.564,0,0,0,3.847-.385l7.181,2.821c.128,0,.256.128.513.128a1.822,1.822,0,0,0,.769-.256,1.392,1.392,0,0,0,.513-1.154l-.641-6.026a11.766,11.766,0,0,0,3.206-7.822C378.774,690.77,371.85,685,363.387,685Z" transform="translate(0 0)" fill="#fff" />
        </g>
      </g>
    </svg>
  )
};

export default ChatIcon;

