import React from "react";
import { Form } from "react-bootstrap";
import { InputProperty } from "../interfaces/GeneralInterface"
import "./NBSelect.scss";
interface Input extends InputProperty { }

/**
 *@file NBInputGroup.tsx
 *@brief Reusable input group component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
const NBSelect = React.forwardRef((props: Input, ref: any) => {
    const propsValue = props.value ? props.value : ''
    const optionValues = props.options
    const { handleSelectChange } = props;
    return (
        <Form.Group className='nb-inputgroup'>
            <Form.Label>{props.label}</Form.Label>
            <Form.Select
                aria-label="Default select example"
                onChange={(event) => {
                    handleSelectChange(event.target.value);
                }}
            >
                {optionValues.length > 0 ? optionValues.map((items:any) => (
                    <option value={items.reason_id}>{items.reason}</option>
                )) : ""}

            </Form.Select>
        </Form.Group>
    );
});

export default NBSelect;