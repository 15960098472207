/**
 * @file   src\components\ForgotPass.tsx
 * @brief  ForgotPass Component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Button } from 'react-bootstrap';
import Strings from '../assets/strings/Strings.json';
import { Link, useNavigate } from 'react-router-dom';

const ForgotMail = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      <h1>{Strings.FrogotPassword.MailSent.Title}</h1>
      <p>{Strings.FrogotPassword.MailSent.InfoTxt}</p>
      <Button onClick={() => {navigate('/signin')}} variant='primary' className='w-100'>{Strings.FrogotPassword.MailSent.Button}</Button>
      <div className='register pt-4'>{Strings.FrogotPassword.MailSent.InfoTxt1} <Link onClick={props.handleSubmit} to=''>{Strings.FrogotPassword.MailSent.InfoLink}</Link></div>
    </>
  );
};

export default ForgotMail;
