/**
 * @file   src\store\actions\productAction.ts
 * @brief  This file is responsible for generating asynchronous api call.
 * @date   Oct, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { GeneralApi, ProductApi } from '../../utils/apiUrls';
import { getAuthToken } from '../../utils/localStorage';
import { encryptData, decryptData } from '../../utils/Encryption';

export const productDetails = createAsyncThunk('/product/productDetails', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { baseURL: process.env.REACT_APP_API_PRODUCT_URL };
    const { data } = await axios.post(ProductApi.DETAILS, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const refundDetails = createAsyncThunk('/product/refundDetails', async (payload: any, { rejectWithValue }) => {
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), 'Content-Type': 'text/plain', Accept: 'text/plain' };
    const { data } = await axios.post(ProductApi.REFUNDDETAILS, plainText, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const requestRefund = createAsyncThunk('/product/requestRefund', async (payload: any, { rejectWithValue }) => {
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), 'Content-Type': 'text/plain', Accept: 'text/plain' };
    const { data } = await axios.post(ProductApi.REQUESTREFUND, plainText, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const productLock = createAsyncThunk('/product/productLock', async (payload: any, { rejectWithValue }) => {
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), 'Content-Type': 'text/plain', Accept: 'text/plain' };
    const { data } = await axios.post(ProductApi.LOCKPRODUCT, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        console.log('DecryptError', err);
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const reportProduct = createAsyncThunk('/product/reportProduct', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), baseURL: process.env.REACT_APP_API_PRODUCT_URL };
    const { data } = await axios.post(ProductApi.REPORTPRODUCT, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const productMakeOrder = createAsyncThunk('/product/productMakeOrder', async (payload: any, { rejectWithValue }) => {
  console.log(payload);
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), 'Content-Type': 'text/plain', Accept: 'text/plain' };
    const { data } = await axios.post(ProductApi.MAKEORDER, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        console.log('DecryptError', err);
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const reportOptions = createAsyncThunk('/product/reportOptions', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), baseURL: process.env.REACT_APP_API_PRODUCT_URL };
    const { data } = await axios.post(ProductApi.REPORTREASONS, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const createCustomer = createAsyncThunk('/product/createcustomer', async (payload: any, { rejectWithValue }) => {
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {});
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), 'Content-Type': 'text/plain', Accept: 'text/plain' };
    const { data } = await axios.post(ProductApi.CREATECUSTOMER, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        console.log('DecryptError', err);
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const categoryList = createAsyncThunk('/product/categorieslist', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), baseURL: process.env.REACT_APP_API_URL_VENDOR };
    const { data } = await axios.post(ProductApi.PRODUCTCATEGORIES, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const productMessages = createAsyncThunk('/questionslist', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { baseURL: process.env.REACT_APP_API_URL };
    const { data } = await axios.post(GeneralApi.QUESTIONLIST, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const productMessageHistory = createAsyncThunk('/productmessages', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), baseURL: process.env.REACT_APP_API_URL };
    const { data } = await axios.post(GeneralApi.PRODUCTMESSAGES, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const productMessageSend = createAsyncThunk('/askvendor', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), baseURL: process.env.REACT_APP_API_URL };
    const { data } = await axios.post(GeneralApi.PRODUCTMESSAGESSEND, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const productApplyCoupon = createAsyncThunk('/applyCoupon', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), baseURL: process.env.REACT_APP_API_URL };
    const { data } = await axios.post(ProductApi.APPLYCOUPON, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const shippingAddress = createAsyncThunk('/shippingaddress', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), baseURL: process.env.REACT_APP_API_URL };
    const { data } = await axios.post(ProductApi.SHIPPINGADDRESS, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
