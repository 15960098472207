/**
 * @file   src\components\AppsAdRight.tsx
 * @brief  AppsAdRight component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import './AppsAdRight.scss';
import Strings from '../assets/strings/Strings.json';
import { Col, Row } from 'react-bootstrap';
import Appstore from '../assets/img/AppStore-Btn.svg';
import Playstore from '../assets/img/PlayStore-Btn.svg';

const AppsAdRight = () => {
  return (
    <div className='apps-ad-right'>
      <Row>
        <Col xl='12'>
          <p>{Strings.AppsAd.Text1}</p>
          <p>{Strings.AppsAd.Text2}</p>
        </Col>
        <Col xl='12' className='d-flex align-items-end btn-wrap'>
          <img src={Appstore} alt='' />
          <img src={Playstore} alt='' />
        </Col>
      </Row>
    </div>
  );
};

export default AppsAdRight;
