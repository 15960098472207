/**
 * @file   src\store\actions\userAction.ts
 * @brief  This file is responsible for generating asynchronous api call.
 * @date   Oct, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { GeneralApi, ProductApi } from '../../utils/apiUrls';
import { getAuthToken } from '../../utils/localStorage';

export const profileImageUpload = createAsyncThunk('/user/profileImageUpload', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), 'Content-Type': 'text/plain', Accept: 'text/plain' };
    const { data } = await axios.post(GeneralApi.PROFILEIMAGEUPLOAD, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const productDetails = createAsyncThunk('/product/productDetails', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { baseURL: process.env.REACT_APP_API_PRODUCT_URL };
    const { data } = await axios.post(ProductApi.DETAILS, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const userLocationUpdate = createAsyncThunk('/user/userLocation', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { baseURL: process.env.REACT_APP_API_MONGODB_URL };
    const { data } = await axios.post(GeneralApi.UPDATELOCATION, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const userDeleteAccount = createAsyncThunk('/user/deleteAccount', async (payload: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(GeneralApi.DELETEACCOUNT, payload);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const userSupport = createAsyncThunk('/user/support', async (payload: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(GeneralApi.SUPPORT, payload);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
export const userOfferRequest = createAsyncThunk('/user/offerRequest', async (payload: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(GeneralApi.OFFERREQUEST, payload);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const offerRequestImageUpload = createAsyncThunk('/user/offerRequestImageUpload', async (payload: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(GeneralApi.OFFERREQUESTIMAGEUPLOAD, payload);
    return data;
  } catch (error: any) {
    console.log('error', error);

    return rejectWithValue(error.response?.data);
  }
});
export const NationWideListing = createAsyncThunk('/user/NationWideListing', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { baseURL: process.env.REACT_APP_API_URL_VENDOR };
    const { data } = await axios.post(GeneralApi.NATIONWIDELISTING, payload, { headers });
    return data;
  } catch (error: any) {
    console.log('error', error);

    return rejectWithValue(error.response?.data);
  }
});
