/**
 * @file   src\components\AddressAutocomplete.tsx
 * @brief  Action Button component.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import './AddressAutocomplete.scss';

interface AddressAutocompleteProps {
  apiKey: string;
  address: string;
  handlePlaceSelected: (place: any) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ apiKey, address, handlePlaceSelected, handleChange, label }) => {
  return (
    <div>
      <label>{label}</label>
      <Autocomplete
        apiKey={apiKey}
        style={{ width: '100%' }}
        onPlaceSelected={handlePlaceSelected}
        name="address1"
        onChange={handleChange}
        value={address}
        options={{
          fields: ['formatted_address', 'address_components', 'geometry', 'name'],
          strictBounds: false,
          types: ['geocode', 'establishment'],
          componentRestrictions: { country: 'us' }
        }}
      />
      </div>
  );
};

export default AddressAutocomplete;
