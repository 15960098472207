
const Apple = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.148" height="29.266" viewBox="0 0 25.148 29.266">
      <path id="icons8-apple" d="M30.148,22.472a21.014,21.014,0,0,1-1.9,3.549c-1.235,1.879-2.977,4.225-5.13,4.24-1.916.017-2.411-1.25-5.013-1.228s-3.141,1.253-5.06,1.233c-2.155-.02-3.8-2.132-5.038-4.009C4.555,21,4.19,14.828,6.325,11.547a7.644,7.644,0,0,1,6.143-3.693c2.286,0,3.723,1.255,5.612,1.255,1.834,0,2.95-1.258,5.594-1.258A7.467,7.467,0,0,1,29.3,10.819,6.435,6.435,0,0,0,30.148,22.472ZM21.666,5.752A6.411,6.411,0,0,0,23.093,1a6.937,6.937,0,0,0-4.473,2.406,5.963,5.963,0,0,0-1.464,4.635A5.833,5.833,0,0,0,21.666,5.752Z" transform="translate(-5 -1)" />
    </svg>


  )
};

export default Apple;

