
const Search = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.782" height="25.782" viewBox="0 0 25.782 25.782">
            <g id="Group_1620" data-name="Group 1620" transform="translate(-1133.368 -209.014)">
                <line id="Line_72" data-name="Line 72" x1="6.957" y1="6.957" transform="translate(1150.779 226.425)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                <circle id="Ellipse_8" data-name="Ellipse 8" cx="9.5" cy="9.5" r="9.5" transform="translate(1134.368 210.014)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
            </g>
        </svg>
    )
};

export default Search;

