import React, { useState } from 'react';
import './Chat.scss';
import { Button, CloseButton } from 'react-bootstrap';
import ChatIcon from '../assets/img/icons/Chat';
import { Scrollbars } from 'react-custom-scrollbars-2';

const Chat = (props: any) => {
  const viewChat = props.viewChat;
  return (
    <>
      {viewChat ? (
        <div className="chat-btn" onClick={props.handleView}>
          <Button variant="primary">
            Chat <ChatIcon />
          </Button>
        </div>
      ) : (
        <div className="chat-window">
          <div className="chat-header d-flex align-items-center justify-content-center position-relative">
            <h4>Chat with Vendor</h4>
            <CloseButton onClick={props.handleView} />
          </div>
          <div className="chat-body">
            <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={250}>
              <div className="sent-messages">
                {props.productMessageHistory.length ? '' : <div className="text-center">No recent chats</div>}
                {props.productMessageHistory &&
                  props.productMessageHistory.map((data: any, key: any) => (
                    <React.Fragment key={key}>
                      {data?.question && <span className="message d-block recieved">{data.question}</span>}
                      {data?.answer && <span className="message d-block sent">{data.answer}</span>}
                    </React.Fragment>
                  ))}
              </div>
            </Scrollbars>
            <div className="preset-message-wrap">
              <h5>Send a Message</h5>
              <Scrollbars style={{ height: 230 }}>
                <div className="preset-outer">
                  {props.questionList &&
                    props.questionList.map((data: any, key: any) => (
                      <React.Fragment key={key}>
                        {props.questionsAsked && props.questionsAsked.includes(data.question_id) ? <span className="preset-message d-block sent">{data.question_text}</span> : ''}
                        {props.questionsAsked && props.questionsAsked.includes(data.question_id) ? (
                          ''
                        ) : (
                          <span
                            className={props.selectedMsg === data.question_id ? 'preset-message d-block active' : 'preset-message d-block'}
                            onClick={() => props.changeSelectedMsg(data.question_id)}
                          >
                            {data.question_text}
                          </span>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </Scrollbars>
            </div>
            <div className="text-center button-wrap">
              <Button variant="primary" disabled={props.sendBtnActive} onClick={props.send}>
                Send
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Chat;
