import React from "react";
import { Form } from "react-bootstrap";
import { InputProperty } from "../interfaces/GeneralInterface"
import "./NBInputGroup.scss";
interface Input extends InputProperty { }

/**
 *@file NBInputGroup.tsx
 *@brief Reusable input group component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
const NBInputGroup = React.forwardRef((props: Input, ref: any) => {
    const propsValue = props.value ? props.value : ''
    return (
        <Form.Group className='nb-inputgroup'>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control
                id={props.id}
                name={props.name}
                type={props.type}
                placeholder={props.placeholder}
                required={props.required}
                onChange={props.onChange}
                isInvalid={props.isInvalid}
                className={props.className}
                value={props.value}
                disabled={props.disabled}
                ref={ref}
                maxLength={props.maxLength}
                readOnly={props.readOnly}
                as={props.as}
                rows={props.rows}
                onBlur={props.onBlur}
            />
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            {props.error && props.error !== '' ? <Form.Text className='error'style={{ color: 'red' }}>
          {props.error}
        </Form.Text> : ""}
            
        </Form.Group>
    );
});

export default NBInputGroup;
