
const IcFood = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.833" height="20" viewBox="0 0 15.833 20">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_614" data-name="Rectangle 614" width="15.833" height="20" />
        </clipPath>
      </defs>
      <g id="icn_food" clip-path="url(#clip-path)">
        <path id="Path_31554" data-name="Path 31554" d="M7.5,0a.833.833,0,0,0-.833.833V4.167H5V.833a.833.833,0,0,0-1.667,0V4.167H1.667V.833A.833.833,0,0,0,0,.833V7.5A2.5,2.5,0,0,0,2.5,10v8.333a1.667,1.667,0,1,0,3.333,0V10a2.5,2.5,0,0,0,2.5-2.5V.833A.833.833,0,0,0,7.5,0" transform="translate(0 0)" />
        <path id="Path_31555" data-name="Path 31555" d="M17,0a5.005,5.005,0,0,0-5,5v7.5a.833.833,0,0,0,.833.833H14.5v5a1.667,1.667,0,1,0,3.333,0V.833A.833.833,0,0,0,17,0" transform="translate(-2 0)" />
      </g>
    </svg>
  )
};

export default IcFood;

