
const IcProducts = (props: any) => {
  return (
    <svg id="icn_products" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_589" data-name="Rectangle 589" width="20" height="20" />
        </clipPath>
      </defs>
      <g id="Group_1417" data-name="Group 1417" clip-path="url(#clip-path)">
        <path id="Path_31520" data-name="Path 31520" d="M15.383,4.167H21.8l-4.75-4A1.545,1.545,0,0,0,16.467,0H13.8Z" transform="translate(-2.3)" />
        <path id="Path_31521" data-name="Path 31521" d="M13.333,7v5.833H6.667V7H0V20.333a.787.787,0,0,0,.833.833H19.167A.787.787,0,0,0,20,20.333V7Z" transform="translate(0 -1.167)" />
        <path id="Path_31522" data-name="Path 31522" d="M7.017,4.167,8.6,0H5.933a.893.893,0,0,0-.5.167L.6,4.167Z" transform="translate(-0.1)" />
      </g>
    </svg>

  )
};

export default IcProducts;

