/**
 * @file   src\containers\NotificationList.tsx
 * @brief  Notification page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Button, Col, Container, Row, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Strings from '../assets/strings/Strings.json';
import Notification from '../components/Notification';
import ProfileImg from '../assets/img/user-image.png';
import NoImage from '../assets/img/no-image.svg'
import { useAppSelector } from '../hooks/index';
import { clearNtfn, ntnList, ntfnCheck, ntfnRead } from '../store/actions/ntfnAction';
import { resetClearNtfnState } from '../store/slices/authSlice';
import * as CONSTANT from '../utils/constants';
import * as alerts from '../utils/alerts';
import { toast } from 'react-toastify';
import ChatIcon from '../assets/img/icons/Chat';
import Chat from '../components/Chat';
import { useNavigate, useLocation } from 'react-router-dom';
import { getGusetUser, getAuthToken } from '../utils/localStorage';
import { error_code } from '../utils/enums';

type NotificationItem = {
  message: string;
  isread: number;
  imageurl: string;
  notificationId: number;
  productId:number;
  isDeleted:number;
};

const NotificationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const guestUser = getGusetUser();
  const notificationList = () => dispatch<any>(ntnList());
  const notificationCheck = () => dispatch<any>(ntfnCheck());
  const clearAllNtfnResponse = useAppSelector((RootReducer) => RootReducer.auth.clearntfn);
  const NtfnResponse = useAppSelector((RootReducer) => RootReducer.auth.ntfnlist);
  const notificationCheckApiResponse = useAppSelector((RootReducer) => RootReducer.auth.ntfncheck);
  const notificationReadApiResponse = useAppSelector((RootReducer) => RootReducer.auth.ntfnread);
  const [allNotificationList, setNotificationList] = useState<NotificationItem[]>([]);
  const [notificationCount, setNotificationCount] = useState<any>(0);
  const [notificationAvailable, setNotificationAvailable] = useState<any>(0);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(true);

  // handle notification check intial api call and notification list api call
  useEffect(() => {
    notificationList();
    setLoad(true)
  }, []);

  // handle function to call clear all notification api call
  const clearAllNtfns = () => {
    setShowConfirm(false);
    dispatch<any>(clearNtfn());
  };

  // funtion to handle marks the notification as readed
  const markNotificationAsRead = (notification_id: number) => {
    const payload = {
      notification_array: [notification_id],
    };
    dispatch<any>(ntfnRead(payload));
  };

  // handles clear all api response
  useEffect(() => {
    if (clearAllNtfnResponse.isLoading === false && clearAllNtfnResponse.isSuccess === true) {
      if (clearAllNtfnResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        notificationList();
        notificationCheck();
      }
      dispatch(resetClearNtfnState());
    }
  }, [clearAllNtfnResponse]);

  // handles notification list api response
  useEffect(() => {
    if (NtfnResponse.isLoading === false && NtfnResponse.isSuccess === true) {
      if (NtfnResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        if (NtfnResponse.ntfnInfo.notificationDetails.length > 0) {
          setNotificationList(NtfnResponse.ntfnInfo.notificationDetails);
          setNotificationAvailable(1);
        }
      } else {
        setNotificationAvailable(0);
      }
      setLoad(false);
    }
  }, [NtfnResponse]);

  // handles notification total unread msgs api response
  useEffect(() => {
    if (notificationCheckApiResponse.isLoading === false && notificationCheckApiResponse.isSuccess === true) {
      if (notificationCheckApiResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        if (notificationCheckApiResponse.ntfnInfo.total_unread_msgs) {
          setNotificationCount(notificationCheckApiResponse.ntfnInfo.total_unread_msgs);
        } else {
          setNotificationCount(0);
        }
      }
    }
  }, [notificationCheckApiResponse]);

  // handles notification read api response
  useEffect(() => {
    if (notificationReadApiResponse.isLoading === false && notificationReadApiResponse.isSuccess === true) {
      if (notificationReadApiResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        notificationList();
        notificationCheck();
      }
    }
  }, [notificationReadApiResponse]);

  // handle cofirmation modal open and close

  const showConfirmClose = () => {
    setShowConfirm(false);
  }
  const showConfirmOpen = () => {
    setShowConfirm(true);
  }

  // This will go back to the previous page in the browser history
  const handleGoBack = () => {
    window.location.href = '/';
  };

  // navigate to corresponding clicked product details page
  const navigateToProductInfo = (product_id: number, isDeleted:number) => {
    if(product_id && isDeleted === error_code.Code_0 ){
      navigate(`/productdetails/${product_id}`, { state: { product_id: product_id, guestUser: guestUser === true ? "" : getAuthToken() } });
    }
  };

  return (
    <div className="inner-layout">
      {load ? (
        <div className="spinner-outer d-flex justify-content-center">
          <div className=" align-items-center justify-content-center d-flex">
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status" />
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status" />
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status" />
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901', marginRight: 10 }} role="status" />
            <div className="spinner-grow" style={{ width: '2rem', height: '2rem', color: '#F9C901' }} role="status" />
          </div>
        </div>
      ) : (
        <>
          <Container fluid className="page-header">
            <Container>
              <Row>
                <Col md="6" className="d-flex align-items-center">
                  <h2>{Strings.Notification.Title}</h2>
                </Col>
                <Col md="6" className="text-md-end btn-wrap">
                  <Button variant="secondary" className="clear-btn" disabled={notificationAvailable === 0} onClick={showConfirmOpen}>
                    {Strings.Notification.ClearAll}
                  </Button>
                  <Button onClick={handleGoBack} variant="secondary">
                    {Strings.Notification.Back}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid>
            <Container>
              {notificationAvailable === 1 && allNotificationList.length > 0 ? (
                allNotificationList.map((notification, index) => (
                  <Notification
                    key={index}
                    image={notification.imageurl ? notification.imageurl : NoImage}
                    text={notification.message}
                    unread={notification.isread === 0}              
                    onClick={() => {markNotificationAsRead(notification.notificationId);navigateToProductInfo(notification.productId,notification.isDeleted)}}
                  />
                ))
              ) : (
                <Col className="text-center mt-5">{Strings.Notification.NoNtfn}</Col>
              )}
            </Container>
            {/* <Chat/> */}
          </Container>
          <Modal show={showConfirm} onHide={showConfirmClose} centered backdrop="static">
            <Modal.Body className="otp-verify">
              <h5 className="text-center d-block pt-3">
                {alerts.CONFIRM_ALERT}
                <br />
              </h5>
              <div className="btn-wrap d-flex pt-4">
                <Button onClick={showConfirmClose} variant="secondary">
                  {Strings.Header.DropDownMenu.signOut.Btn2}
                </Button>
                <Button onClick={clearAllNtfns} variant="primary">
                  {Strings.Header.DropDownMenu.signOut.Btn1}
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};

export default NotificationList;
