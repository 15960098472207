
const IcServices = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.404" height="23.457" viewBox="0 0 23.404 23.457">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_605" data-name="Rectangle 605" width="23.404" height="23.457" transform="translate(0 0.001)" />
        </clipPath>
      </defs>
      <g id="icn_services" transform="translate(0 0)">
        <g id="Group_1455" data-name="Group 1455" clip-path="url(#clip-path)">
          <path id="Path_31549" data-name="Path 31549" d="M.735,14.514a3.478,3.478,0,0,0,1.026,2.475l4.95,4.95a3.487,3.487,0,0,0,3.866.733,4.29,4.29,0,0,0,5.514-.478l3.8-3.8a4.3,4.3,0,0,0,1.113-4.1A8.411,8.411,0,0,0,11.674.681,8.394,8.394,0,0,0,1.218,12.766a3.475,3.475,0,0,0-.483,1.748M3.875,3.878A6.413,6.413,0,0,1,9.449,2.094c-.137.121-.276.239-.407.37L3.8,7.706a1,1,0,0,0,.155,1.54,5.045,5.045,0,0,0,5.963-.685c.905.406,3.589,1.229,4.373.959a5.757,5.757,0,0,0,1.981-1.282.5.5,0,0,1,.779.58,23.561,23.561,0,0,1-1.173,2.289l2.6,2.6a2.312,2.312,0,0,1,0,3.267l-3.8,3.8a2.29,2.29,0,0,1-2.507.493,3.47,3.47,0,0,0-.51-4.284L6.711,12.04a3.591,3.591,0,0,0-3.964-.663,6.4,6.4,0,0,1,1.128-7.5" />
        </g>
      </g>
    </svg>
  )
};

export default IcServices;

