// Auth api urls.
export enum AuthApi {
  LOGIN = 'V1/login',
  FORGOTPASSWORD = 'V1/forgotpassword',
  SIGNUP = 'V1/signup',
  SENDOTP = 'V1/resendotp',
  VERIFYOTP = 'V1/verifyemail',
  SIGNOUT = 'V1/logout',
  DELETEUSER = 'V1/deleteaccount',
  PROFILE = 'V1/profile',
  EDITPROFILE = 'V1/profile/update',
  APPLELOGIN = 'V1/applelogin',
  FACEBOOKLOGIN = 'V1/fbloginweb',
  REFRESHTOKEN = 'V1/auth/refreshtoken',
  RESETPASSWORD = 'V1/resetpassword',
  CHANGEPASSWORD = 'V1/changepassword',
  PURCHASEHISTORY = 'V1/orders',
  LOCATIONLISTING = 'V1/location/listing',
  FORGOTEMAIL = 'V1/forgotemail',
}

export enum GeneralApi {
  CLEARNTFN = 'V1/notification/clear',
  CHECKNTFN = 'V1/notification/unreadmsgs',
  LISTNTFN = 'V1/notification/list',
  PROFILEIMAGEUPLOAD = 'V1/image/update',
  READNTFN = 'V1/notification/read',
  UPDATELOCATION = 'userDetails/updateUserLocations',
  QUESTIONLIST = 'V1/questionslist',
  PRODUCTMESSAGES = 'V1/productmessages',
  PRODUCTMESSAGESSEND = 'V1/askvendor',
  DELETEACCOUNT = 'V1/publicDeleteAccount',
  SUPPORT = 'V1/support',
}
export enum ProductApi {
  DETAILS = 'V1/productdetails',
  REFUNDDETAILS = 'V1/refund/details',
  REQUESTREFUND = 'V1/refund/request',
  LOCKPRODUCT = 'V1/orders/lockproduct',
  MAKEORDER = 'V1/orders/makeorder',
  CREATECUSTOMER = 'V1/orders/createcustomer',
  VENDORPRODUCTS = 'V1/location/listbylocation',
  REPORTPRODUCT = 'V1/reportproduct',
  REPORTREASONS = 'V1/reasonslist',
  PRODUCTCATEGORIES = 'V1/categorieslist',
  APPLYCOUPON = 'V1/applyCoupon',
  SHIPPINGADDRESS = 'V1/shippingaddress',
}
