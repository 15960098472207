
const ShareIc = (props: any) => {
  return (
    <svg id="Group_1486" data-name="Group 1486" xmlns="http://www.w3.org/2000/svg" width="20.214" height="22" viewBox="0 0 20.214 22">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_612" data-name="Rectangle 612" width="20.214" height="22" fill="none" stroke="#262626" stroke-width="1" />
        </clipPath>
      </defs>
      <g id="Group_1485" data-name="Group 1485" clip-path="url(#clip-path)">
        <circle id="Ellipse_184" data-name="Ellipse 184" cx="2.68" cy="2.68" r="2.68" transform="translate(1.173 8.32)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2" />
        <circle id="Ellipse_185" data-name="Ellipse 185" cx="2.68" cy="2.68" r="2.68" transform="translate(13.681 1.172)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2" />
        <circle id="Ellipse_186" data-name="Ellipse 186" cx="2.68" cy="2.68" r="2.68" transform="translate(13.681 15.467)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2" />
      </g>
      <line id="Line_167" data-name="Line 167" y1="4.468" x2="7.862" transform="translate(6.176 5.192)" fill="none" stroke-miterlimit="10" stroke-width="2" />
      <line id="Line_168" data-name="Line 168" x2="7.862" y2="4.468" transform="translate(6.176 12.34)" fill="none" stroke-miterlimit="10" stroke-width="2" />
    </svg>


  )
};

export default ShareIc;

