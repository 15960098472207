/**
 *@file Catalogue.tsx
 *@brief Catalogue component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
import React from 'react';
import DownloadIc from '../assets/img/icons/Download';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Catalogue.scss';
import PdfImage from '../assets/img/pdf-image.svg';
import { error_code } from '../utils/enums';

const Catalogue = (props: any) => {


  const downloadImage = (image:any) => {
    const getFileExtensionFromUrl = (image:any) => {
      const parts = image.split('.');
      if (parts.length > 1) {
        const lastPart = parts[parts.length - 1];
        return lastPart.toLowerCase(); // Convert to lowercase for consistency
      }
      return null; // Return null if no file extension is found
    };
    const filename = getFileExtensionFromUrl(image)
    const a = document.createElement('a');
    a.href = image;
    a.target= "_blank"
    a.download = `download.${filename}`; // Set the desired filename
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div className="catalogue">
      <div className="image-wrap position-relative">
        <img src={props.filetype !== error_code.Code_3 ? props.image :PdfImage} alt="" />
        <OverlayTrigger overlay={<Tooltip>Download</Tooltip>}>
          <div className="position-absolute download-wrap" onClick={()=>downloadImage(props.image)}>
            <DownloadIc />
          </div>
        </OverlayTrigger>
        <div className="overlay"></div>
      </div>
    </div>
  );
};

export default Catalogue;
