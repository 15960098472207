/**
 * @file   src\store\index.ts
 * @brief  This file is responsible for configuring the redux store.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import productReducer from './slices/productSlice';

export const store = configureStore({
  reducer: {
    // Add reducer slices.
    auth: authReducer,
    user: userReducer,
    product: productReducer,
  },
  // Adding the api middlewares enables caching , invalidation, polling and other useful features of rtk query.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
