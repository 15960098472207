
const Message = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g id="Group_1854" data-name="Group 1854" transform="translate(-1131 -405)">
        <rect id="Rectangle_727" data-name="Rectangle 727" width="36" height="36" transform="translate(1131 405)" fill="none" />
        <g id="message" transform="translate(0.479 0.018)">
          <rect id="Rectangle_722" data-name="Rectangle 722" width="32" height="22" transform="translate(1132.521 411.982)" fill="none" />
          <path id="Path_31727" data-name="Path 31727" d="M26.948,19.965H2.09A2.092,2.092,0,0,1,0,17.874V2.092A2.092,2.092,0,0,1,2.09,0H26.948a2.092,2.092,0,0,1,2.09,2.091V17.872a2.092,2.092,0,0,1-2.09,2.093ZM2.09.98A1.11,1.11,0,0,0,.981,2.09V17.871A1.11,1.11,0,0,0,2.09,18.98H26.948a1.11,1.11,0,0,0,1.109-1.11V2.092A1.11,1.11,0,0,0,26.948.981Z" transform="translate(1134 413)" fill="#94da0a" />
          <path id="Path_31728" data-name="Path 31728" d="M14.519,11.473a.49.49,0,0,1-.277-.086L.856,2.213A.491.491,0,1,1,1.41,1.4l13.108,8.985L27.626,1.4a.491.491,0,1,1,.554.81L14.8,11.391a.49.49,0,0,1-.276.082Z" transform="translate(1134 413)" fill="#94da0a" />
          <path id="Subtraction_1" data-name="Subtraction 1" d="M28.524,16.667a.516.516,0,0,1-.29-.09L17.195,9.01l.9-.633,10.724,7.354a.512.512,0,0,1-.3.936Zm-28.01,0a.511.511,0,0,1-.505-.608.509.509,0,0,1,.215-.329L10.939,8.387l.9.626L.805,16.576a.516.516,0,0,1-.291.091Z" transform="translate(1134 413)" fill="#94da0a" />
        </g>
      </g>
    </svg>
  )
};

export default Message;

