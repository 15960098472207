/**
 * @file   src\components\SubMenu.tsx
 * @brief  Sub Menu component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Link } from 'react-router-dom';
import './Header.scss';
import { Badge, Button, NavDropdown, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Notification from '../assets/img/icons/Notification';
import USFlag from '../assets/img/icons/USFlag';
import ProfileDummy from '../assets/img/user-image.png';
import Strings from '../assets/strings/Strings.json';
import DropMenu from './DropdownMenu';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../hooks/index';
import { ntfnCheck } from '../store/actions/ntfnAction';
import { getProfile } from '../store/actions/authAction';
import * as CONSTANT from '../utils/constants';
import { getGusetUser, setNationalWide, removeNationalWide, getNationalWide, getUser } from '../utils/localStorage';

const SubMenu = () => {
  const guestUser = getGusetUser();
  const UserInfo = JSON.parse(getUser());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notificationCheck = () => dispatch<any>(ntfnCheck());
  const profileInfo = () => {
    dispatch<any>(getProfile());
  };
  const nationalWide = getNationalWide()
  const notificationCheckApiResponse = useAppSelector((RootReducer) => RootReducer.auth.ntfncheck);
  const profileImageUploadResponse = useAppSelector((RootReducer) => RootReducer.user.profileImageUpload);
  const profileResponse = useAppSelector((RootReducer) => RootReducer.auth.profile);
  const [notificationCount, setNotificationCount] = useState<any>(0);
  const [gusetUserSignOutShow, setGusetUserSignOutShow] = useState<boolean>(false);
  const [activeNationalWide, setActiveNationalWide] = useState<boolean>(false);
  const [images, setImages] = useState<any>(null);
  const pathName = window.location.pathname;

  // handle intial national wide button configure and call notification and profile api call
  useEffect(() => {
    if (guestUser !== true && pathName !== '/support' && pathName !== '/deleteaccount' && UserInfo) {
      notificationCheck();
      profileInfo();
    }
    if (nationalWide !== ('' || null)) {
      setActiveNationalWide(true)
    }
  }, []);

  // handle notification check API response
  useEffect(() => {
    if (notificationCheckApiResponse.isLoading === false && notificationCheckApiResponse.isSuccess === true) {
      if (notificationCheckApiResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        if (notificationCheckApiResponse.ntfnInfo.total_unread_msgs) {
          setNotificationCount(notificationCheckApiResponse.ntfnInfo.total_unread_msgs);
        } else {
          setNotificationCount(0);
        }
      }
    }
  }, [notificationCheckApiResponse]);

  // handle guest sign out modal close
  const signoutGuestConfirmationClose = () => {
    setGusetUserSignOutShow(false);
  };

  // handle guest sign out and navigate to login
  const logoutGuestUser = () => {
    setGusetUserSignOutShow(false);
    navigate('/signin');
    if (window.zE) {
      window.zE('messenger:set', 'cookies', false);
      // window.zE('messenger', 'hide');
    }
    // window.location.reload();
  };

  // handle National wide button
  const handleNationalWide = () => {
    if(nationalWide === (''|| null)){
      setNationalWide(JSON.parse('1'));
      navigate('/');
      setActiveNationalWide(true);
    } else {
      removeNationalWide();
      navigate('/');
      setActiveNationalWide(false);
    }
  };

  // handle profile image response
  useEffect(() => {
    if (profileResponse.isLoading === false && profileResponse.isSuccess === true) {
      if (profileResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setImages(profileResponse.userInfo?.user_info?.p_image);
      }
    }
  }, [profileResponse]);

  // handle profile upload image response
  useEffect(() => {
    if (profileImageUploadResponse.isLoading === false && profileImageUploadResponse.isSuccess === true) {
      if (profileImageUploadResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setImages(profileImageUploadResponse?.userInfo?.profile_image);
      }
    }
  }, [profileImageUploadResponse]);

  const getChat = document.getElementById('ze-snippet');

  return (
    <>
      <Button className={activeNationalWide ? 'active' : ''} onClick={() => handleNationalWide()}>
        {Strings.Header.SubMenu.MenuItem1}
        <USFlag />
      </Button>
      <Link onClick={() => guestUser === true && setGusetUserSignOutShow(true)} className="notification position-relative"  to={guestUser !== true && '/notification'}>
        <Notification />
        {guestUser !== true && notificationCount > 0 && (
          <Badge className="position-absolute" bg="danger">
            {notificationCount}
          </Badge>
        )}
      </Link>
      {(!UserInfo || guestUser === true) ? '' :
      <NavDropdown
        title={
          <div className="profile-icon">
            <img src={images != null ?images : ProfileDummy} alt="" />
          </div>
        }
        align="end"
        autoClose
      >
         <DropMenu setGusetUserSignOutShow={setGusetUserSignOutShow} />
      </NavDropdown>
      }
      <Modal show={gusetUserSignOutShow} onHide={signoutGuestConfirmationClose} centered backdrop="static">
        <Modal.Body className="otp-verify">
          <h1 className="text-center">{Strings.Login.Title}</h1>
          <span className="text-center d-block pt-3">
            Sign in to continue.
            <br />
          </span>
          <div className="btn-wrap d-flex pt-4">
            <Button onClick={signoutGuestConfirmationClose} variant="secondary">
              {Strings.Header.DropDownMenu.signOut.Btn2}
            </Button>
            <Button onClick={logoutGuestUser} variant="primary">
              {Strings.Header.DropDownMenu.signOut.Btn1}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubMenu;
