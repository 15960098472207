import React from 'react';
import { Form } from 'react-bootstrap';
import { InputProperty } from '../interfaces/GeneralInterface';
import './NBInputGroupPswd.scss';
import ShowPassword from '../assets/img/icons/ShowPassword';
interface Input extends InputProperty {}

/**
 *@file NBInputGroupPswd.tsx
 *@brief Reusable input password show/hide component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
const NBInputGroupPswd = React.forwardRef((props: Input, ref: any) => {
  const propsValue = props.value ? props.value : '';
  return (
    <Form.Group className="nb-inputgroupPswd position-relative">
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        id={props.id}
        name={props.name}
        type={props.showpass ? 'text' : 'password'}
        placeholder={props.placeholder}
        required={props.required}
        onChange={props.onChange}
        isInvalid={props.isInvalid}
        className={props.className}
        value={props.value}
        maxLength={props.maxLength}
        onKeyDown={props.onKeyDown}
      />
      <Form.Control.Feedback type="invalid">{props.error}</Form.Control.Feedback>
      {/* <span onClick={props.handleShowPassword} className="show-pass position-absolute"> */}
      <span onClick={props.handleShowPassword} className={`show-pass position-absolute ${props.showpass && props.value !== '' ? 'active' : ''}`}>
        <ShowPassword />
      </span>
      {props.error && props.error !== '' ? <Form.Text className="error"style={{ color: 'red' }}>{props.error}</Form.Text> : ''}
    </Form.Group>
  );
});

export default NBInputGroupPswd;
