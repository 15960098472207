import React from "react";
import { Form } from "react-bootstrap";
import { InputProperty } from "../interfaces/GeneralInterface"
import "./NBPhoneInputGroup.scss";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
interface Input extends InputProperty { }

/**
 *@file NBPhoneInputGroup.tsx
 *@brief Reusable phone number input group component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
const NBPhoneInput = React.forwardRef((props: any, ref: any) => {
    const propsValue = props.value ? props.value : ''
    return (
        <Form.Group className='nb-inputgroup'>
            <Form.Label>{props.label}</Form.Label>

            <PhoneInput
                onlyCountries={["us"]}
                disableDropdown={true}
                disableCountryCode={true}
                containerClass="nb-phone"
                placeholder=''
                country={'us'} // Set the country code
                enableSearch={false}
                disableSearchIcon={false}
                value={propsValue}
                onChange={props.onChange}
                disabled={props.disabled}
            />
            <Form.Control.Feedback type="invalid">Error Comes Here</Form.Control.Feedback>
            {props.error && props.error !== '' ? <Form.Text className='error' style={{ color: 'red' }}>
          {props.error}
        </Form.Text> : ""}
        </Form.Group>
    );
});

export default NBPhoneInput;
