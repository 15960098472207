
const LogoInner = (props: any) => {
  const pathName = window.location.pathname;

  return (
    <svg onClick={() => {pathName !== '/support' && pathName !== '/deleteaccount' && props.navigate('/')}} xmlns="http://www.w3.org/2000/svg" width="99.746" height="47.462" viewBox="0 0 99.746 47.462">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_656" data-name="Rectangle 656" width="99.746" height="47.462" fill="none" />
        </clipPath>
      </defs>
      <g id="logo" transform="translate(-21.188 -497.635)">
        <g id="logo-2" data-name="logo" transform="translate(33.456 21.306)">
          <g id="Component_40_8" data-name="Component 40 – 8" transform="translate(-12.268 476.33)">
            <path id="Path_31689" className="primary-col" data-name="Path 31689" d="M238.355,91.364h5.031l6.077,8.207h.037V91.364h4.223v13.11h-4.829l-6.279-8.281h-.036v8.281h-4.223Z" transform="translate(-197.748 -75.799)" />
            <g id="Group_1609" data-name="Group 1609">
              <g id="Group_1608" data-name="Group 1608" clip-path="url(#clip-path)">
                <path id="Path_31690" className="primary-col" data-name="Path 31690" d="M357.664,114.929a3.741,3.741,0,0,1-.643,1.221,4.416,4.416,0,0,1-1.258,1.074,7.318,7.318,0,0,1-1.955.771,11.336,11.336,0,0,1-2.754.293,8.7,8.7,0,0,1-5.169-1.312,4.709,4.709,0,0,1-1.808-4.086,5.168,5.168,0,0,1,.5-2.322,4.686,4.686,0,0,1,1.4-1.681,6.24,6.24,0,0,1,2.166-1.019,10.849,10.849,0,0,1,2.809-.339,10.638,10.638,0,0,1,2.873.358,5.924,5.924,0,0,1,2.176,1.093,4.75,4.75,0,0,1,1.377,1.863,6.886,6.886,0,0,1,.478,2.69v.293h-9.768a2.773,2.773,0,0,0,.294.891,2.207,2.207,0,0,0,.6.707,2.8,2.8,0,0,0,.927.459,4.561,4.561,0,0,0,1.3.165,2.715,2.715,0,0,0,1.469-.34,3.532,3.532,0,0,0,.881-.78Zm-3.819-3.121a3.109,3.109,0,0,0-.294-.78,2.085,2.085,0,0,0-.551-.652,2.766,2.766,0,0,0-.844-.441,3.814,3.814,0,0,0-1.194-.165,3.9,3.9,0,0,0-1.2.165,2.742,2.742,0,0,0-.854.441,2.078,2.078,0,0,0-.551.652,2.636,2.636,0,0,0-.276.78Z" transform="translate(-285.458 -89.21)" />
                <path id="Path_31691" className="primary-col" data-name="Path 31691" d="M436.25,111.771a3.292,3.292,0,0,1,.688-1.772,3.644,3.644,0,0,1,1.414-1.029,6.726,6.726,0,0,1,1.919-.468,22.566,22.566,0,0,1,4.718.028,7.278,7.278,0,0,1,2.01.5,3.12,3.12,0,0,1,1.331,1.029,2.849,2.849,0,0,1,.477,1.707V116.8q0,.312.018.55a2.386,2.386,0,0,0,.074.441,4.118,4.118,0,0,0,.128.395q.073.193.183.413H445.32a2.278,2.278,0,0,1-.137-.469q-.046-.247-.064-.45a4.147,4.147,0,0,1-2.02,1.065,12.526,12.526,0,0,1-2.662.257q-4.866,0-4.865-3.1a3.363,3.363,0,0,1,.275-1.432,2.368,2.368,0,0,1,.826-.964,4.213,4.213,0,0,1,1.35-.579,10.619,10.619,0,0,1,1.845-.275l3.874-.257a1.858,1.858,0,0,0,.964-.239.717.717,0,0,0,.211-.569.855.855,0,0,0-.588-.8,4.849,4.849,0,0,0-1.873-.266,3.411,3.411,0,0,0-1.634.3,1.389,1.389,0,0,0-.679.946Zm8.776,2.185a9.644,9.644,0,0,1-1.487.275l-2.626.312a1.567,1.567,0,0,0-.853.313.887.887,0,0,0-.266.7,1.009,1.009,0,0,0,.477.9,2.282,2.282,0,0,0,1.267.312,4.8,4.8,0,0,0,2.6-.579,1.833,1.833,0,0,0,.89-1.625Z" transform="translate(-361.364 -89.926)" />
                <path id="Path_31692" className="primary-col" data-name="Path 31692" d="M531.161,108.649H535v1.964h.037a4.46,4.46,0,0,1,1.662-1.606,4.73,4.73,0,0,1,2.4-.615q.312,0,.67.028a5.989,5.989,0,0,1,.652.083v3.635a4.527,4.527,0,0,0-.982-.239,7.97,7.97,0,0,0-1.166-.092,3.312,3.312,0,0,0-2.441.78,2.935,2.935,0,0,0-.771,2.158V118.6h-3.892Z" transform="translate(-440.669 -89.926)" />
                <path id="Path_31693" className="secondary-col" data-name="Path 31693" d="M238.355,180.52h10.851a4.751,4.751,0,0,1,1.928.33,3.022,3.022,0,0,1,1.129.817,2.669,2.669,0,0,1,.533,1.056,4.412,4.412,0,0,1,.138,1.028,3.055,3.055,0,0,1-.459,1.652,2.3,2.3,0,0,1-1.157.991v.037a2.769,2.769,0,0,1,1.662,1.065,3.64,3.64,0,0,1,.615,2.221,5.1,5.1,0,0,1-.174,1.267,3.074,3.074,0,0,1-.689,1.267,3.9,3.9,0,0,1-1.45.982,6.357,6.357,0,0,1-2.442.395H238.355Zm4.37,5.049h4.352a1.371,1.371,0,0,0,1.019-.33,1.1,1.1,0,0,0,.321-.79,1.072,1.072,0,0,0-.321-.771,1.368,1.368,0,0,0-1.019-.331h-4.352Zm4.7,5.233a1.676,1.676,0,0,0,1.12-.358,1.243,1.243,0,0,0,.422-1,1.161,1.161,0,0,0-.422-.973,1.768,1.768,0,0,0-1.12-.331h-4.7V190.8Z" transform="translate(-197.747 -149.765)" />
                <path id="Path_31694" className="secondary-col" data-name="Path 31694" d="M342.673,199.058h3.892v5.14a2.44,2.44,0,0,0,.514,1.772,2.06,2.06,0,0,0,1.505.5,2.579,2.579,0,0,0,1.735-.587,2.236,2.236,0,0,0,.689-1.8v-5.031H354.9v9.951h-3.783v-1.285h-.037a4.353,4.353,0,0,1-1.643,1.212,6.144,6.144,0,0,1-2.543.477,6.307,6.307,0,0,1-1.67-.211,3.556,3.556,0,0,1-1.34-.679,3.123,3.123,0,0,1-.891-1.24,4.916,4.916,0,0,1-.321-1.891Z" transform="translate(-284.293 -165.145)" />
                <path id="Path_31695" className="secondary-col" data-name="Path 31695" d="M424.27,210.037h.937a4.262,4.262,0,0,0,.826-.064,1.811,1.811,0,0,0,.5-.165.925.925,0,0,0,.284-.23,2.177,2.177,0,0,0,.175-.257l.441-.881-4.682-9.382h4.223l2.515,5.912,2.423-5.912h4.113l-5.288,11.2a9.85,9.85,0,0,1-.551,1,2.784,2.784,0,0,1-.679.735,2.865,2.865,0,0,1-1,.459,6.059,6.059,0,0,1-1.5.156q-.772,0-1.469-.037t-1.267-.073Z" transform="translate(-350.724 -165.145)" />
                <path id="Path_31696" className="secondary-col" data-name="Path 31696" d="M510.352,203.937a1.248,1.248,0,0,0,.165.487,1.042,1.042,0,0,0,.386.358,2.249,2.249,0,0,0,.67.229,5.527,5.527,0,0,0,1.056.083,5.453,5.453,0,0,0,.642-.037,2.293,2.293,0,0,0,.569-.138,1.116,1.116,0,0,0,.413-.266.616.616,0,0,0,.156-.44.553.553,0,0,0-.312-.551,3.747,3.747,0,0,0-1.029-.239L509.6,203a5.27,5.27,0,0,1-2.579-.854,2.265,2.265,0,0,1-.853-1.919,3.14,3.14,0,0,1,.422-1.689,3.063,3.063,0,0,1,1.2-1.092,6.034,6.034,0,0,1,1.9-.588,16.472,16.472,0,0,1,2.515-.175,18.514,18.514,0,0,1,2.057.119,7.45,7.45,0,0,1,1.955.477,4.155,4.155,0,0,1,1.5,1,2.751,2.751,0,0,1,.7,1.707h-4.37a.994.994,0,0,0-.7-.844,2.392,2.392,0,0,0-.661-.175,5.97,5.97,0,0,0-.734-.045,3.276,3.276,0,0,0-1.313.211.738.738,0,0,0-.468.725.523.523,0,0,0,.248.422,2.315,2.315,0,0,0,1,.275l3.36.368a6.615,6.615,0,0,1,3.011.909,2.443,2.443,0,0,1,.991,2.157,2.761,2.761,0,0,1-1.616,2.617,10.661,10.661,0,0,1-4.755.835,19.626,19.626,0,0,1-2.176-.128,8.61,8.61,0,0,1-2.111-.5,4.2,4.2,0,0,1-1.607-1.056,2.7,2.7,0,0,1-.679-1.827Z" transform="translate(-419.659 -163.176)" />
                <path id="Path_31697" className="primary-col" data-name="Path 31697" d="M30.42,82.8H24.637a2.887,2.887,0,0,1-.963.881,13.62,13.62,0,0,1-1.772.846,3.058,3.058,0,0,1-2.618.841,5.852,5.852,0,0,1-.757.033,11.327,11.327,0,0,1-2.591,0,7.13,7.13,0,0,1-3.955-1.148,3.34,3.34,0,0,1-.922-.928c-.038-.033-.08-.059-.118-.093a3.174,3.174,0,0,1-.4-.43H5.314A5.321,5.321,0,0,0,0,88.114v19.243a5.321,5.321,0,0,0,5.314,5.314H30.42a5.321,5.321,0,0,0,5.314-5.314V88.114A5.321,5.321,0,0,0,30.42,82.8m1.567,24.557a1.569,1.569,0,0,1-1.567,1.567H5.314a1.569,1.569,0,0,1-1.567-1.567V88.114a1.569,1.569,0,0,1,1.567-1.567H30.42a1.569,1.569,0,0,1,1.567,1.567Z" transform="translate(0 -68.694)" />
                <path id="Path_31698" className="secondary-col" data-name="Path 31698" d="M59.438,141.773c.026.272.042.533.042.785,0,4.9-4.286,8.878-9.554,8.878s-9.554-3.982-9.554-8.878c0-.252.016-.513.043-.785H38.679c-.022.268-.037.529-.037.785,0,5.782,5.062,10.485,11.284,10.485s11.284-4.7,11.284-10.485c0-.255-.015-.517-.037-.785Z" transform="translate(-32.059 -117.62)" />
                <path id="Path_31699" className="secondary-col" data-name="Path 31699" d="M61.743,17.853a10.907,10.907,0,0,0,2.588-7.072,10.481,10.481,0,1,0-20.954,0,10.907,10.907,0,0,0,2.588,7.072ZM53.854,5.187a5.453,5.453,0,0,1,5.289,5.593,5.3,5.3,0,1,1-10.578,0,5.454,5.454,0,0,1,5.289-5.593" transform="translate(-35.987 0.001)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
};

export default LogoInner

