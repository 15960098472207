
const FlagIc = (props: any) => {
  return (
    <svg id="icn_flag" xmlns="http://www.w3.org/2000/svg" width="18.766" height="24" viewBox="0 0 18.766 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_611" data-name="Rectangle 611" width="18.766" height="24" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </clipPath>
      </defs>
      <g id="Group_1483" data-name="Group 1483" clip-path="url(#clip-path)">
        <line id="Line_166" data-name="Line 166" y2="22" transform="translate(1 1)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
        <path id="Path_31550" data-name="Path 31550" d="M17,13H4V3H17L14,8Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
      </g>
    </svg>
  )
};

export default FlagIc;

