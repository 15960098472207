/**
 * @file   src\store\slices\authSlices.ts
 * @brief  This file is responsible for creating auth slices to call actions and state management.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';

import {
  login,
  signup,
  sendcode,
  verifyCode,
  forgotPassword,
  signoutUser,
  deleteUser,
  applelogin,
  getProfile,
  editProfile,
  fbLogin,
  resetPassword,
  purchaseHistory,
  changePassword,
  locationListing,
  _forgotEmail
} from '../actions/authAction';
import { clearNtfn, ntfnCheck, ntnList, ntfnRead } from '../actions/ntfnAction';
import {
  Iauth,
  IappleLogin,
  IcheckNtfn,
  IclearNtfn,
  IchangePassword,
  IdeleteUser,
  IfbLogin,
  Iforgot,
  IlocationListing,
  Iprofile,
  IpurchaseHistory,
  IrequestOTP,
  Isignout,
  Isignup,
  IverifyOTP,
  IresetPassword,
  IForgotEmail
} from '../../interfaces/authInterface';
import { Intfn } from '../../interfaces/GeneralInterface';

// Initial state.
const authState: Iauth = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const verifyOTPState: IverifyOTP = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const requestOTPState: IrequestOTP = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const signupState: Isignup = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

// Initial state.
const forgotState: Iforgot = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const signoutState: Isignout = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const deleteUserState: IdeleteUser = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const clearNtfnState: IclearNtfn = {
  ntfnInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const checkNtfnState: IcheckNtfn = {
  ntfnInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const profileState: Iprofile = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const appleLoginState: IappleLogin = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const NtfnListState: Intfn = {
  ntfnInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const profileEditState: Iprofile = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const NtfnReadState: Intfn = {
  ntfnInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const fbLoginState: IfbLogin = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const resetPasswordState: IresetPassword = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const purchaseHistoryState: IpurchaseHistory = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const locationListingState: IlocationListing = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const changePasswordState: IchangePassword = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const forgotEmailState: IForgotEmail = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

// Config user slice.
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    Login: authState,
    verifyOTP: verifyOTPState,
    sendOTP: requestOTPState,
    signup: signupState,
    ForgotPassword: forgotState,
    signout: signoutState,
    deleteuser: deleteUserState,
    appleLogin: appleLoginState,
    clearntfn: clearNtfnState,
    ntfncheck: checkNtfnState,
    profile: profileState,
    ntfnlist: NtfnListState,
    profileEdit: profileEditState,
    ntfnread: NtfnReadState,
    fbLogin: fbLoginState,
    resetpassword: resetPasswordState,
    changepassword: changePasswordState,
    purchaseHistory: purchaseHistoryState,
    locationListing: locationListingState,
    forgotEmail: forgotEmailState
  },
  reducers: {
    resetAuthState: (state) => {
      state.Login = authState;
    },
    resetForgotPasswordState: (state) => {
      state.ForgotPassword = forgotState;
    },
    resetVerifyOtpState: (state) => {
      state.verifyOTP = verifyOTPState;
    },
    resetSendOTPState: (state) => {
      state.sendOTP = requestOTPState;
    },
    resetSignupState: (state) => {
      state.signup = signupState;
    },
    resetSignoutState: (state) => {
      state.signout = signoutState;
    },
    resetDeleteUserState: (state) => {
      state.deleteuser = deleteUserState;
    },
    resetClearNtfnState: (state) => {
      state.clearntfn = clearNtfnState;
    },
    resetNtfnCheckState: (state) => {
      state.ntfncheck = checkNtfnState;
    },
    resetProfileState: (state) => {
      state.profile = profileState;
    },
    resetAppleLogin: (state) => {
      state.appleLogin = appleLoginState;
    },
    resetNtfnListState: (state) => {
      state.ntfnlist = NtfnListState;
    },
    resetProfileEditState: (state) => {
      state.profileEdit = profileEditState;
    },
    resetNtfnReadState: (state) => {
      state.ntfnread = NtfnReadState;
    },
    resetfbLoginState: (state) => {
      state.fbLogin = fbLoginState;
    },
    resetPasswordResponseState: (state) => {
      state.resetpassword = resetPasswordState;
    },
    resetPurchaseHistoryState: (state) => {
      state.purchaseHistory = purchaseHistoryState;
    },
    changePasswordResponseState: (state) => {
      state.changepassword = changePasswordState;
    },
    resetLocationListingStateState: (state) => {
      state.locationListing = locationListingState;
    },
    resetForgotEmailState: (state) => {
      state.forgotEmail = forgotEmailState;
    },
  },
  extraReducers(builder) {
    // Start validate user request.
    builder.addCase(login.pending, (state) => {
      state.Login.isSuccess = false;
      state.Login.isLoading = true;
      state.Login.errorCode = 0;
      state.Login.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(login.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.Login.isLoading = error === 0;
      state.Login.isSuccess = true;
      state.Login.userInfo = data;
      state.Login.errorCode = error;
      state.Login.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(login.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.Login.isSuccess = false;
      state.Login.isLoading = false;
      state.Login.errorCode = error;
      state.Login.errorMessage = 'Error';
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.ForgotPassword.isLoading = error === 0;
      state.ForgotPassword.isSuccess = true;
      state.ForgotPassword.userInfo = data;
      state.ForgotPassword.errorCode = error;
      state.ForgotPassword.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(forgotPassword.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.ForgotPassword.isSuccess = false;
      state.ForgotPassword.isLoading = false;
      state.ForgotPassword.errorCode = error;
      state.ForgotPassword.errorMessage = 'Error';
    });
    builder.addCase(signup.pending, (state) => {
      state.signup.isSuccess = false;
      state.signup.isLoading = true;
      state.signup.errorCode = 0;
      state.signup.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(signup.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.signup.isLoading = error === 0;
      state.signup.isSuccess = true;
      state.signup.userInfo = data;
      state.signup.errorCode = error;
      state.signup.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(signup.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.signup.isSuccess = false;
      state.signup.isLoading = false;
      state.signup.errorCode = error;
      state.signup.errorMessage = 'Error';
    });
    builder.addCase(sendcode.pending, (state) => {
      state.sendOTP.isSuccess = false;
      state.sendOTP.isLoading = true;
      state.sendOTP.errorCode = 0;
      state.sendOTP.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(sendcode.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.sendOTP.isLoading = error === 0;
      state.sendOTP.isSuccess = true;
      state.sendOTP.userInfo = data;
      state.sendOTP.errorCode = error;
      state.sendOTP.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(sendcode.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.sendOTP.isSuccess = false;
      state.sendOTP.isLoading = false;
      state.sendOTP.errorCode = error;
      state.sendOTP.errorMessage = 'Error';
    });
    builder.addCase(verifyCode.pending, (state) => {
      state.verifyOTP.isSuccess = false;
      state.verifyOTP.isLoading = true;
      state.verifyOTP.errorCode = 0;
      state.verifyOTP.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(verifyCode.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.verifyOTP.isLoading = false;
      state.verifyOTP.isSuccess = true;
      state.verifyOTP.userInfo = data;
      state.verifyOTP.errorCode = error;
      state.verifyOTP.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(verifyCode.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.verifyOTP.isSuccess = false;
      state.verifyOTP.isLoading = false;
      state.verifyOTP.errorCode = error;
      state.verifyOTP.errorMessage = 'Error';
    });
    builder.addCase(signoutUser.pending, (state) => {
      state.signout.isSuccess = false;
      state.signout.isLoading = true;
      state.signout.errorCode = 0;
      state.signout.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(signoutUser.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.signout.isLoading = false;
      state.signout.isSuccess = true;
      state.signout.userInfo = data;
      state.signout.errorCode = error;
      state.signout.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(signoutUser.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.signout.isSuccess = false;
      state.signout.isLoading = false;
      state.signout.errorCode = error;
      state.signout.errorMessage = 'Error';
    });
    builder.addCase(deleteUser.pending, (state) => {
      state.deleteuser.isSuccess = false;
      state.deleteuser.isLoading = true;
      state.deleteuser.errorCode = 0;
      state.deleteuser.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.deleteuser.isLoading = false;
      state.deleteuser.isSuccess = true;
      state.deleteuser.userInfo = data;
      state.deleteuser.errorCode = error;
      state.deleteuser.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(deleteUser.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.deleteuser.isSuccess = false;
      state.deleteuser.isLoading = false;
      state.deleteuser.errorCode = error;
      state.deleteuser.errorMessage = 'Error';
    });
    builder.addCase(clearNtfn.pending, (state) => {
      state.clearntfn.isSuccess = false;
      state.clearntfn.isLoading = true;
      state.clearntfn.errorCode = 0;
      state.clearntfn.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(clearNtfn.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.clearntfn.isLoading = false;
      state.clearntfn.isSuccess = true;
      state.clearntfn.ntfnInfo = data;
      state.clearntfn.errorCode = error;
      state.clearntfn.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(clearNtfn.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.clearntfn.isSuccess = false;
      state.clearntfn.isLoading = false;
      state.clearntfn.errorCode = error;
      state.clearntfn.errorMessage = 'Error';
    });
    builder.addCase(ntfnCheck.pending, (state) => {
      state.ntfncheck.isSuccess = false;
      state.ntfncheck.isLoading = true;
      state.ntfncheck.errorCode = 0;
      state.ntfncheck.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(ntfnCheck.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.ntfncheck.isLoading = false;
      state.ntfncheck.isSuccess = true;
      state.ntfncheck.ntfnInfo = data;
      state.ntfncheck.errorCode = error;
      state.ntfncheck.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(ntfnCheck.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.ntfncheck.isSuccess = false;
      state.ntfncheck.isLoading = false;
      state.ntfncheck.errorCode = error;
      state.ntfncheck.errorMessage = 'Error';
    });

    //profile
    builder.addCase(getProfile.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.profile.isSuccess = false;
      state.profile.isLoading = false;
      state.profile.errorCode = error;
      state.profile.errorMessage = 'Error';
    });
    builder.addCase(getProfile.pending, (state) => {
      state.profile.isSuccess = false;
      state.profile.isLoading = true;
      state.profile.errorCode = 0;
      state.profile.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(getProfile.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.profile.isLoading = false;
      state.profile.isSuccess = true;
      state.profile.userInfo = data;
      state.profile.errorCode = error;
      state.profile.errorMessage = action.payload?.message || '';
    });
    builder.addCase(applelogin.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.appleLogin.isLoading = false;
      state.appleLogin.isSuccess = true;
      state.appleLogin.userInfo = data;
      state.appleLogin.errorCode = error;
      state.appleLogin.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(applelogin.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.appleLogin.isSuccess = false;
      state.appleLogin.isLoading = false;
      state.appleLogin.errorCode = error;
      state.appleLogin.errorMessage = 'Error';
    });
    builder.addCase(ntnList.pending, (state) => {
      state.ntfnlist.isSuccess = false;
      state.ntfnlist.isLoading = true;
      state.ntfnlist.errorCode = 0;
      state.ntfnlist.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(ntnList.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.ntfnlist.isLoading = false;
      state.ntfnlist.isSuccess = true;
      state.ntfnlist.ntfnInfo = data;
      state.ntfnlist.errorCode = error;
      state.ntfnlist.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(ntnList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.ntfnlist.isSuccess = false;
      state.ntfnlist.isLoading = false;
      state.ntfnlist.errorCode = error;
      state.ntfnlist.errorMessage = 'Error';
    });

    builder.addCase(ntfnRead.pending, (state) => {
      state.ntfnread.isSuccess = false;
      state.ntfnread.isLoading = true;
      state.ntfnread.errorCode = 0;
      state.ntfnread.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(ntfnRead.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.ntfnread.isLoading = false;
      state.ntfnread.isSuccess = true;
      state.ntfnread.ntfnInfo = data;
      state.ntfnread.errorCode = error;
      state.ntfnread.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(ntfnRead.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.ntfnread.isSuccess = false;
      state.ntfnread.isLoading = false;
      state.ntfnread.errorCode = error;
      state.ntfnread.errorMessage = 'Error';
    });

    //profile edit
    builder.addCase(editProfile.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.profileEdit.isSuccess = false;
      state.profileEdit.isLoading = false;
      state.profileEdit.errorCode = error;
      state.profileEdit.errorMessage = 'Error';
    });
    builder.addCase(editProfile.pending, (state) => {
      state.profileEdit.isSuccess = false;
      state.profileEdit.isLoading = true;
      state.profileEdit.errorCode = 0;
      state.profileEdit.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(editProfile.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.profileEdit.isLoading = false;
      state.profileEdit.isSuccess = true;
      state.profileEdit.userInfo = data;
      state.profileEdit.errorCode = error;
      state.profileEdit.errorMessage = action.payload?.message || '';
    });


    //FB LOGIN
    builder.addCase(fbLogin.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.fbLogin.isSuccess = false;
      state.fbLogin.isLoading = false;
      state.fbLogin.errorCode = error;
      state.fbLogin.errorMessage = 'Error';
    });
    builder.addCase(fbLogin.pending, (state) => {
      state.fbLogin.isSuccess = false;
      state.fbLogin.isLoading = true;
      state.fbLogin.errorCode = 0;
      state.fbLogin.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(fbLogin.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.fbLogin.isLoading = false;
      state.fbLogin.isSuccess = true;
      state.fbLogin.userInfo = data;
      state.fbLogin.errorCode = error;
      state.fbLogin.errorMessage = action.payload?.message || '';
    });
    builder.addCase(resetPassword.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.resetpassword.isSuccess = false;
      state.resetpassword.isLoading = false;
      state.resetpassword.errorCode = error;
      state.resetpassword.errorMessage = 'Error';
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.resetpassword.isSuccess = false;
      state.resetpassword.isLoading = true;
      state.resetpassword.errorCode = 0;
      state.resetpassword.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.resetpassword.isLoading = false;
      state.resetpassword.isSuccess = true;
      state.resetpassword.userInfo = data;
      state.resetpassword.errorCode = error;
      state.resetpassword.errorMessage = action.payload?.message || '';
    });
    builder.addCase(purchaseHistory.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.purchaseHistory.isSuccess = false;
      state.purchaseHistory.isLoading = false;
      state.purchaseHistory.errorCode = error;
      state.purchaseHistory.errorMessage = 'Error';
    });
    builder.addCase(purchaseHistory.pending, (state) => {
      state.purchaseHistory.isSuccess = false;
      state.purchaseHistory.isLoading = true;
      state.purchaseHistory.errorCode = 0;
      state.purchaseHistory.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(purchaseHistory.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.purchaseHistory.isLoading = false;
      state.purchaseHistory.isSuccess = true;
      state.purchaseHistory.userInfo = data;
      state.purchaseHistory.errorCode = error;
      state.purchaseHistory.errorMessage = action.payload?.message || '';
    });
    builder.addCase(changePassword.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.changepassword.isSuccess = false;
      state.changepassword.isLoading = false;
      state.changepassword.errorCode = error;
      state.changepassword.errorMessage = 'Error';
    });
    builder.addCase(changePassword.pending, (state) => {
      state.changepassword.isSuccess = false;
      state.changepassword.isLoading = true;
      state.changepassword.errorCode = 0;
      state.changepassword.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(changePassword.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.changepassword.isLoading = false;
      state.changepassword.isSuccess = true;
      state.changepassword.userInfo = data;
      state.changepassword.errorCode = error;
      state.changepassword.errorMessage = action.payload?.message || '';
    });


    builder.addCase(locationListing.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.locationListing.isSuccess = false;
      state.locationListing.isLoading = false;
      state.locationListing.errorCode = error;
      state.locationListing.errorMessage = 'Error';
    });
    builder.addCase(locationListing.pending, (state) => {
      state.locationListing.isSuccess = false;
      state.locationListing.isLoading = true;
      state.locationListing.errorCode = 0;
      state.locationListing.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(locationListing.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.locationListing.isLoading = false;
      state.locationListing.isSuccess = true;
      state.locationListing.userInfo = data;
      state.locationListing.errorCode = error;
      state.locationListing.errorMessage = action.payload?.message || '';
    });


    builder.addCase(_forgotEmail.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.forgotEmail.isSuccess = false;
      state.forgotEmail.isLoading = false;
      state.forgotEmail.errorCode = error;
      state.forgotEmail.errorMessage = 'Error';
    });
    builder.addCase(_forgotEmail.pending, (state) => {
      state.forgotEmail.isSuccess = false;
      state.forgotEmail.isLoading = true;
      state.forgotEmail.errorCode = 0;
      state.forgotEmail.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(_forgotEmail.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.forgotEmail.isLoading = false;
      state.forgotEmail.isSuccess = true;
      state.forgotEmail.userInfo = data;
      state.forgotEmail.errorCode = error;
      state.forgotEmail.errorMessage = action.payload?.message || '';
    });

  },

});

// Export auth actions.
export const { resetAuthState, resetVerifyOtpState, resetSendOTPState, resetSignupState, resetForgotPasswordState, resetSignoutState, resetDeleteUserState, resetClearNtfnState, resetNtfnCheckState, resetNtfnListState, resetNtfnReadState, resetAppleLogin, resetProfileState, resetfbLoginState, resetPasswordResponseState, resetPurchaseHistoryState, changePasswordResponseState, resetLocationListingStateState, resetForgotEmailState } = authSlice.actions;
// Export reducer.
export default authSlice.reducer;
