
const CatProducts = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_589" data-name="Rectangle 589" width="20" height="20" transform="translate(0.065 0.065)" fill="#1e90ff" />
        </clipPath>
      </defs>
      <g id="Group_1633" data-name="Group 1633" transform="translate(-309 -1361)">
        <g id="icn_products" transform="translate(308.935 1360.935)">
          <g id="Group_1417" data-name="Group 1417" clip-path="url(#clip-path)">
            <path id="Path_31520" data-name="Path 31520" d="M15.394,4.194h6.458L17.071.168A1.555,1.555,0,0,0,16.484,0H13.8Z" transform="translate(-2.226)" fill="#1e90ff" />
            <path id="Path_31521" data-name="Path 31521" d="M13.42,7v5.871H6.71V7H0V20.42a.792.792,0,0,0,.839.839H19.291a.792.792,0,0,0,.839-.839V7Z" transform="translate(0 -1.129)" fill="#1e90ff" />
            <path id="Path_31522" data-name="Path 31522" d="M7.058,4.194,8.652,0H5.968a.9.9,0,0,0-.5.168L.6,4.194Z" transform="translate(-0.097)" fill="#1e90ff" />
          </g>
        </g>
      </g>
    </svg>
  )
};

export default CatProducts;

