/**
 * @file   src\utils\constants.ts
 * @brief  This file is responsible define constants.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

export const STATUS_401 = 401;
export const STATUS_0 = 0;
export const STATUS_1 = 1;
export const STATUS_2 = 2;
export const STATUS_3 = 3;
export const STATUS_10 = 10;
export const STATUS_50 = 50;
export const STATUS_500 = 500;
export const API_TIMEOUT = 30000;
export const API_MAXCONTENTLENGTH = 1000;
export const API_REDIRECTS = 5;
export const API_SUCCESSCODE = 1000; // 1000
export const API_ERRCODE = 1001; // 1001
export const API_AUTH_TOKEN_ERROR = 1002; // 1002
export const API_BLOCKED_USER = 1003; // 1003
export const API_LOGOUT_USER = 1004; // 1004
export const EFFECTIVE_DATE = '01-17-2024';
export const ZIPCODE_LENGTH = 5;
export const STATUS_NULL = null;
export const STRINGEMPTY = 'NA';
export const ACCEPTED = 'Accepted';
export const NOTYETSCANNED = 'Not Yet Scanned';
export const INTRANSIT = 'In Transit';
export const DELIVERED = 'Delivered';
export const PRESHIPPING = 'Pre-Shipping';
export const MAX_PRICE = 5000;
export const MIN_PRICE = 1;
export const DESCRIPTION_LENGTH = 500;
export const EMAIL_LENGTH = 50;
