/**
 * @file   src\components\ForgotPass.tsx
 * @brief  ForgotPass Component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Strings from '../assets/strings/Strings.json';
import NBInputGroup from '../components/NBInputGroup';
import NBPhoneInput from '../components/NBPhoneInputGroup';

const ForgotMailForm = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <h1 className="pt-3 pb-4">{Strings.ForgotEmail.Title}</h1>
      <p className="mb-4">
        {Strings.ForgotEmail.InfoTxt1}
        <br />
        {Strings.ForgotEmail.InfoTxt2}
      </p>
      <NBPhoneInput
        id="phone"
        name="phone_number"
        type="number"
        maxLength="14"
        label={Strings.ForgotEmail.Label}
        onChange={props.onChange}
        value={props.value}
        error={props.phoneError}  
      />
      <Button variant="primary" disabled={props.disabled} onClick={props.handleSubmit} className="w-100">
        {Strings.ForgotEmail.Btn}
      </Button>
      <Button style={{marginTop:13}} onClick={() => {navigate('/signin')}} variant='secondary' className='w-100'>{Strings.FrogotPassword.Button1}</Button>

    </>
  );
};

export default ForgotMailForm;
