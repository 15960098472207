
const WebIc = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g id="icn_web" transform="translate(-225.648 -259.648)">
        <circle id="Ellipse_136" data-name="Ellipse 136" cx="10" cy="10" r="10" transform="translate(226.648 260.648)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
        <ellipse id="Ellipse_137" data-name="Ellipse 137" cx="4" cy="10" rx="4" ry="10" transform="translate(232.648 260.648)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
        <path id="Path_31490" data-name="Path 31490" d="M6.365,37.711A14.171,14.171,0,0,1,13.551,36a14.275,14.275,0,0,1,7.115,1.668" transform="translate(223.089 238.898)" fill="none" stroke-miterlimit="10" stroke-width="2" />
        <path id="Path_31491" data-name="Path 31491" d="M20.85,9.563a14.175,14.175,0,0,1-7.186,1.711A14.275,14.275,0,0,1,6.549,9.606" transform="translate(222.977 255.14)" fill="none" stroke-miterlimit="10" stroke-width="2" />
        <line id="Line_151" data-name="Line 151" x2="20" transform="translate(226.648 270.648)" fill="none" stroke-miterlimit="10" stroke-width="2" />
        <line id="Line_152" data-name="Line 152" y2="20" transform="translate(236.648 260.648)" fill="none" stroke-miterlimit="10" stroke-width="2" />
      </g>
    </svg>
  )
};

export default WebIc;

