/**
 * @file   src\store\actions\authAction.ts
 * @brief  This file is responsible for generating asynchronous api call.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { ProductApi } from '../../utils/apiUrls';

// Encrypt Header
const headers: any = {};
headers['Access-Control-Allow-Origin'] = '*';
headers['Access-Control-Allow-Headers'] = '*';
headers['Access-Control-Allow-Credentials'] = true;
headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, PATCH, DELETE';
headers['Content-Type'] = 'text/plain';
headers.Accept = 'text/plain';
headers['Cache-Control'] = 'no-cache';
headers['api_key'] = process.env.REACT_APP_API_KEY;

export const vendorProducts = createAsyncThunk('/auth/clear', async (payload: any, { rejectWithValue }) => {
    try {
        const headers = {'baseURL': process.env.REACT_APP_API_URL_VENDOR };
        const { data } = await axios.post(ProductApi.VENDORPRODUCTS, payload, { headers });
        return data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data);
    }
});