/**
 * @file   src\containers\About.tsx
 * @brief  About page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Container } from 'react-bootstrap';
import Strings from '../assets/strings/Strings.json';
import AppsAd from '../components/AppsAd';

const PrivacyPolicy = () => {
  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container className='d-flex align-items-middle justify-content-between'>
          <h2>{Strings.Privacy.Title}</h2>
        </Container>
      </Container>
      <Container fluid>
        <Container className='content-area'>
          <h3>Lorem ipsum dolor sit</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam viverra enim quis eleifend molestie. Mauris at orci nec leo euismod ultrices a et magna. Praesent venenatis libero dictum massa auctor, sed pharetra neque semper. Suspendisse viverra suscipit mauris, id consequat purus venenatis acIn egestas mauris eget pretium porta. Pellentesque a vestibulum lectus. Nam dapibus risus nisl, quis euismod leo fringilla eu.</p>
          <p>Sed in magna sit amet eros dignissim tempor a non velit. Nulla aliquam libero eu ligula suscipit, ut venenatis nisl dapibus. Aenean a velit mi. Phasellus at blandit enim. Nunc ullamcorper, augue sit amet aliquam auctor, dui arcu lobortis arcu, id maximus odio metus non lorem. Morbi mi ante, consectetur et nisi ut, euismod volutpat quam. Etiam condimentum lectus quis nisi pulvinar, in varius magna placerat. Nulla pretium, massa vel feugiat commodo, orci tellus tempor nibh, nec venenatis est tortor quis urna. Quisque mi lacus, mattis a ullamcorper vitae, sagittis quis tortor. Curabitur ultricies iaculis tortor, interdum auctor felis condimentum ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur felis mauris, eleifend et nulla non, pretium posuere dui.</p>
          <AppsAd />
        </Container>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
