/**
 * @file   src\components\VendorMenu.tsx
 * @brief  VendorMenu component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { NavLink } from 'react-router-dom';
import './Header.scss';
import Strings from '../assets/strings/Strings.json';

const VendorMenu = () => {

  const vendorSiteLink :any =process.env.REACT_APP_VENDOR_SITE_URL

  const redirectToExternalLink = () => { 
    window.open(vendorSiteLink, '_blank');
  };
  return (
    <div className='vendor-menu'>
      <NavLink  onClick={redirectToExternalLink} to='' className='d-block'>Sign In as a Vendor</NavLink>
    </div>
  );
};

export default VendorMenu;
