import CryptoJS from 'crypto-js';

const encryptData = async (plainText: any) => {
  try {
    let Key = CryptoJS.enc.Utf8.parse('9&3u$c{FfqZQ95S9tq4.zsL)%6@QUR8f');
    let IV = CryptoJS.enc.Utf8.parse('KWrSkUm5ZwWW(?Xz');
    let encryptedText = CryptoJS.AES.encrypt(JSON.stringify(plainText), Key, {
      // 4. Use decrypt instead of encrypt
      iv: IV,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding,
    });
    const encryptedData = encryptedText.toString(CryptoJS.format.Base64);
    return encryptedData;
  } catch (error) {
    console.error('Error encrypting data:', error);
    throw error;
  }
};

const decryptData = async (encryptedData: any) => {
  try {
    let Key = CryptoJS.enc.Utf8.parse('9&3u$c{FfqZQ95S9tq4.zsL)%6@QUR8f');
    let IV = CryptoJS.enc.Utf8.parse('KWrSkUm5ZwWW(?Xz');
    let decryptedText = CryptoJS.AES.decrypt(encryptedData, Key, {
      iv: IV,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding,
    });
    const decryptedData = decryptedText.toString(CryptoJS.enc.Utf8);
    const decryptedJSON = JSON.parse(decryptedData);
    return decryptedJSON;
  } catch (error) {
    console.error('Error decrypting data:', error);
    throw error;
  }
};

export { decryptData, encryptData };
