/**
 * @file   src\components\ForgotPass.tsx
 * @brief  ForgotPass Component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Strings from '../assets/strings/Strings.json';
import NBInputGroup from '../components/NBInputGroup';

const ForgotPswd = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <h1 className='pt-3 pb-4'>{Strings.FrogotPassword.Title}</h1>
      <p className='mb-4'>{Strings.FrogotPassword.InfoTxt1}<br />{Strings.FrogotPassword.InfoTxt2}</p>
      <NBInputGroup id='email' name='email' type='email' label={Strings.FrogotPassword.Label} onChange={props.handleEmailChanges} value={props.email} error={props.emailError}/>
      <Button variant='primary' onClick={props.handleSubmit} className='w-100'>{Strings.FrogotPassword.Button}</Button>
      <br />
      <Button style={{marginTop:13}} onClick={() => {navigate('/signin')}} variant='secondary' className='w-100'>{Strings.FrogotPassword.Button1}</Button>

    </>
  );
};

export default ForgotPswd;
