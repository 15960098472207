
const ICForgotMail = (props: any) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="263" height="263" viewBox="0 0 263 263">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_515" data-name="Rectangle 515" width="10.262" height="10.262" fill="none" stroke="#94da0a" stroke-width="1"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect id="Rectangle_516" data-name="Rectangle 516" width="9.597" height="9.597" fill="none" stroke="#94da0a" stroke-width="1"/>
    </clipPath>
  </defs>
  <g id="icn_forgot_email" transform="translate(-349 -409)">
    <g id="icn_forgot_password" transform="translate(251.1 286.1)">
      <circle id="Ellipse_83" data-name="Ellipse 83" cx="131.5" cy="131.5" r="131.5" transform="translate(97.9 122.9)" fill="#fff"/>
    </g>
    <g id="icn_email_verify" transform="translate(284.601 315.377)">
      <g id="forgot_bg" transform="translate(122.469 151.311)" opacity="0.5">
        <g id="alarm_bg">
          <g id="Group_559" data-name="Group 559" transform="translate(0 0)">
            <g id="Group_339" data-name="Group 339" transform="translate(0 49.005)">
              <g id="Group_338" data-name="Group 338">
                <g id="Group_337" data-name="Group 337" clip-path="url(#clip-path)">
                  <line id="Line_133" data-name="Line 133" x2="8.698" transform="translate(0.782 5.131)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.918"/>
                  <line id="Line_134" data-name="Line 134" y1="8.698" transform="translate(5.131 0.782)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.918"/>
                </g>
              </g>
            </g>
            <g id="Group_342" data-name="Group 342" transform="translate(114.576 0)">
              <g id="Group_341" data-name="Group 341">
                <g id="Group_340" data-name="Group 340" clip-path="url(#clip-path-2)">
                  <line id="Line_135" data-name="Line 135" x2="7.339" transform="translate(1.129 4.799)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.77"/>
                  <line id="Line_136" data-name="Line 136" y1="7.339" transform="translate(4.799 1.129)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.77"/>
                </g>
              </g>
            </g>
            <g id="Group_551" data-name="Group 551" transform="translate(124.174 132.543)">
              <line id="Line_137" data-name="Line 137" x2="5.171" transform="translate(0 2.586)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.952"/>
              <line id="Line_138" data-name="Line 138" y1="5.171" transform="translate(2.585)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.952"/>
            </g>
            <path id="Path_31467" data-name="Path 31467" d="M212.3,30.661a2.9,2.9,0,1,1-2.9-2.9A2.9,2.9,0,0,1,212.3,30.661Z" transform="translate(-165.98 -5.131)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.609"/>
            <circle id="Ellipse_113" data-name="Ellipse 113" cx="4.255" cy="4.255" r="4.255" transform="translate(137.349 54.136)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364" opacity="0.5"/>
            <circle id="Ellipse_114" data-name="Ellipse 114" cx="3.364" cy="3.364" r="3.364" transform="translate(65.766 139.898)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <circle id="Ellipse_115" data-name="Ellipse 115" cx="3.364" cy="3.364" r="3.364" transform="translate(10.262 108.655)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </g>
        </g>
      </g>
      <g id="Group_667" data-name="Group 667" transform="translate(163.28 194.21)">
        <g id="Group_666" data-name="Group 666" transform="translate(2.6 2.62)">
          <g id="Group_665" data-name="Group 665" transform="translate(0)">
            <path id="Path_52" data-name="Path 52" d="M4466.3,1218.3l-33.6-19.7-33.593,19.7a2.314,2.314,0,0,0-2.315,2.315V1260a2.317,2.317,0,0,0,2.315,2.318H4466.3a2.317,2.317,0,0,0,2.315-2.318V1220.61A2.314,2.314,0,0,0,4466.3,1218.3Zm-5,6.949c-10.5,7.366-28.593,20.851-28.593,20.851v.037c-6.454-4.774-20.2-14.914-28.683-20.888-.094-.067-.187-.133-.281-.2v-2.122a2.318,2.318,0,0,1,2.319-2.315h53.286a2.315,2.315,0,0,1,2.318,2.315v2.062Z" transform="translate(-4396.79 -1198.6)" fill="#94da0a" opacity="0.3"/>
            <path id="Path_53" data-name="Path 53" d="M4468.611,1264.581v39.387a2.318,2.318,0,0,1-2.315,2.319h-67.191a2.317,2.317,0,0,1-2.315-2.319v-39.387c0-.323,2.818,1.539,6.949,4.437.094.063.187.13.281.2,8.488,5.973,22.229,16.114,28.683,20.888v-.037s18.092-13.486,28.593-20.851l.367-.256C4465.759,1266.087,4468.611,1264.222,4468.611,1264.581Z" transform="translate(-4396.79 -1242.571)" fill="#94da0a" opacity="0.3"/>
            <path id="Path_54" data-name="Path 54" d="M4475.572,1266.985v2.062l-.366.256c-10.5,7.366-28.594,20.851-28.594,20.851v.036c-6.453-4.774-20.195-14.915-28.683-20.888-.094-.067-.187-.133-.28-.2v-2.122a2.317,2.317,0,0,1,2.318-2.315h53.286A2.314,2.314,0,0,1,4475.572,1266.985Z" transform="translate(-4410.701 -1242.66)" fill="#94da0a"/>
            <path id="Path_55" data-name="Path 55" d="M4417.65,1277.99c.093.063.186.13.28.2h-.28Z" transform="translate(-4410.701 -1251.542)" fill="#94da0a"/>
            <path id="Path_56" data-name="Path 56" d="M4590.786,1277.81v.256h-.366Z" transform="translate(-4525.915 -1251.422)" fill="#94da0a"/>
          </g>
        </g>
        <g id="Group_664" data-name="Group 664">
          <path id="Path_49" data-name="Path 49" d="M4133.234,1260a2.317,2.317,0,0,0,2.316,2.317h67.19a2.317,2.317,0,0,0,2.316-2.317V1220.61a2.317,2.317,0,0,0-2.316-2.317l-33.6-19.693-33.594,19.693a2.317,2.317,0,0,0-2.316,2.317Z" transform="translate(-4133.234 -1198.6)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <path id="Path_50" data-name="Path 50" d="M4169.146,1290.1c-9.706-7.185-35.913-26.51-35.913-25.523v39.387a2.317,2.317,0,0,0,2.316,2.317h67.19a2.317,2.317,0,0,0,2.316-2.317v-39.387c0-1.279-35.911,25.486-35.911,25.486" transform="translate(-4133.234 -1242.57)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <line id="Line_15" data-name="Line 15" x1="27.803" y1="19.694" transform="translate(42.862 42.863)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <line id="Line_16" data-name="Line 16" y1="19.694" x2="27.803" transform="translate(1.158 42.863)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <path id="Path_51" data-name="Path 51" d="M4154.1,1269.3v-2.317a2.317,2.317,0,0,1,2.316-2.317H4209.7a2.317,2.317,0,0,1,2.317,2.317v2.317" transform="translate(-4147.147 -1242.66)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <line id="Line_17" data-name="Line 17" x2="8.109" transform="translate(26.177 28.828)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <line id="Line_18" data-name="Line 18" x2="19.58" transform="translate(26.177 33.462)" fill="none" stroke="#94da0a" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </g>
      </g>
    </g>
  </g>
</svg>

  )
};

export default ICForgotMail;

