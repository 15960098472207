/**
 * @file   src\store\slices\productSlices.ts
 * @brief  This file is responsible for creating auth slices to call actions and state management.
 * @date   Oct, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import {
  productDetails,
  refundDetails,
  requestRefund,
  reportProduct,
  reportOptions,
  productLock,
  productMakeOrder,
  createCustomer,
  categoryList,
  productMessages,
  productMessageHistory,
  productMessageSend,
  productApplyCoupon,
  shippingAddress,
} from '../actions/productAction';
import {
  IPrdt,
  IproductDetail,
  IrequestRefund,
  IlockProduct,
  IvendorPrdts,
  ImakeOrderProduct,
  IcreateCustomer,
  IreportReasons,
  IcategoryList,
  IquestionList,
  IproductMessage,
  IproductMessageSend,
  IproductApplyCoupon,
  IShippingAddress,
} from '../../interfaces/GeneralInterface';
import { vendorProducts } from '../actions/vendorAction';

const productDetailState: IproductDetail = {
  userInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const refundDetailState: IPrdt = {
  prdtInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const requestRefundState: IrequestRefund = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const lockProductState: IlockProduct = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const vendorPrdtsState: IvendorPrdts = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const makeOrderProductState: ImakeOrderProduct = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const reportProductState: IreportReasons = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const createCustomerState: IcreateCustomer = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const reportReasonsState: IreportReasons = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const categoryListState: IcategoryList = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const questionListState: IquestionList = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const productMessageState: IproductMessage = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const productMessageSendState: IproductMessageSend = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};
const productApplyCouponState: IproductApplyCoupon = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

const shippingAddressState: IShippingAddress = {
  resultInfo: {},
  errorCode: 0,
  errorMessage: null,
  isSuccess: false,
  isLoading: false,
};

// Config user product.
export const productSlice = createSlice({
  name: 'product',
  initialState: {
    productDetails: productDetailState,
    refundDetails: refundDetailState,
    requestRefund: requestRefundState,
    lockProduct: lockProductState,
    makeOrderProduct: makeOrderProductState,
    createCustomer: createCustomerState,
    vendorProducts: vendorPrdtsState,
    reportproduct: reportProductState,
    reportReasons: reportReasonsState,
    categoryList: categoryListState,
    questionList: questionListState,
    productMessage: productMessageState,
    productMessageSend: productMessageSendState,
    productApplyCoupon: productApplyCouponState,
    shippingAddress:shippingAddressState,
  },
  reducers: {
    resetProductDetailState: (state) => {
      state.productDetails = productDetailState;
    },
    resetRefundDetailState: (state) => {
      state.refundDetails = refundDetailState;
    },
    resetRequestRefundState: (state) => {
      state.requestRefund = requestRefundState;
    },
    resetLockProductState: (state) => {
      state.lockProduct = lockProductState;
    },
    resetMakeOrderProductState: (state) => {
      state.makeOrderProduct = makeOrderProductState;
    },
    resetCustomerCreateState: (state) => {
      state.createCustomer = createCustomerState;
    },
    resetVendorPrdtsState: (state) => {
      state.vendorProducts = vendorPrdtsState;
    },
    resetreportProductState: (state) => {
      state.reportproduct = reportProductState;
    },
    resetReportReasonsState: (state) => {
      state.reportReasons = reportReasonsState;
    },
    resetCategoryListState: (state) => {
      state.categoryList = categoryListState;
    },
    resetQuestionListState: (state) => {
      state.questionList = questionListState;
    },
    resetProductMessageState: (state) => {
      state.productMessage = questionListState;
    },
    resetProductMessageSendState: (state) => {
      state.productMessageSend = productMessageSendState;
    },
    resetProductApplyCouponState: (state) => {
      state.productApplyCoupon = productApplyCouponState;
    },
    resetshippingAddressState: (state) => {
      state.shippingAddress = shippingAddressState;
    },
  },
  extraReducers(builder) {
    // Start validate user request.
    builder.addCase(productDetails.pending, (state) => {
      state.productDetails.isSuccess = false;
      state.productDetails.isLoading = true;
      state.productDetails.errorCode = 0;
      state.productDetails.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(productDetails.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.productDetails.isLoading = error === 0;
      state.productDetails.isSuccess = true;
      state.productDetails.userInfo = data;
      state.productDetails.errorCode = error;
      state.productDetails.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(productDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.productDetails.isSuccess = false;
      state.productDetails.isLoading = false;
      state.productDetails.errorCode = error;
      state.productDetails.errorMessage = 'Error';
    });
    builder.addCase(refundDetails.pending, (state) => {
      state.refundDetails.isSuccess = false;
      state.refundDetails.isLoading = true;
      state.refundDetails.errorCode = 0;
      state.refundDetails.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(refundDetails.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.refundDetails.isLoading = error === 0;
      state.refundDetails.isSuccess = true;
      state.refundDetails.prdtInfo = data;
      state.refundDetails.errorCode = error;
      state.refundDetails.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(refundDetails.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.refundDetails.isSuccess = false;
      state.refundDetails.isLoading = false;
      state.refundDetails.errorCode = error;
      state.refundDetails.errorMessage = 'Error';
    });
    builder.addCase(requestRefund.pending, (state) => {
      state.requestRefund.isSuccess = false;
      state.requestRefund.isLoading = true;
      state.requestRefund.errorCode = 0;
      state.requestRefund.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(requestRefund.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.requestRefund.isLoading = error === 0;
      state.requestRefund.isSuccess = true;
      state.requestRefund.resultInfo = data;
      state.requestRefund.errorCode = error;
      state.requestRefund.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(requestRefund.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.requestRefund.isSuccess = false;
      state.requestRefund.isLoading = false;
      state.requestRefund.errorCode = error;
      state.requestRefund.errorMessage = 'Error';
    });
    builder.addCase(productLock.pending, (state) => {
      state.lockProduct.isSuccess = false;
      state.lockProduct.isLoading = true;
      state.lockProduct.errorCode = 0;
      state.lockProduct.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(productLock.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.lockProduct.isLoading = error === 0;
      state.lockProduct.isSuccess = true;
      state.lockProduct.resultInfo = data;
      state.lockProduct.errorCode = error;
      state.lockProduct.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(productLock.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.lockProduct.isSuccess = false;
      state.lockProduct.isLoading = false;
      state.lockProduct.errorCode = error;
      state.lockProduct.errorMessage = 'Error';
    });
    builder.addCase(productMakeOrder.pending, (state) => {
      state.makeOrderProduct.isSuccess = false;
      state.makeOrderProduct.isLoading = true;
      state.makeOrderProduct.errorCode = 0;
      state.makeOrderProduct.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(productMakeOrder.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.makeOrderProduct.isLoading = error === 0;
      state.makeOrderProduct.isSuccess = true;
      state.makeOrderProduct.resultInfo = data;
      state.makeOrderProduct.errorCode = error;
      state.makeOrderProduct.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(productMakeOrder.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.makeOrderProduct.isSuccess = false;
      state.makeOrderProduct.isLoading = false;
      state.makeOrderProduct.errorCode = error;
      state.makeOrderProduct.errorMessage = 'Error';
    });
    builder.addCase(createCustomer.pending, (state) => {
      state.createCustomer.isSuccess = false;
      state.createCustomer.isLoading = true;
      state.createCustomer.errorCode = 0;
      state.createCustomer.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(createCustomer.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.createCustomer.isLoading = error === 0;
      state.createCustomer.isSuccess = true;
      state.createCustomer.resultInfo = data;
      state.createCustomer.errorCode = error;
      state.createCustomer.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(createCustomer.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.createCustomer.isSuccess = false;
      state.createCustomer.isLoading = false;
      state.createCustomer.errorCode = error;
      state.createCustomer.errorMessage = 'Error';
    });
    builder.addCase(vendorProducts.pending, (state) => {
      state.vendorProducts.isSuccess = false;
      state.vendorProducts.isLoading = true;
      state.vendorProducts.errorCode = 0;
      state.vendorProducts.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(vendorProducts.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.vendorProducts.isLoading = error === 0;
      state.vendorProducts.isSuccess = true;
      state.vendorProducts.resultInfo = data;
      state.vendorProducts.errorCode = error;
      state.vendorProducts.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(vendorProducts.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.vendorProducts.isSuccess = false;
      state.vendorProducts.isLoading = false;
      state.vendorProducts.errorCode = error;
      state.vendorProducts.errorMessage = 'Error';
    });
    builder.addCase(reportProduct.pending, (state) => {
      state.reportproduct.isSuccess = false;
      state.reportproduct.isLoading = true;
      state.reportproduct.errorCode = 0;
      state.reportproduct.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(reportProduct.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.reportproduct.isLoading = error === 0;
      state.reportproduct.isSuccess = true;
      state.reportproduct.resultInfo = data;
      state.reportproduct.errorCode = error;
      state.reportproduct.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(reportProduct.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.reportproduct.isSuccess = false;
      state.reportproduct.isLoading = false;
      state.reportproduct.errorCode = error;
      state.reportproduct.errorMessage = 'Error';
    });
    builder.addCase(reportOptions.pending, (state) => {
      state.reportReasons.isSuccess = false;
      state.reportReasons.isLoading = true;
      state.reportReasons.errorCode = 0;
      state.reportReasons.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(reportOptions.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.reportReasons.isLoading = error === 0;
      state.reportReasons.isSuccess = true;
      state.reportReasons.resultInfo = data;
      state.reportReasons.errorCode = error;
      state.reportReasons.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(reportOptions.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.reportReasons.isSuccess = false;
      state.reportReasons.isLoading = false;
      state.reportReasons.errorCode = error;
      state.reportReasons.errorMessage = 'Error';
    });

    builder.addCase(categoryList.pending, (state) => {
      state.categoryList.isSuccess = false;
      state.categoryList.isLoading = true;
      state.categoryList.errorCode = 0;
      state.categoryList.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(categoryList.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.categoryList.isLoading = error === 0;
      state.categoryList.isSuccess = true;
      state.categoryList.resultInfo = data;
      state.categoryList.errorCode = error;
      state.categoryList.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(categoryList.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.categoryList.isSuccess = false;
      state.categoryList.isLoading = false;
      state.categoryList.errorCode = error;
      state.categoryList.errorMessage = 'Error';
    });

    builder.addCase(productMessages.pending, (state) => {
      state.questionList.isSuccess = false;
      state.questionList.isLoading = true;
      state.questionList.errorCode = 0;
      state.questionList.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(productMessages.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.questionList.isLoading = error === 0;
      state.questionList.isSuccess = true;
      state.questionList.resultInfo = data;
      state.questionList.errorCode = error;
      state.questionList.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(productMessages.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.productMessage.isSuccess = false;
      state.productMessage.isLoading = false;
      state.productMessage.errorCode = error;
      state.productMessage.errorMessage = 'Error';
    });

    builder.addCase(productMessageHistory.pending, (state) => {
      state.productMessage.isSuccess = false;
      state.productMessage.isLoading = true;
      state.productMessage.errorCode = 0;
      state.productMessage.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(productMessageHistory.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.productMessage.isLoading = error === 0;
      state.productMessage.isSuccess = true;
      state.productMessage.resultInfo = data;
      state.productMessage.errorCode = error;
      state.productMessage.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(productMessageHistory.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.productMessage.isSuccess = false;
      state.productMessage.isLoading = false;
      state.productMessage.errorCode = error;
      state.productMessage.errorMessage = 'Error';
    });

    builder.addCase(productMessageSend.pending, (state) => {
      state.productMessageSend.isSuccess = false;
      state.productMessageSend.isLoading = true;
      state.productMessageSend.errorCode = 0;
      state.productMessageSend.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(productMessageSend.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.productMessageSend.isLoading = error === 0;
      state.productMessageSend.isSuccess = true;
      state.productMessageSend.resultInfo = data;
      state.productMessageSend.errorCode = error;
      state.productMessageSend.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(productMessageSend.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.productMessageSend.isSuccess = false;
      state.productMessageSend.isLoading = false;
      state.productMessageSend.errorCode = error;
      state.productMessageSend.errorMessage = 'Error';
    });

    builder.addCase(productApplyCoupon.pending, (state) => {
      state.productApplyCoupon.isSuccess = false;
      state.productApplyCoupon.isLoading = true;
      state.productApplyCoupon.errorCode = 0;
      state.productApplyCoupon.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(productApplyCoupon.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.productApplyCoupon.isLoading = error === 0;
      state.productApplyCoupon.isSuccess = true;
      state.productApplyCoupon.resultInfo = data;
      state.productApplyCoupon.errorCode = error;
      state.productApplyCoupon.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(productApplyCoupon.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.productApplyCoupon.isSuccess = false;
      state.productApplyCoupon.isLoading = false;
      state.productApplyCoupon.errorCode = error;
      state.productApplyCoupon.errorMessage = 'Error';
    });

    builder.addCase(shippingAddress.pending, (state) => {
      state.shippingAddress.isSuccess = false;
      state.shippingAddress.isLoading = true;
      state.shippingAddress.errorCode = 0;
      state.shippingAddress.errorMessage = '';
    });
    // Validate user request success.
    builder.addCase(shippingAddress.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      const error = action.payload?.error_code || 0;
      state.shippingAddress.isLoading = error === 0;
      state.shippingAddress.isSuccess = true;
      state.shippingAddress.resultInfo = data;
      state.shippingAddress.errorCode = error;
      state.shippingAddress.errorMessage = action.payload?.message || '';
    });
    // Validate user error.
    builder.addCase(shippingAddress.rejected, (state, action: any) => {
      const error = action.payload?.error || 0;
      state.shippingAddress.isSuccess = false;
      state.shippingAddress.isLoading = false;
      state.shippingAddress.errorCode = error;
      state.shippingAddress.errorMessage = 'Error';
    });
  },
});
// Export auth actions.
export const {
  resetProductDetailState,
  resetRefundDetailState,
  resetVendorPrdtsState,
  resetreportProductState,
  resetLockProductState,
  resetMakeOrderProductState,
  resetCustomerCreateState,
  resetRequestRefundState,
  resetProductMessageSendState,
  resetProductApplyCouponState,
  resetshippingAddressState,
} = productSlice.actions;
// Export reducer.
export default productSlice.reducer;
