
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const Notification = (props: any) => {
  return (
    <OverlayTrigger placement='bottom' overlay={<Tooltip id="tooltip-disabled">Notifications</Tooltip>}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
      <g id="icn_notification" transform="translate(1 1)">
        <path id="Path_13" data-name="Path 13" d="M69,175.9v.089a2.675,2.675,0,1,0,5.35,0V175.9" transform="translate(-61.675 -156.664)" fill="none" stroke-miterlimit="10" stroke-width="2" />
        <path id="Path_14" data-name="Path 14" d="M79,167.171V164.12a7,7,0,1,0-14,0v3.051c0,3.356-3,4.17-3,6.1,0,1.729,3.9,3.051,10,3.051S82,175,82,173.274C82,171.341,79,170.527,79,167.171Z" transform="translate(-62 -157)" fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="2" />
      </g>
    </svg>
    </OverlayTrigger>
  )
};

export default Notification;

