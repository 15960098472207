import React from 'react'
import './Loader.scss'

const Loader = (props) => {
  return (
  <div className="show cd-loader d-flex justify-content-center align-items-center">
  <div className="loader"></div>
</div>
  )
};

export default Loader;

