/**
 * @file   src\components\DropdownMenu.tsx
 * @brief  Dropdown menu component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import './Header.scss';
import { useEffect, useState } from 'react';
import { NavDropdown, Modal, Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Strings from '../assets/strings/Strings.json';
import { useAppSelector } from '../hooks/index';
import { signoutUser } from '../store/actions/authAction';
import { resetSignoutState } from '../store/slices/authSlice';
import * as CONSTANT from '../utils/constants';
import { getGusetUser, getUser } from '../utils/localStorage';
import { error_code } from '../utils/enums';
const DropMenu = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const guestUser = getGusetUser();
  const user_info = JSON.parse(getUser()) 
  const signoutResponse = useAppSelector((RootReducer) => RootReducer.auth.signout);
  const [signoutConfirmationShow, setSignoutConfirmationShow] = useState<boolean>(false);
  const [gusetUserSignOutShow, setGusetUserSignOutShow] = useState<boolean>(false);

  // handle function to call logout user api
  const logoutUser = () => {
    setSignoutConfirmationShow(false);
    dispatch(signoutUser());
  };

  // Signout modal confirmation show
  const signoutConfirmation = () => {
    setSignoutConfirmationShow(true);
  };
  // Signout modal confirmation close
  const signoutConfirmationClose = () => {
    setSignoutConfirmationShow(false);
  };

  // handle signout api call response
  useEffect(() => {
    if (signoutResponse.isLoading === false && signoutResponse.isSuccess === true) {
      if (signoutResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        navigate('/signin');
        localStorage.clear();
        //window.location.href = '/signin';
        if (window.zE) {
          window.zE('messenger:set', 'cookies', false);
          // window.zE('messenger', 'hide');
        }
        // toast.success(signoutResponse?.errorMessage);
      } else {
        toast.error(signoutResponse?.errorMessage);
      }
      dispatch(resetSignoutState());
    }
  }, [signoutResponse]);

  // Guest signout modal confirmation show
  const signoutGuestConfirmationClose = () => {
    setGusetUserSignOutShow(false);
  };

  // Guest signout modal confirmation close and navigate to login page
  const logoutGuestUser = () => {
    setGusetUserSignOutShow(false);
    window.location.href = '/signin';
    // navigate('/signin');
    if (window.zE) {
      window.zE('messenger:set', 'cookies', false);
      // window.zE('messenger', 'hide');
    }
  };
  const pathName = window.location.pathname;

  return (
    <>
      <NavDropdown.Item onClick={() => guestUser === true && setGusetUserSignOutShow(true)} as={Link} className={pathName==='/profile'? 'active':''} to={guestUser !== true ? '/profile' : ''}>
        {Strings.Header.DropDownMenu.MenuItem1}
      </NavDropdown.Item>
      <NavDropdown.Item onClick={() => guestUser === true && setGusetUserSignOutShow(true)} as={Link} className={pathName==='/purchasehistory'? 'active':''} to={guestUser !== true ? "/purchasehistory" : ''}>
        {Strings.Header.DropDownMenu.MenuItem2}
      </NavDropdown.Item>
      { user_info && ((user_info.u_logintype !== error_code.Code_2) && (user_info.u_logintype !== error_code.Code_3)) &&
      <NavDropdown.Item onClick={() => guestUser === true && setGusetUserSignOutShow(true)} as={Link} className={pathName==='/changepassword'? 'active':''} to={guestUser !== true ? '/changepassword' : ''}>
        {Strings.Header.DropDownMenu.MenuItem3}
      </NavDropdown.Item>
      }

      {guestUser !== true && <NavDropdown.Item onClick={() => signoutConfirmation()} >
        {Strings.Header.DropDownMenu.MenuItem4}
      </NavDropdown.Item>}

      <Modal show={signoutConfirmationShow} onHide={signoutConfirmationClose} centered backdrop="static">
        <Modal.Body className="otp-verify">
          <h1 className="text-center">{Strings.Header.DropDownMenu.MenuItem4}</h1>
          <span className="text-center d-block pt-3">
            {Strings.Header.DropDownMenu.signOut.label}
            <br />
          </span>
          <div className="btn-wrap d-flex pt-4">
            <Button onClick={signoutConfirmationClose} variant="secondary">
              {Strings.Header.DropDownMenu.signOut.Btn2}
            </Button>
            <Button onClick={logoutUser} variant="primary">
              {Strings.Header.DropDownMenu.signOut.Btn1}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={gusetUserSignOutShow} onHide={signoutGuestConfirmationClose} centered backdrop="static">
        <Modal.Body className="otp-verify">
          <h1 className="text-center">{Strings.Login.Title}</h1>
          <span className="text-center d-block pt-3">
            Sign in to continue.
            <br />
          </span>
          <div className="btn-wrap d-flex pt-4">
            <Button onClick={signoutGuestConfirmationClose} variant="secondary">
              {Strings.Header.DropDownMenu.signOut.Btn2}
            </Button>
            <Button onClick={logoutGuestUser} variant="primary">
              {Strings.Header.DropDownMenu.signOut.Btn1}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DropMenu;
