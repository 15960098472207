
const IcThings = (props: any) => {
  return (
    <svg id="icn_things" xmlns="http://www.w3.org/2000/svg" width="16.889" height="20" viewBox="0 0 16.889 20">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_594" data-name="Rectangle 594" width="16.889" height="20" />
        </clipPath>
      </defs>
      <g id="Group_1425" data-name="Group 1425" clip-path="url(#clip-path)">
        <path id="Path_31535" data-name="Path 31535" d="M8.462,0C1.564,0,0,3.067,0,3.067,0,18.253,8.444,20,8.444,20s8.444-1.858,8.444-16.933c0,0-1.529-3.067-8.427-3.067m-5.8,7.556S4.053,5.778,5.333,5.778c1.058,0,2.222,1.333,2.222,1.333s-1.262.244-2.5.373a16.8,16.8,0,0,1-2.391.071m5.778,8C5.373,15.556,4,10.667,4,10.667a19.73,19.73,0,0,0,4.444.889,19.791,19.791,0,0,0,4.444-.889s-1.373,4.889-4.444,4.889m3.387-8.071c-1.24-.129-2.5-.373-2.5-.373S10.5,5.778,11.556,5.778c1.276,0,2.667,1.778,2.667,1.778a16.8,16.8,0,0,1-2.391-.071" />
      </g>
    </svg>

  )
};

export default IcThings;

