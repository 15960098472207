import React, { useState, useEffect } from 'react';
import { Col, Row, Modal, Button, Form } from 'react-bootstrap';
import { Range } from 'react-range';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ImageListType } from 'react-images-uploading';
import RangeBg from '../assets/img/range-bg.svg';
import UploadBg from '../assets/img/upload-bg.svg';
import NoImage from '../assets/img/no-image.svg';
import * as CONSTANT from '../utils/constants';
import NBInputGroup from './NBInputGroup';
import NBSelect from './NBSelect';
import Strings from '../assets/strings/Strings.json';
import { getLatLong, getGusetUser } from '../utils/localStorage';
import * as alerts from '../utils/alerts';
import { emailValidation, validateOfferImage } from '../utils/authHelper';
import { userOfferRequest, offerRequestImageUpload } from '../store/actions/userAction';
import { useAppSelector } from '../hooks/index';
import { resetOfferRequestState } from '../store/slices/userSlice';
import Loader from './Loader';
import RemoveIc from '../assets/img/icons/Remove';

const RequestOfferModal = React.forwardRef((props: any, ref: any) => {
  const latLong = JSON.parse(getLatLong());
  const dispatch = useDispatch();
  const guestUser = getGusetUser();
  const requestOfferResponse = useAppSelector((RootReducer) => RootReducer.user.userOfferRequest);
  const CategoryListingResponseData = useAppSelector((RootReducer) => RootReducer.product.categoryList);
  const profileResponse = useAppSelector((RootReducer) => RootReducer.auth.profile);

  type OfferDetails = {
    offerTypeId: number;
    offerTypeLabel: string;
    subcategoryId: number;
    minPrice: number;
    maxPrice: number;
    offerDescription: string;
    imagePath: string;
    imageUrl: string;
    imageFormData: any;
  };
  type failedUploadsType = {
    index: number;
    base64: string;
  };
  type ErrorValueType = {
    zipCode: string;
    expectedTimeValue: string;
    email: string;
    offerTypeId: string;
    subcategoryId: string;
    minPrice: string;
    maxPrice: string;
    offerDescription: string;
  };
  type Category = { reason_id: number; reason: string };
  type dataType = {
    zipCode: string;
    expectedTimeValue: number;
    email: string;
  };
  const intialErrorValue = {
    zipCode: '',
    expectedTimeValue: '',
    email: '',
    offerTypeId: '',
    subcategoryId: '',
    minPrice: '',
    maxPrice: '',
    offerDescription: '',
  };
  const [range, setRange] = useState([1, CONSTANT.MAX_PRICE]);
  const [subCategoryListData, setSubCategoryListData] = useState<any>([]);
  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [categoriesValue, setCategoriesValue] = useState('');
  const [subCategoriesValue, setSubCategoriesValue] = useState('');
  const [note, setNote] = useState('');
  const [offersDetails, setOffersDetails] = useState<OfferDetails[]>([]);
  const [error, setError] = useState<ErrorValueType>(intialErrorValue);
  const [data, setData] = useState<dataType>({
    zipCode: '',
    expectedTimeValue: 1,
    email: '',
  });
  const [image, setImage] = useState<string>('');
  const [imageFormData, setImageFormData] = useState<string>('');
  const [showRetryModal, setShowRetryModal] = useState<boolean>(false);
  const [failedUploads, setFailedUploads] = useState<failedUploadsType[]>([]);
  const [offerDetailsArray, setOfferDetailsArray] = useState<OfferDetails[]>([]);
  const [load, setLoad] = useState<boolean>(false);
  const [productPicError, setProductPicError] = React.useState<any>('');

  const expextedTimeData = [
    { reason_id: 1, reason: 'Within 2 Hours' },
    { reason_id: 2, reason: 'Within 6 Hours' },
    { reason_id: 3, reason: 'Within a Day' },
    { reason_id: 4, reason: 'Within a Week' },
    { reason_id: 5, reason: 'Within a Month' },
  ];

  const handleZipcodeChange = (event: any) => {
    const newValue = event.target.value;
    if (/^\d*\.?\d*$/.test(newValue)) {
      setData((val: dataType) => ({
        ...val,
        zipCode: newValue,
      }));
    }
  };
  const handleSelectChange = (value: any) => {
    setData((val: dataType) => ({
      ...val,
      expectedTimeValue: value,
    }));
  };
  const handleSelectCategoryChange = (value: any) => {
    const categoryArray: { reason_id: number; reason: string }[] = [];
    subCategoryListData.forEach((subcategory: any) => {
      if (subcategory.category_id === Number(value)) {
        categoryArray.push({
          reason_id: subcategory.subcategory_id,
          reason: subcategory.subcategory_name,
        });
      }
    });
    setSubCategories(categoryArray);
    setCategoriesValue(value);
    setSubCategoriesValue('');
    setError((prevError: ErrorValueType) => ({ ...prevError, offerTypeId: '' }));
  };

  const handleSelectSubCategoryChange = (value: string) => {
    setSubCategoriesValue(value);
    setError((prevError: ErrorValueType) => ({ ...prevError, subcategoryId: '' }));
  };
  const handleChange = (values: number[]) => {
    setRange(values);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((val: dataType) => ({
      ...val,
      email: e.target.value,
    }));
    setError((prevError: ErrorValueType) => ({ ...prevError, email: '' }));
  };
  const handleNoteChange = (e: any) => {
    setNote(e.target.value);
    setError((prevError: ErrorValueType) => ({ ...prevError, offerDescription: '' }));
  };

  const clearSaveRequestData = () => {
    setCategoriesValue('');
    setSubCategoriesValue('');
    setNote('');
    setSubCategories([]);
    setImage('');
    setImageFormData('');
    setRange([1, CONSTANT.MAX_PRICE]);
    setProductPicError('');
  };
  const clearSubmitRequestData = () => {
    clearSaveRequestData();
    setRange([1, CONSTANT.MAX_PRICE]);
    setData((val: any) => ({
      ...val,
      expectedTimeValue: 1,
      email: '',
      zipCode: '',
    }));
    setOffersDetails([]);
    setFailedUploads([]);
    setOfferDetailsArray([]);
  };
  const validateSubmitRequest = () => {
    let isValid = true;
    const validEmail = emailValidation(data?.email);

    setError(intialErrorValue);
    if (data && data?.zipCode === '') {
      setError((prevError: ErrorValueType) => ({ ...prevError, zipCode: alerts.ENTER_ZIP }));
      isValid = false;
    }
    if (data && data?.email === '') {
      setError((prevError: ErrorValueType) => ({ ...prevError, email: alerts.ENTER_EMAIL }));
      isValid = false;
    } else if (validEmail === false) {
      setError((prevError: ErrorValueType) => ({ ...prevError, email: alerts.ENTER_VALID_EMAIL }));
      isValid = false;
    }
    // if ((note !== '' || (offersDetails && offersDetails?.length === 0)) && categoriesValue === '') {
    //   setError((prevError: ErrorValueType) => ({ ...prevError, offerTypeId: alerts.ENTER_CATEGORY }));
    //   isValid = false;
    // }
    // if ((note !== '' || categoriesValue !== '' || (offersDetails && offersDetails?.length === 0)) && subCategoriesValue === '') {
    //   setError((prevError: ErrorValueType) => ({ ...prevError, subcategoryId: alerts.ENTER_SUB_CATEGORY }));
    //   isValid = false;
    // }
    // if ((categoriesValue !== '' || subCategoriesValue !== '' || (offersDetails && offersDetails?.length === 0)) && note === '') {
    //   setError((prevError: ErrorValueType) => ({ ...prevError, offerDescription: alerts.ENTER_PRODUCT_DESCRIPTION }));
    //   isValid = false;
    // }
    return isValid;
  };

  const validateSaveRequest = () => {
    let isValid = true;
    const validEmail = emailValidation(data?.email);
    setError(intialErrorValue);
    if (data && data?.zipCode === '') {
      setError((prevError: ErrorValueType) => ({ ...prevError, zipCode: alerts.ENTER_ZIP }));
      isValid = false;
    }
    if (data && data?.email === '') {
      setError((prevError: ErrorValueType) => ({ ...prevError, email: alerts.ENTER_EMAIL }));
      isValid = false;
    } else if (validEmail === false) {
      setError((prevError: ErrorValueType) => ({ ...prevError, email: alerts.ENTER_VALID_EMAIL }));
      isValid = false;
    }
    if (categoriesValue === '') {
      setError((prevError: ErrorValueType) => ({ ...prevError, offerTypeId: alerts.ENTER_CATEGORY }));
      isValid = false;
    }
    if (subCategoriesValue === '') {
      setError((prevError: ErrorValueType) => ({ ...prevError, subcategoryId: alerts.ENTER_SUB_CATEGORY }));

      isValid = false;
    }
    if (note === '') {
      setError((prevError: ErrorValueType) => ({ ...prevError, offerDescription: alerts.ENTER_PRODUCT_DESCRIPTION }));
      isValid = false;
    }
    return isValid;
  };

  const handleSaveRequest = () => {
    const valid = validateSaveRequest();
    if (valid) {
      let categoriesLabel = '';
      if (categories) {
        const resultCategories: Category = categories?.find((categoriesData: any) => categoriesData?.reason_id === Number(categoriesValue));
        categoriesLabel = resultCategories?.reason;
      }
      const params = {
        offerTypeId: Number(categoriesValue),
        offerTypeLabel: categoriesLabel,
        subcategoryId: Number(subCategoriesValue),
        minPrice: range[0],
        maxPrice: range[1],
        offerDescription: note,
        imagePath: '',
        imageUrl: image,
        imageFormData,
      };

      setOffersDetails((prevDetails) => [params, ...prevDetails]);
      clearSaveRequestData();
    }
  };

  const handleSubmitRequest = async () => {
    const valid = validateSubmitRequest();
    if (valid) {
      setLoad(true);
      const array: any = [];
      const imageArray: any = [];
      // if (categoriesValue !== '') {
      //   array.push({
      //     offerTypeId: Number(categoriesValue),
      //     subcategoryId: Number(subCategoriesValue),
      //     minPrice: range[0],
      //     maxPrice: range[1],
      //     offerDescription: note,
      //     imagePath: '',
      //   });
      //   if (imageFormData) {
      //     imageArray.push({ base64: imageFormData, index: array.length - 1 });
      //   }
      // }
      if (offersDetails && offersDetails.length > 0) {
        offersDetails.forEach((offer: OfferDetails) => {
          array.push({
            offerTypeId: offer?.offerTypeId,
            subcategoryId: offer?.subcategoryId,
            minPrice: offer?.minPrice,
            maxPrice: offer?.maxPrice,
            offerDescription: offer?.offerDescription,
            imagePath: offer?.imagePath,
          });
          if (offer?.imageFormData) {
            imageArray.push({ base64: offer?.imageFormData, index: array.length - 1 });
          }
        });
      }
      setOfferDetailsArray(array);

      try {
        const uploadResponses = await Promise.allSettled(imageArray.map((data) => dispatch<any>(offerRequestImageUpload({ base64Image: data?.base64 })).unwrap()));

        const initialFailures: any[] = [];
        uploadResponses.forEach((result, idx) => {
          const { index } = imageArray[idx];
          if (result.status === 'fulfilled' && result.value?.data?.imageUrl) {
            array[index].imagePath = result.value.data.imageUrl;
          } else {
            initialFailures.push({ ...imageArray[idx], index });
          }
        });

        setFailedUploads(initialFailures);

        if (initialFailures.length > 0) {
          setShowRetryModal(true);
          setLoad(false);
        } else {
          const payload = {
            zipcode: data?.zipCode,
            expectedTime: data?.expectedTimeValue,
            emailId: data?.email,
            offerDetails: array,
          };

          dispatch<any>(userOfferRequest(payload));
        }
      } catch (error) {
        console.error('Unexpected error during image upload:', error);
        toast.error('An unexpected error occurred.');
      }
      return null;
    }
  };

  const handleRetry = async () => {
    setShowRetryModal(false); // Close the modal
    setLoad(true);

    const retryResponses = await Promise.allSettled(failedUploads.map((data) => dispatch<any>(offerRequestImageUpload({ base64Image: data.base64 })).unwrap()));

    const remainingFailures: any[] = [];
    const sucessArray: any[] = [...offerDetailsArray];
    retryResponses.forEach((result, idx) => {
      const { index } = failedUploads[idx];
      if (result.status === 'fulfilled' && result.value?.data?.imageUrl) {
        sucessArray[index].imagePath = result.value.data.imageUrl; // Update imagePath
      } else {
        remainingFailures.push({ ...failedUploads[idx], index });
      }
    });

    setFailedUploads(remainingFailures);

    if (remainingFailures.length > 0) {
      setOfferDetailsArray(sucessArray);
      setShowRetryModal(true);
      setLoad(false);
    } else {
      const payload = {
        zipcode: data?.zipCode,
        expectedTime: data?.expectedTimeValue,
        emailId: data?.email,
        offerDetails: sucessArray,
      };

      dispatch<any>(userOfferRequest(payload));
    }
  };

  const handleCancel = () => {
    const sucessArray: any[] = [...offerDetailsArray];
    failedUploads.forEach((result, idx) => {
      sucessArray[result?.index].imagePath = '';
    });
    setShowRetryModal(false); // Close the modal
    setLoad(false);
    const payload = {
      zipcode: data?.zipCode,
      expectedTime: data?.expectedTimeValue,
      emailId: data?.email,
      offerDetails: sucessArray,
    };

    dispatch<any>(userOfferRequest(payload)); // Dispatch the payload
  };
  const handleRemove = (index: number) => {
    const array = [...offersDetails];
    array.splice(index, 1);
    setOffersDetails(array);
  };

  // handle image upload
  const handleImageChange = (imageList: any) => {
    if (imageList.target.files && imageList.target.files[0]) {
      const file = imageList.target.files[0];

      const isValid = validateOfferImage(file);
      if (isValid === '') {
        setProductPicError('');
        const imageUrl = URL.createObjectURL(file);
        setImage(imageUrl);
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            const base64String = reader.result.toString();
            setImageFormData(base64String);
          }
        };
        reader.readAsDataURL(file);
      } else {
        setProductPicError(isValid);
        setImage('');
        setImageFormData('');
      }
    }
  };

  // handle category listing response data
  useEffect(() => {
    if (CategoryListingResponseData.isLoading === false && CategoryListingResponseData.isSuccess === true) {
      if (CategoryListingResponseData.errorCode === CONSTANT.API_SUCCESSCODE) {
        const resultCategory = CategoryListingResponseData?.resultInfo?.category_info;
        const categoryArray: any = [];
        resultCategory?.forEach((catData: any) => {
          categoryArray.push({
            reason_id: catData.category_id,
            reason: catData.category_name,
          });
        });
        setSubCategoryListData(CategoryListingResponseData.resultInfo.subcategory_info);
        setCategories(categoryArray);
      } else {
        toast.error(CategoryListingResponseData?.errorMessage);
      }
    }
  }, [CategoryListingResponseData]);

  // handle profile image response
  useEffect(() => {
    if (profileResponse.isLoading === false && profileResponse.isSuccess === true) {
      if (profileResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        setData((val: any) => ({
          ...val,
          email: profileResponse.userInfo?.user_info?.e_address,
        }));
      }
    }
    if (latLong?.zipCode && props.show === true) {
      setData((val: any) => ({
        ...val,
        zipCode: latLong?.zipCode,
      }));
    }
    clearSaveRequestData();
  }, [profileResponse, props.show]);

  // handle request response.
  useEffect(() => {
    if (requestOfferResponse.isLoading === false && requestOfferResponse.isSuccess === true) {
      if (requestOfferResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        // toast.success(requestOfferResponse?.errorMessage);
        props.handleClose();
        clearSubmitRequestData();
        setLoad(false);
        props.setSuccesModal(true);
      } else {
        toast.error(requestOfferResponse?.errorMessage);
        setLoad(false);
      }
      dispatch<any>(resetOfferRequestState());
    }
  }, [requestOfferResponse]);

  return (
    <Modal show={props.show} onHide={props.handleClose} centered backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Request an offer now</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-0">
        <Row>
          <Col lg="6">
            <NBInputGroup id="zip" name="zip" label="ZIP Code *" value={data?.zipCode} error={error?.zipCode} maxLength={CONSTANT.ZIPCODE_LENGTH} onChange={handleZipcodeChange} />
          </Col>
          <Col lg="6">
            <NBSelect id="select" name="select" options={expextedTimeData} handleSelectChange={handleSelectChange} value={data?.expectedTimeValue} label="Expected Time" />
          </Col>
          <Col lg="12">
            <NBInputGroup
              id="email"
              name="email"
              label="Email Address *"
              maxLength={CONSTANT.EMAIL_LENGTH}
              value={data?.email}
              onChange={handleEmailChange}
              error={error?.email}
              disabled={guestUser === true ? false : true}
            />
          </Col>
        </Row>
        <div className="request-offer">
          <div className="form-wrap">
            <Row>
              <Col lg="6">
                <NBSelect
                  id="select"
                  name="select"
                  options={categories}
                  handleSelectChange={handleSelectCategoryChange}
                  value={categoriesValue}
                  label="Type of Offer *"
                  defaultPlaceHolder="Select Offer Type"
                  error={error?.offerTypeId}
                  disabled={offersDetails && offersDetails.length >= 5}
                />
              </Col>
              <Col lg="6">
                <NBSelect
                  id="select"
                  name="select"
                  options={subCategories}
                  handleSelectChange={handleSelectSubCategoryChange}
                  value={subCategoriesValue}
                  label="Sub Category"
                  defaultPlaceHolder="Select Sub Category"
                  error={error?.subcategoryId}
                  disabled={offersDetails && offersDetails.length >= 5}
                />
              </Col>

              <Col lg="12">
                <label className="d-block mb-3">Price Range *</label>
                <div className="d-flex justify-content-between mb-3 range-value">
                  <span>${range[0]}</span>
                  <span>${range[1]}</span>
                </div>
                <Range
                  disabled={offersDetails && offersDetails.length >= 5}
                  step={1}
                  min={CONSTANT.MIN_PRICE}
                  max={CONSTANT.MAX_PRICE}
                  values={range}
                  onChange={handleChange} // Update range on change
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: '14px',
                        width: '100%',
                        backgroundColor: '#E6E6E6',
                        borderRadius: '10px',
                        position: 'relative',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          left: `${(range[0] / CONSTANT.MAX_PRICE) * 100}%`,
                          width: `${((range[1] - range[0]) / CONSTANT.MAX_PRICE) * 100}%`,
                          backgroundColor: '#262626',
                          height: '100%',
                          borderRadius: '5px',
                        }}
                      />
                      {/* {[...Array(Math.floor((CONSTANT.MAX_PRICE - CONSTANT.MIN_PRICE) / 1000))].map((_, index) => {
                        const leftPosition = ((index * 1000) / CONSTANT.MAX_PRICE) * 100; // Calculate the position for each dot
                        return (
                          <div
                            key={index}
                            style={{
                              position: 'absolute',
                              left: `${leftPosition}%`,
                              top: '50%',
                              transform: 'translateY(-50%)',
                              width: '3px',
                              height: '8px',
                              backgroundColor: '#262626',
                              borderRadius: '50%',
                              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.37)',
                            }}
                          />
                        );
                      })} */}
                      {children}
                    </div>
                  )}
                  renderThumb={({ index, props, value }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: '28px',
                        width: '28px',
                        backgroundColor: '#262626',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '0',
                        transform: 'translateY(-50%)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.37)',
                      }}
                    >
                      {' '}
                      {/* <span
                        style={{
                          position: 'absolute',
                          top: '-25px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: '#737373',
                        }}
                      >
                        ${value}
                      </span> */}
                      <img src={RangeBg} />
                    </div>
                  )}
                />
                <div className="d-flex justify-content-between mt-3">
                  <span>Min: ${CONSTANT.MIN_PRICE}</span>
                  <span>Max: ${CONSTANT.MAX_PRICE}</span>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg="9">
                <NBInputGroup
                  id="description"
                  name="description"
                  as="textarea"
                  rows={4}
                  placeholder={Strings.OfferRequest.PlaceHolder1}
                  label="Offer Description *"
                  maxLength={CONSTANT.DESCRIPTION_LENGTH}
                  value={note}
                  onChange={handleNoteChange}
                  error={error?.offerDescription}
                  disabled={offersDetails && offersDetails.length >= 5}
                />
              </Col>
              <Col lg="3">
                <div className="upload-wrap position-relative">
                  {/* {image !== '' ? (
                    <img src={image} />
                  ) : (
                    <> */}
                  <label for="upload-photo" className="d-flex justify-content-center align-items-center flex-column">
                    <img className={image ? 'uploaded-img' : ''} src={image ? image : UploadBg} />
                    {image ? '' : 'Click to upload'}
                  </label>
                  <input
                    disabled={offersDetails && offersDetails.length >= 5}
                    type="file"
                    accept=" .jpeg, .jpg,"
                    name="photo"
                    id="upload-photo"
                    onChange={(e) => {
                      handleImageChange(e);
                      e.target.value = '';
                    }}
                  />
                  {/* </>
                  )} */}
                  {image && productPicError === '' && (
                    <label for="upload-photo" className="edit-photo text-center d-block">
                      Change Photo
                    </label>
                  )}
                </div>
                <Form.Text className="error" style={{ color: 'red' }}>
                  {productPicError}
                </Form.Text>
              </Col>
            </Row>
            <Button variant="primary" onClick={handleSaveRequest} disabled={offersDetails && offersDetails.length >= 5}>
              Save Request
            </Button>
            {offersDetails && offersDetails.length >= 5 && (
              <>
                <br />
                <br />
                <Form.Text className="error" style={{ color: 'red' }}>
                  The maximum number of offers you can save is 5.
                </Form.Text>
              </>
            )}
          </div>
          {offersDetails &&
            offersDetails.length > 0 &&
            offersDetails.map((offerDetail: OfferDetails, index: number) => {
              return (
                <div className="requested-list d-flex">
                  <div className="left-wrap">
                    <img alt="" src={offerDetail.imageUrl === '' ? NoImage : offerDetail.imageUrl} />
                  </div>
                  <div className="right-wrap position-relative">
                    <h3>{offerDetail?.offerTypeLabel}</h3>
                    <p className="mb-0">{offerDetail?.offerDescription}</p>
                    <span className="remove position-absolute" onClick={() => handleRemove(index)}>
                      <RemoveIc />
                    </span>
                  </div>
                </div>
              );
            })}
          {/* <div className="requested-list d-flex">
            <div className="left-wrap">
              <img src={NoImage} />
            </div>
            <div className="right-wrap">
              <h3>Goods</h3>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam viverra enim quis eleifend molestie. Mauris at orci nec leo euismod ultrices a et...{' '}
              </p>
            </div>
          </div>
          <div className="requested-list d-flex">
            <div className="left-wrap">
              <img src={NoImage} />
            </div>
            <div className="right-wrap">
              <h3>Goods</h3>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam viverra enim quis eleifend molestie. Mauris at orci nec leo euismod ultrices a et...{' '}
              </p>
            </div>
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 req-off-footer">
        <Button variant="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button variant="primary" disabled={offersDetails.length === CONSTANT.STATUS_0} onClick={handleSubmitRequest}>
          Submit Request
        </Button>
      </Modal.Footer>

      <Modal show={showRetryModal} onHide={handleCancel} centered backdrop="static" className="report">
        <Modal.Header>
          <Modal.Title>Retry Image Uploads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Some image uploads failed. Would you like to retry? ({failedUploads.length} images failed)</p>
          <Col className="button-nav">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleRetry}>
              Retry
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
      {load && <Loader />}
    </Modal>
  );
});

export default RequestOfferModal;
