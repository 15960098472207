/**
 * @file   src\components\InnerLayout.tsx
 * @brief  This component used as a layout for the content which can be replaced.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './InnerLayout.scss';

const CommonLayout = () => {
  return (
    <div className='common-layout'>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default CommonLayout;
