
const IcCoupons = (props: any) => {
  return (
    <svg id="coupons" xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_901" data-name="Rectangle 901" width="24" height="18" />
        </clipPath>
      </defs>
      <g id="Group_1983" data-name="Group 1983" clip-path="url(#clip-path)">
        <path id="Path_31930" data-name="Path 31930" d="M23,7a1,1,0,0,0,1-1V1a1,1,0,0,0-1-1H1A1,1,0,0,0,0,1V6A1,1,0,0,0,1,7a2,2,0,0,1,0,4,1,1,0,0,0-1,1v5a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1,2,2,0,0,1,0-4M8.5,4A1.5,1.5,0,1,1,7,5.5,1.5,1.5,0,0,1,8.5,4m7,10A1.5,1.5,0,1,1,17,12.5,1.5,1.5,0,0,1,15.5,14M8,14.414,6.586,13,16,3.586,17.414,5Z" />
      </g>
    </svg>
  )
};

export default IcCoupons;

