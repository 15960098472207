/**
 * @file   src\components\ForgotPass.tsx
 * @brief  ForgotPass Component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Button } from 'react-bootstrap';
import Strings from '../assets/strings/Strings.json';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPhone = (props: any) => {
  const navigate = useNavigate();
  return (
    <>
      <h1>{Strings.ForgotEmail.TextSent.Title}</h1>
      <p>{Strings.ForgotEmail.TextSent.InfoTxt}</p>
      <Button onClick={() => {navigate('/signin')}} variant='primary' className='w-100'>{Strings.FrogotPassword.MailSent.Button}</Button>      
    </>
  );
};

export default ForgotPhone;
