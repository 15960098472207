/**
 * @file   src\store\actions\authAction.ts
 * @brief  This file is responsible for generating asynchronous api call.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { IAuthRequest, ForgotPasswordRequest } from '../../interfaces/authInterface';
import { AuthApi } from '../../utils/apiUrls';
import { encryptData, decryptData } from '../../utils/Encryption';
import { getAuthToken } from '../../utils/localStorage'
// Encrypt Header
const headers: any = {};
headers['Access-Control-Allow-Origin'] = '*';
headers['Access-Control-Allow-Headers'] = '*';
headers['Access-Control-Allow-Credentials'] = true;
headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, PATCH, DELETE';
headers['Content-Type'] = 'text/plain';
headers.Accept = 'text/plain';
headers['Cache-Control'] = 'no-cache';
headers['api_key'] = process.env.REACT_APP_API_KEY;

// User authentication api call.

export const login = createAsyncThunk('/auth/login', async (loginRequest: IAuthRequest, { rejectWithValue }) => {
  let plainText = await encryptData(loginRequest)
    .then(async (response: any) => {
      return response;
    })
    .catch((err: any) => {
    });
  try {
    const { data } = await axios.post(AuthApi.LOGIN, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        console.log('DecryptError', err);
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const forgotPassword = createAsyncThunk('/auth/forgotPassword', async (payload: ForgotPasswordRequest, { rejectWithValue }) => {
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {
      console.log("encryption",err)
    });
  try {
    const { data } = await axios.post(AuthApi.FORGOTPASSWORD, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res: any) => {
        return res;
      })
      .catch((err: any) => {
        console.log('DecryptError', err);
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const signup = createAsyncThunk('/auth/signup', async (signupRequest: IAuthRequest, { rejectWithValue }) => {
  let plainText = await encryptData(signupRequest)
    .then(async (response: any) => {
      return response;
    })
    .catch((err: any) => {
    });
  try {
    const { data } = await axios.post(AuthApi.SIGNUP, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res: any) => {
        return res;
      })
      .catch((err: any) => {
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const sendcode = createAsyncThunk('/auth/sendcode', async (payload: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(AuthApi.SENDOTP, payload);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const verifyCode = createAsyncThunk('/auth/verifycode', async (payload: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(AuthApi.VERIFYOTP, payload);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const signoutUser = createAsyncThunk('/auth/signout', async (_, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken() };
    const { data } = await axios.post(AuthApi.SIGNOUT, undefined, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const deleteUser = createAsyncThunk('/auth/deleteuser', async (_, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken() };
    const { data } = await axios.delete(AuthApi.DELETEUSER, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const clearNtfn = createAsyncThunk('/auth/clear', async (_, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken() };
    const { data } = await axios.delete(AuthApi.DELETEUSER, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const getProfile = createAsyncThunk('/auth/profile', async (_, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken() };
    const { data } = await axios.post(AuthApi.PROFILE, {}, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});


export const editProfile = createAsyncThunk('/auth/editprofile', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken() };
    const { data } = await axios.put(AuthApi.EDITPROFILE, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const applelogin = createAsyncThunk('/auth/appleLogin', async (payload: any, { rejectWithValue }) => {
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {
      console.log("encryption",err)
    });
  try {
    const { data } = await axios.post(AuthApi.APPLELOGIN, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        console.log('DecryptError', err);
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const fbLogin = createAsyncThunk('/auth/facebooklogin', async (payload: any, { rejectWithValue }) => {
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {
      console.log("encryption",err)
    });
  try {
    const { data } = await axios.post(AuthApi.FACEBOOKLOGIN, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        console.log('DecryptError', err);
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const resetPassword = createAsyncThunk('/auth/resetpassword', async (payload: any, { rejectWithValue }) => {
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {
      console.log("encryption",err)
    });
  try {
    const headers = { api_key: process.env.REACT_APP_API_KEY_ENCRYPTED, 'Content-Type': 'text/plain', Accept: 'text/plain' };
    const { data } = await axios.post(AuthApi.RESETPASSWORD, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        console.log('DecryptError', err);
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const purchaseHistory = createAsyncThunk('/auth/purchasehistory', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken() };
    const { data } = await axios.post(AuthApi.PURCHASEHISTORY, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const locationListing = createAsyncThunk('/auth/locationListing', async (payload: any, { rejectWithValue }) => {
  try {
    const headers = { 'baseURL': process.env.REACT_APP_API_URL_VENDOR };
    const { data } = await axios.post(AuthApi.LOCATIONLISTING, payload, { headers });
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const changePassword = createAsyncThunk('/auth/changepassword', async (payload: any, { rejectWithValue }) => {
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {
      console.log("encryption",err)
    });
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), 'Content-Type': 'text/plain', Accept: 'text/plain' };
    const { data } = await axios.post(AuthApi.CHANGEPASSWORD, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        console.log('DecryptError', err);
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});


export const _forgotEmail = createAsyncThunk('/auth/forgotemail', async (payload: any, { rejectWithValue }) => {
  const plainText = await encryptData(payload)
    .then(async (response) => {
      return response;
    })
    .catch((err) => {
      console.log("encryption",err)
    });
  try {
    const headers = { Authorization: 'Bearer ' + getAuthToken(), 'Content-Type': 'text/plain', Accept: 'text/plain' };
    const { data } = await axios.post(AuthApi.FORGOTEMAIL, plainText, { headers });
    const response = await decryptData(data)
      .then(async (res) => {
        return res;
      })
      .catch((err) => {
        console.log('DecryptError', err);
      });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});
