/**
 * @file   src\containers\ChangePassword.tsx
 * @brief  Change Password page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Button, Col, Container, Row } from 'react-bootstrap';
import '../assets/styles/profile.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { changePassword } from '../store/actions/authAction';
import { ntfnCheck } from '../store/actions/ntfnAction';
import { changePasswordResponseState } from '../store/slices/authSlice';
import { passwordValidation } from '../utils/authHelper';
import * as CONSTANT from '../utils/constants';
import { IchangePassword, IPasswordError } from '../interfaces/GeneralInterface';
import * as ALERT from '../utils/alerts';
import { changePwd } from '../utils/enums';
import NBInputGroupPswd from '../components/NBInputGroupPswd';
import Strings from '../assets/strings/Strings.json';

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const changePasswordResponse = useAppSelector((RootReducer) => RootReducer.auth.changepassword);

  const [passwordsError, setPasswordsError] = useState<IPasswordError>({
    old_passwordError: '',
    new_passwordError: '',
    confirm_passwordError: '',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordNew, setShowPasswordNew] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);
  const [passowrd, setPassword] = useState<IchangePassword>({
    newPass: '',
    confirmNewPass: '',
    oldPass: '',
  });

  // handles change password api response
  useEffect(() => {
    if (changePasswordResponse && changePasswordResponse.isSuccess) {
      if (changePasswordResponse && changePasswordResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        toast.success(changePasswordResponse.errorMessage);
        navigate('/signin');
      } else if (changePasswordResponse && changePasswordResponse.errorCode === CONSTANT.API_ERRCODE) {
        toast.error(changePasswordResponse.errorMessage);
      }
      dispatch(changePasswordResponseState());
    }
  }, [changePasswordResponse]);

  // handle notification check
  useEffect(() => {
    dispatch<any>(ntfnCheck());
  }, []);

  // handles password changes
  const handleChanges = (event: { target: { name: any; value: string } }) => {
    setPassword((passowrd) => ({
      ...passowrd,
      [event.target.name]: event.target.value,
    }));
  };

  // handles password validation
  const validation = () => {
    let isValid = true;
    setPasswordsError({
      old_passwordError: '',
      new_passwordError: '',
      confirm_passwordError: '',
    });
    if (passowrd.oldPass === '') {
      isValid = false;
      setPasswordsError((passwordsError : any) => ({
        ...passwordsError,
        old_passwordError: ALERT.ENTER_CURRENT_PWD,
      }));
    }
    const passwordErrorMsg = passwordValidation(passowrd.newPass, changePwd.CURRENT_PWD);
    if (passwordErrorMsg !== '') {
      isValid = false;
      setPasswordsError((passwordsError: any) => ({
        ...passwordsError,
        new_passwordError: passwordErrorMsg,
      }));
    }
    if (passowrd.newPass === '') {
      isValid = false;
      setPasswordsError((passwordsError: any) => ({
        ...passwordsError,
        new_passwordError: ALERT.ENTER_NEW_PWD,
      }));
    }
    if (passowrd.confirmNewPass === '') {
      isValid = false;
      setPasswordsError((passwordsError: any) => ({
        ...passwordsError,
        confirm_passwordError: ALERT.RE_ENTER_PWD,
      }));
    } else if (passowrd.newPass !== passowrd.confirmNewPass) {
      isValid = false;
      setPasswordsError((passwordsError: any) => ({
        ...passwordsError,
        confirm_passwordError: ALERT.CONFIRM_NEW_PWD,
      }));
    }
    return isValid;
  };

  // handles password form submission
  const submitPswdForm = () => {
    const isValid = validation();
    if (isValid) {
      const payload = {
        password: passowrd.newPass,
        old_password: passowrd.oldPass,
      };
      dispatch(changePassword(payload));
    }
  };

  // handle show password
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowPasswordNew = () => {
    setShowPasswordNew(!showPasswordNew);
  };
  const handleShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container>
          <h2>{Strings.ChangePassword.Title}</h2>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <Row className="profile-form">
            <Col xl={4} lg={5} md={6} className="m-0">
              <NBInputGroupPswd
                value={passowrd.oldPass}
                onChange={handleChanges}
                error={passwordsError.old_passwordError ? passwordsError.old_passwordError : ''}
                id="CurrentPassword"
                name="oldPass"
                type="password"
                maxLength={15}
                label={Strings.ChangePassword.Label1}
                showpass={showPassword}
                handleShowPassword={handleShowPassword}
              />
            </Col>
          </Row>
          <Row className="profile-form">
            <Col xl={4} lg={5} md={6} className="m-0">
              <NBInputGroupPswd
                value={passowrd.newPass}
                onChange={handleChanges}
                error={passwordsError.new_passwordError ? passwordsError.new_passwordError : ''}
                id="NewPassword"
                name="newPass"
                type="password"
                maxLength={15}
                label={Strings.ChangePassword.Label2}
                showpass={showPasswordNew}
                handleShowPassword={handleShowPasswordNew}
              />
            </Col>
          </Row>
          <Row className="profile-form">
            <Col xl={4} lg={5} md={6} className="m-0">
              <NBInputGroupPswd
                value={passowrd.confirmNewPass}
                onChange={handleChanges}
                error={passwordsError.confirm_passwordError ? passwordsError.confirm_passwordError : ''}
                id="ConfirmPassword"
                name="confirmNewPass"
                type="password"
                maxLength={15}
                label={Strings.ChangePassword.Label3}
                showpass={showPasswordConfirm}
                handleShowPassword={handleShowPasswordConfirm}
              />
            </Col>
          </Row>
          <Col className="button-nav mt-4">
            <Button variant="secondary" onClick={() => navigate('/')}>
              {Strings.ChangePassword.Btn1}
            </Button>
            <Button onClick={submitPswdForm} variant="primary">
              {Strings.ChangePassword.Btn2}
            </Button>
          </Col>
        </Container>
      </Container>
    </div>
  );
};

export default ChangePassword;
