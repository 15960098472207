/**
 * @file   src\components\CategoryMenu.tsx
 * @brief  Category Menu component.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Link } from 'react-router-dom';
import './Header.scss';
import Strings from '../assets/strings/Strings.json';
import CatAll from '../assets/img/icons/CatgAll';
import CatProducts from '../assets/img/icons/CatgProducts';
import CatServices from '../assets/img/icons/CatgServices';
import CatFood from '../assets/img/icons/CatgFood';
import CatThings from '../assets/img/icons/CatThings';
import CatCoupons from '../assets/img/icons/CatgCoupons';
import CatClassifieds from '../assets/img/icons/CatClassifieds';
import { setCategory, getCategory } from '../utils/localStorage';
import { categoryIds } from '../utils/enums';
import { useAppSelector } from '../hooks/index';
import { categoryList } from '../store/actions/productAction';
import { useState, useEffect } from 'react';
import * as CONSTANT from '../utils/constants';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const CategoryMenu = () => {
  const categoryId = getCategory();
  const dispatch = useDispatch();
  const [categoryListData, setCategoryListData] = useState<any>([]);
  const CategoryListApi = (payload: any) => dispatch<any>(categoryList(payload));
  const CategoryListingResponseData = useAppSelector((RootReducer) => RootReducer.product.categoryList);
  // handle set catrogry to local and reload the page except product details page.
  const handleCategory = (value: string) => {
    setCategory(JSON.stringify(value));
    // if (pathName !== '/productdetails') {
    //   window.location.reload();
    // }
  };

  useEffect(() => {
    CategoryListApi({});
  }, []); 

    // handle category listing response data
    useEffect(() => {
      if (CategoryListingResponseData.isLoading === false && CategoryListingResponseData.isSuccess === true) {
        if (CategoryListingResponseData.errorCode === CONSTANT.API_SUCCESSCODE) {
          setCategoryListData(CategoryListingResponseData.resultInfo.category_info);
        } else {
          toast.error(CategoryListingResponseData?.errorMessage);
        }
      }
    }, [CategoryListingResponseData]);
 
  const categories = [
    { id: categoryIds.PRODUCTS, icon: <CatProducts /> },
    { id: categoryIds.FOOD, icon: <CatFood /> },
    { id: categoryIds.THINGSTODO, icon: <CatThings /> },
    { id: categoryIds.SERVICES, icon: <CatServices /> },
    { id: categoryIds.COUPONS, icon: <CatCoupons /> },
    { id: categoryIds.CLASSIFIEDS, icon: <CatClassifieds /> },
  ];

  const getCategoryIcon = (id) => {
      const category = categories.find((data) => data.id === id);
      return category ? category.icon : ''; // Return the name or an empty string if not found
  };

  return (
    <>
     <Link key={categoryIds.All} to="/vendordetails"  className={categoryId ===categoryIds.All ? 'active' : ''} onClick={() => handleCategory(categoryIds.All)}
      > <CatAll />  {Strings.Header.CategoryMenu.MenuItem1}  </Link>
       {categoryListData.map((data) => (
        <Link
          key={ data.category_id}
          to="/vendordetails"
          className={categoryId === data.category_id ? 'active' : ''}
          onClick={() => handleCategory( data.category_id)}
         >
          {getCategoryIcon(data.category_id)}
          {data.category_name} {/* Fallback to default string */}
        </Link>
      ))}
    </>
  );
};

export default CategoryMenu;
