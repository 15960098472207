
const CatAll = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.447" height="20.447" viewBox="0 0 20.447 20.447">
      <g id="Group_1633" data-name="Group 1633" transform="translate(-309 -1361)">
        <g id="icn_all" transform="translate(251 1182.447)">
          <path id="Path_31516" data-name="Path 31516" d="M7.668,0H.852A.852.852,0,0,0,0,.852V7.668a.852.852,0,0,0,.852.852H7.668a.852.852,0,0,0,.852-.852V.852A.852.852,0,0,0,7.668,0" transform="translate(58 178.553)" fill="#ffc406" />
          <path id="Path_31517" data-name="Path 31517" d="M21.668,0H14.852A.852.852,0,0,0,14,.852V7.668a.852.852,0,0,0,.852.852h6.816a.852.852,0,0,0,.852-.852V.852A.852.852,0,0,0,21.668,0" transform="translate(55.927 178.553)" fill="#ffc406" />
          <path id="Path_31518" data-name="Path 31518" d="M7.668,14H.852A.852.852,0,0,0,0,14.852v6.816a.852.852,0,0,0,.852.852H7.668a.852.852,0,0,0,.852-.852V14.852A.852.852,0,0,0,7.668,14" transform="translate(58 176.48)" fill="#ffc406" />
          <path id="Path_31519" data-name="Path 31519" d="M21.668,14H14.852a.852.852,0,0,0-.852.852v6.816a.852.852,0,0,0,.852.852h6.816a.852.852,0,0,0,.852-.852V14.852A.852.852,0,0,0,21.668,14" transform="translate(55.927 176.48)" fill="#ffc406" />
        </g>
      </g>
    </svg>

  )
};

export default CatAll;

