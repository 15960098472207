/**
 *@file NBInputQty.tsx
 *@brief Reusable Quantity Input component
 *@date Oct, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */

import React from "react";
import { Button, Form } from "react-bootstrap";
import { InputProperty } from "../interfaces/GeneralInterface"
import "./NBInputQty.scss";
interface Input extends InputProperty { }


const NBInputQty = React.forwardRef((props: Input, ref: any) => {
    const propsValue = props.value ? props.value : ''
    return (
        <Form.Group className='nb-qty-input'>
            <div className='qty-input-wrap d-flex'>
                <Button onClick={props.decrement} />
                <Form.Control
                    id={props.id}
                    name={props.name}
                    type={props.type}
                    placeholder={props.placeholder}
                    required={props.required}
                    onChange={props.onChange}
                    isInvalid={props.isInvalid}
                    className={props.className}
                    value={props.value}
                />
                <Button onClick={props.increment}/>
            </div>
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
            {props.error && props.error !== '' ? <Form.Text className='error'>
                {props.error}
            </Form.Text> : ""}
        </Form.Group>
    );
});

export default NBInputQty;
