import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { InputProperty } from '../interfaces/GeneralInterface';
import './NBOtpInput.scss';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
interface Input extends InputProperty {}

/**
 *@file NBOtpInput.tsx
 *@brief Reusable OTP input group component
 *@date September, 2023
 *@author ZCO Engineer
 *@copyright (c) 2023, ZCO
 */
const NBOtpInput = React.forwardRef((props: Input, ref: any) => {
  return (
    <Form.Group className="nb-otp-inputgroup">
      <Form.Label>{props.label}</Form.Label>
      <OtpInput  value={props.value} onChange={props.onChange} numInputs={4} renderSeparator={false} shouldAutoFocus={true} inputType='password' renderInput={(props) => <input {...props} />} />
      <span className="text-end d-block mt-3">
        <Link to=""onClick={props.resendOtp}>{props.linkName}</Link>
      </span>
      <Form.Control.Feedback type="invalid">Error Comes Here</Form.Control.Feedback>
      {props.error && props.error !== '' ? <Form.Text className="error">{props.error}</Form.Text> : ''}
    </Form.Group>
  );
});

export default NBOtpInput;
