
const IcClassifieds = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.909" viewBox="0 0 24 20.909">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_593" data-name="Rectangle 593" width="24" height="20.909" transform="translate(0 0)" />
        </clipPath>
      </defs>
      <g id="icn_classifieds" transform="translate(0 0)">
        <g id="Group_1423" data-name="Group 1423" clip-path="url(#clip-path)">
          <path id="Path_31530" data-name="Path 31530" d="M16.484.125a1,1,0,0,0-1.015.027L7.713,5H4a4,4,0,0,0,0,8H7.713l7.756,4.848A1,1,0,0,0,17,17V1a1,1,0,0,0-.516-.875" />
          <path id="Path_31531" data-name="Path 31531" d="M23,8H21a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2" />
          <path id="Path_31532" data-name="Path 31532" d="M19.8,5.5a.992.992,0,0,0,.5-.134l1.732-1a1,1,0,1,0-1-1.732l-1.732,1A1,1,0,0,0,19.8,5.5" />
          <path id="Path_31533" data-name="Path 31533" d="M22.026,13.634l-1.732-1a1,1,0,1,0-1,1.732l1.732,1a1,1,0,1,0,1-1.732" />
          <path id="Path_31534" data-name="Path 31534" d="M8.333,15.745,7.14,15H4.48l2.212,4.957a1.607,1.607,0,0,0,2.936-1.31Z" />
        </g>
      </g>
    </svg>
  )
};

export default IcClassifieds;

