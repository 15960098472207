/**
 * @file   src\containers\GenericNotFound.tsx
 * @brief  404 page.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { Button } from 'react-bootstrap';
import '../assets/styles/notfound.scss';
import Errorimg from '../assets/img/404.svg';
import Strings from '../assets/strings/Strings.json';

const GenericNotFound = () => {
  // handle back to home button
  const handleErrorhandle = () => {
    window.location.href = '/';
  };
  return (
    <div className="inner-layout d-flex align-items-center justify-content-center">
      <div className="not-found">
        <img src={Errorimg} alt="" />
        <h3>{Strings.ErrorPage.Title}</h3>
        <p>{Strings.ErrorPage.InfoText}</p>
        <Button onClick={handleErrorhandle} variant="primary">
          {Strings.ErrorPage.Btn}
        </Button>
      </div>
    </div>
  );
};

export default GenericNotFound;
