
const ForgotPassIc = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="263" height="263" viewBox="0 0 263 263">
      <g id="icn_forgot_password" transform="translate(-97.9 -122.9)">
        <circle id="Ellipse_83" data-name="Ellipse 83" cx="131.5" cy="131.5" r="131.5" transform="translate(97.9 122.9)" fill="#fff" />
        <g id="Group_660" data-name="Group 660" transform="translate(147.002 164.425)">
          <g id="Group_657" data-name="Group 657" transform="translate(94.929 0)">
            <path id="Path_43" data-name="Path 43" d="M179.862,72.622l-.8,5.693A4.232,4.232,0,0,1,174.173,82a4.338,4.338,0,0,1-3.689-4.888l1.275-9.108c.012-.085.1-.073.113-.162-.077-.1-.077-.1-.065-.187a4.458,4.458,0,0,1,4.889-3.691,9.9,9.9,0,1,0,2.746-19.615,9.776,9.776,0,0,0-9.08,3.641,4.358,4.358,0,0,1-6.174.743,4.3,4.3,0,0,1-.666-6.073,18.582,18.582,0,1,1,16.338,29.96ZM177.177,91.8l-.221,1.575a4.232,4.232,0,0,1-4.892,3.691,4.34,4.34,0,0,1-3.687-4.892l.22-1.575a4.4,4.4,0,0,1,4.888-3.689,4.283,4.283,0,0,1,3.694,4.889Z" transform="translate(-162.567 -35.535)" fill="#cbe991" />
          </g>
          <g id="Group_658" data-name="Group 658" transform="translate(0 56.692)">
            <path id="Path_44" data-name="Path 44" d="M76.127,144.991l1.278,4.64a3.543,3.543,0,0,1-2.535,4.458,3.639,3.639,0,0,1-4.458-2.532l-2.044-7.423c-.019-.07.054-.09.034-.163-.092-.05-.092-.05-.112-.123a3.733,3.733,0,0,1,2.532-4.461,8.29,8.29,0,1,0-4.4-15.986,8.185,8.185,0,0,0-5.769,5.812,3.65,3.65,0,0,1-4.5,2.62,3.605,3.605,0,0,1-2.527-4.447,15.556,15.556,0,1,1,22.506,17.6Zm4.3,15.633.354,1.284a3.543,3.543,0,0,1-2.535,4.462,3.639,3.639,0,0,1-4.458-2.536l-.354-1.284a3.681,3.681,0,0,1,2.532-4.457,3.585,3.585,0,0,1,4.462,2.526Z" transform="translate(-53.492 -115.781)" fill="#cbe991" />
          </g>
          <g id="Group_659" data-name="Group 659" transform="translate(154.426 68.987)">
            <path id="Path_45" data-name="Path 45" d="M141.305,200.776l-.189,3.562a2.627,2.627,0,0,1-2.83,2.542,2.7,2.7,0,0,1-2.542-2.83l.3-5.7c0-.054.058-.05.061-.106-.053-.057-.053-.057-.05-.112a2.767,2.767,0,0,1,2.83-2.544,6.147,6.147,0,1,0,.65-12.273,6.065,6.065,0,0,0-5.419,2.736,2.7,2.7,0,0,1-3.774.788,2.671,2.671,0,0,1-.736-3.718,11.532,11.532,0,1,1,11.7,17.655Zm-.635,11.994-.053.987a2.627,2.627,0,0,1-2.83,2.544,2.7,2.7,0,0,1-2.542-2.83l.053-.987a2.728,2.728,0,0,1,2.83-2.542,2.658,2.658,0,0,1,2.543,2.833Z" transform="translate(-129.152 -177.892)" fill="#cbe991" />
          </g>
        </g>
        <g id="Group_656" data-name="Group 656" transform="translate(193.914 224.401)">
          <path id="Rectangle_181" data-name="Rectangle 181" d="M12.618,0h41.6A12.618,12.618,0,0,1,66.832,12.618v30.9A12.618,12.618,0,0,1,54.214,56.138h-41.6A12.618,12.618,0,0,1,0,43.52v-30.9A12.618,12.618,0,0,1,12.618,0Z" transform="translate(8.183 51.303)" fill="#cbe991" />
          <g id="Group_655" data-name="Group 655" transform="translate(0)">
            <rect id="Rectangle_182" data-name="Rectangle 182" width="69.503" height="57.92" rx="9.363" transform="translate(0 44.683)" fill="none" stroke="#94da0a" stroke-linejoin="round" stroke-width="2" />
            <path id="Path_41" data-name="Path 41" d="M3858.85,1212.548v-13.686c0-16.45,13.342-31.039,29.784-31.039s29.784,14.59,29.784,31.039v15.3" transform="translate(-3853.891 -1167.824)" fill="none" stroke="#94da0a" stroke-linejoin="round" stroke-width="2" />
            <path id="Path_42" data-name="Path 42" d="M3935.077,1356.982a6.569,6.569,0,0,1-2.473,5.121c-.725.583,3.835,7.123,4,13.186l-16.555.094c.043-6.092,4.656-12.753,3.917-13.377a6.563,6.563,0,0,1-2.353-5.03,6.738,6.738,0,0,1,13.463,0Z" transform="translate(-3893.452 -1285.818)" fill="#fff" stroke="#94da0a" stroke-linejoin="round" stroke-width="2" />
          </g>
        </g>
      </g>
    </svg>
  )
};

export default ForgotPassIc;

