
const DownloadIc = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.323" height="14.323" viewBox="0 0 14.323 14.323">
      <g id="download-ic" transform="translate(12.323 7.162) rotate(135)">
        <line id="Line_170" data-name="Line 170" x1="7.299" y2="7.299" transform="translate(0 0)" fill="none" stroke="#037ccc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        <path id="Path_31557" data-name="Path 31557" d="M6.387,6.387V0H0" transform="translate(0.912)" fill="none" stroke="#037ccc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
      </g>
    </svg>
  )
};

export default DownloadIc;

