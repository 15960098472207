
const Hamburger = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="22" viewBox="0 0 33 22">
      <g id="Group_344" data-name="Group 344" transform="translate(1.082 1)">
        <line id="Line_56" data-name="Line 56" x2="31" transform="translate(-0.082 10)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
        <line id="Line_57" data-name="Line 57" x2="31" transform="translate(-0.082)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
        <line id="Line_58" data-name="Line 58" x2="31" transform="translate(-0.082 20)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
      </g>
    </svg>
  )
};

export default Hamburger;

