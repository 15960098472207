/**
 * @file   src\containers\Contact.tsx
 * @brief  Contact page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../assets/styles/contact.scss';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../hooks/index';
import Strings from '../assets/strings/Strings.json';
import AppsAd from '../components/AppsAd';
import Visit from '../assets/img/icons/Visit';
import CallUs from '../assets/img/icons/Callus';
import Message from '../assets/img/icons/Message';
import Website from '../assets/img/icons/Website';
import { ntfnCheck } from '../store/actions/ntfnAction';
import { getGusetUser } from '../utils/localStorage';

const Contact = () => {
  const dispatch = useAppDispatch();
  const guestUser = getGusetUser();
  // handle email window open in another tab
  const handlemail = () => {
    window.open('mailto:message@nearbuys.com', '_blank');
  };

  // handle web window open in another tab
  const handleWeb = () => {
    window.open('https://www.trynearbuys.com/', '_blank');
  };

  // calls notification check api
  useEffect(() => {
    if (!guestUser) {
      dispatch<any>(ntfnCheck());
    }
  }, []);
  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container className="d-flex align-items-middle justify-content-between">
          <h2>{Strings.Contact.Title}</h2>
        </Container>
      </Container>
      <Container fluid>
        <Container>
          <Row className="contact-wrap">
            <Col xl="3" mdl="6" className="contact-box d-flex align-items-center flex-column">
              <span className="d-flex align-items-center justify-content-center">
                <Visit />
              </span>
              <h3>{Strings.Contact.Sub1Title}</h3>
              <p className="text-center">{Strings.Contact.Sub1Text}</p>
            </Col>
            <Col xl="3" md="6" className="contact-box d-flex align-items-center flex-column">
              <span className="d-flex align-items-center justify-content-center">
                <CallUs />
              </span>
              <h3>{Strings.Contact.Sub2Title}</h3>
              <p className="text-center">{Strings.Contact.Sub2Text}</p>
            </Col>
            <Col xl="3" md="6" className="contact-box d-flex align-items-center flex-column" style={{ cursor: 'pointer' }} onClick={handlemail}>
              <span className="d-flex align-items-center justify-content-center">
                <Message />
              </span>
              <h3>{Strings.Contact.Sub3Title}</h3>
              <Link to="#" onClick={handlemail}>
                {Strings.Contact.Sub3Text}
              </Link>
            </Col>
            <Col xl="3" md="6" className="contact-box d-flex align-items-center flex-column" style={{ cursor: 'pointer' }} onClick={handleWeb}>
              <span className="d-flex align-items-center justify-content-center">
                <Website />
              </span>
              <h3>{Strings.Contact.Sub4Title}</h3>
              <Link target="_blank" to="https://www.nearbuys.com/">
                {Strings.Contact.Sub4Text}
              </Link>
            </Col>
          </Row>
          <AppsAd />
        </Container>
      </Container>
    </div>
  );
};

export default Contact;
